import React, { memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { useTextOverflow } from 'hooks';
import { adjustUrl } from 'utils';

import ReadonlyField, { ReadonlyFieldProps } from './readonly-field';

interface LinkItemInfo {
  linkUrl: string;
  linkLabel?: string;
}

interface LinkItemProps {
  linkItemInfo: LinkItemInfo;
}

const LinkItem = ({ linkItemInfo: { linkUrl, linkLabel } }: LinkItemProps) => {
  const [labelRef, labelIsOverflow] = useTextOverflow();
  const [urlRef, urlIsOverflow] = useTextOverflow();

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={labelIsOverflow ? linkLabel || linkUrl : ''}>
          <Box ref={labelRef} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {linkLabel || linkUrl}
          </Box>
        </Tooltip>
        <IconButton
          sx={{ flexShrink: 0 }}
          onClick={() => {
            window.open(adjustUrl(linkUrl));
          }}
        >
          <LaunchOutlinedIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Box>
      {!!linkLabel && (
        <Tooltip title={urlIsOverflow ? linkUrl : ''}>
          <Box
            ref={urlRef}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#00000099',
              fontSize: '12px',
            }}
          >
            {linkUrl}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

type LinksReadonlyFieldProps = Pick<ReadonlyFieldProps, 'sx' | 'label'> & { links: Array<LinkItemInfo> };

const LinksReadonlyField = ({ links, label, ...rest }: LinksReadonlyFieldProps) => {
  return (
    <ReadonlyField
      {...rest}
      label={label}
      value={links.length ? '1' : undefined}
      {...(!!links.length && {
        renderValue: () => {
          return (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                maxWidth: '100%',
                padding: '4px 0 5px',
                marginTop: '16px',
                color: theme.palette.text.primary,
              })}
            >
              {links.map((linkItemInfo) => (
                <LinkItem key={linkItemInfo.linkUrl} linkItemInfo={linkItemInfo} />
              ))}
            </Box>
          );
        },
      })}
    />
  );
};

export default memo(LinksReadonlyField);
