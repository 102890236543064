import { useQuery } from 'react-query';

import { Sources, GridSettings } from 'types';
import buildFindParams from 'api/domain/candidate-service/find-payload-builder';

import { prepareQueryResult } from '../utils';
import { fetchSources, findSources } from './service';

export const useFetchSources = () => {
  const { data, isLoading, isError } = useQuery<Sources, Error>('sources-fetch', () => fetchSources());
  return {
    sources: data,
    sourcesAreLoading: isLoading,
    fetchSourcesIsError: isError,
  };
};

interface UseFindSourcesParams {
  gridSettings: GridSettings;
}

export const useFindSources = ({ gridSettings }: UseFindSourcesParams) => {
  const { filters, searches } = buildFindParams(gridSettings, []);

  const result = useQuery(['contact-sources-for-filtering', ...Object.values(gridSettings)], () =>
    findSources({ filters, searches })
  );

  return prepareQueryResult('contactSources', result, null, true);
};
