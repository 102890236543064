import { ContactRoles, ContactAvailabilityTypes } from 'config';
import { GridSettings, LeadType, LeadTypeOptionValue } from 'types';
import { MAPPED_SOURCE_NULL_VALUE } from 'views/dashboard/filters-sidebar/utils';

interface FetchCandidatesRequestParams {
  pagination: {
    page: number;
    perPage: number;
  };
  filters?: Record<string, any>;
  sorting?: Record<string, any>;
  searches?: Record<string, any>;
  globalSearch?: string;
  fields?: string[];
}

const settingsTokenToFilterParamMap: Record<string, string> = {
  [ContactRoles.FREELANCER]: 'freelancer',
  [ContactRoles.TEAM]: 'team',
  [ContactAvailabilityTypes.PART_TIME]: 'PartTimeOnly',
  [ContactAvailabilityTypes.FULL_TIME]: 'FullTimeOnly',
  [ContactAvailabilityTypes.PART_AND_FULL_TIME]: 'BothPartAndFullTime',
  [ContactAvailabilityTypes.PART_THEN_FULL_TIME]: 'PartTimeCanSwitchToFullTime',
  [ContactAvailabilityTypes.UNKNOWN]: 'Unknown',
};

const leadTypeOptionMap: Record<string, Array<LeadType>> = {
  [LeadTypeOptionValue.INBOUND]: [LeadType.INBOUND],
  [LeadTypeOptionValue.OUTREACHED]: [LeadType.OUTREACHED],
};

const buildFilterParams = (settings: GridSettings): Pick<FetchCandidatesRequestParams, 'filters'> => {
  const {
    role,
    team,
    availabilityType,
    operatorId,
    dedicatedOperatorId,
    availabilityHours,
    statuses,
    leadType,
    campaignTagsInclude,
    campaignTagsExclude,
    forFutureReasonsInclude,
    forFutureReasonsExclude,
    selfAssessmentInclude,
    commercialExperience,
    relevantCountriesOnly,
    leadTypeSource,
    websiteSources,
    adTypeSources,
    campaignSources,
    customSources,
    referredById,
    referredBySomeone,
    tagIds,
  } = settings;

  const mapSourceValueToApiFormat = (value: string) => (value === MAPPED_SOURCE_NULL_VALUE ? null : value);

  const includeTagIds = [...tagIds, ...campaignTagsInclude];
  const excludeTagIds = [...campaignTagsExclude];

  const filters = {
    ...(role === ContactRoles.ALL ? {} : { role: settingsTokenToFilterParamMap[role] || role }),
    ...(team ? { teamId: team } : {}),
    ...(availabilityType.length !== 0
      ? { availabilityTypes: availabilityType.map((feType) => settingsTokenToFilterParamMap[feType] || feType) }
      : {}),
    ...(availabilityHours.length !== 0 ? { availabilities: availabilityHours } : {}),
    ...(operatorId ? { operators: [operatorId] } : {}),
    ...(dedicatedOperatorId
      ? dedicatedOperatorId === 'none'
        ? { dedicatedOperators: [] }
        : { dedicatedOperators: [dedicatedOperatorId] }
      : {}),
    ...(statuses.length !== 0 ? { statuses } : {}),
    ...(([LeadTypeOptionValue.INBOUND, LeadTypeOptionValue.OUTREACHED] as Array<string>).includes(leadType) && {
      sourceL1: leadTypeOptionMap[leadType],
    }),
    ...(forFutureReasonsInclude.length ? { disqualification_reasons: forFutureReasonsInclude } : {}),
    ...(forFutureReasonsExclude.length ? { disqualification_reasons_exclude: forFutureReasonsExclude } : {}),
    ...(selfAssessmentInclude.length ? { seniority: selfAssessmentInclude } : {}),
    ...(typeof commercialExperience === 'number' ? { yearsOfExperienceGreaterThanOrEqual: commercialExperience } : {}),
    ...(([LeadTypeOptionValue.INBOUND, LeadTypeOptionValue.OUTREACHED] as Array<string>).includes(leadTypeSource) && {
      sourceL1: leadTypeOptionMap[leadTypeSource],
    }),
    ...(websiteSources.length ? { sourceL2: websiteSources.map(mapSourceValueToApiFormat) } : {}),
    ...(adTypeSources.length ? { sourceL3: adTypeSources.map(mapSourceValueToApiFormat) } : {}),
    ...(campaignSources.length ? { sourceL4: campaignSources.map(mapSourceValueToApiFormat) } : {}),
    ...(customSources.length ? { sourceL5: customSources.map(mapSourceValueToApiFormat) } : {}),
    relevantCountriesOnly,
    ...(referredById ? { referredById } : {}),
    referredBySomeone,
    ...(includeTagIds.length ? { tagIds: includeTagIds } : {}),
    ...(excludeTagIds.length ? { excludeTagIds } : {}),
  };
  return Object.keys(filters).length === 0 ? {} : { filters };
};

const settingsTokenToSortingParamMap: Record<string, string> = {
  name: 'fullName',
  avEngHours: 'available_hours_per_week',
  rate_per_hour: 'min_or_max_rate_per_hour',
  linkedin: 'linkedinId',
};

/**
 * the following fields are sortable:
 *    lastName, email, created_date, availability_type, available_hours_per_week, min_rate_per_hour,
 *    max_rate_per_hour, client_rate_per_hour, role, team, country, phone, telegram, city, payoneer_name,
 *    w8ben_link, experience_since, link_to_cv, status_in_system, linkedinId,
 *    last_change_date, status_change_date
 */
const buildSortingParams = ({ sortedColumn }: GridSettings): Pick<FetchCandidatesRequestParams, 'sorting'> =>
  Object.keys(sortedColumn).length === 0
    ? {}
    : {
        sorting: {
          [settingsTokenToSortingParamMap[sortedColumn.fieldName!] || sortedColumn.fieldName!]: sortedColumn.sortOption,
        },
      };

const settingsTokenToSearchesParamMap: Record<string, string> = {
  name: 'fullName',
  mainSkills: 'main_skills',
  otherSkills: 'other_skills',
  avEngHours: 'available_hours',
};

const buildSearchesParams = ({ columnSearches }: GridSettings): Pick<FetchCandidatesRequestParams, 'searches'> =>
  Object.keys(columnSearches).length === 0
    ? {}
    : {
        searches: Object.entries(columnSearches).reduce(
          (acc, [key, value]) => ({ ...acc, [settingsTokenToSearchesParamMap[key] || key]: value }),
          {}
        ),
      };

const buildFindParams = (settings: GridSettings, visibleColumns: string[]): FetchCandidatesRequestParams => {
  const { page, perPage, globalSearch } = settings;
  return {
    pagination: {
      page,
      perPage,
    },
    ...(globalSearch && globalSearch !== '' ? { globalSearch } : {}),
    ...buildFilterParams(settings),
    ...buildSortingParams(settings),
    ...buildSearchesParams(settings),
    ...(visibleColumns.length > 0 ? { fields: visibleColumns } : {}),
  };
};

export default buildFindParams;
