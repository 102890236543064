import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CandidateSpecialization } from 'types';
import {
  getCandidateSpecializations,
  addSpecialization,
  deleteSpecialization,
  updateSpecialization,
} from './specializations-service';

const CANDIDATE_SPECIALIZATIONS_FETCH_KEY = 'candidate-specializations-fetch';

export const useCandidateSpecializationsFetch = (candidateId: string) => {
  const { data, isLoading, isError } = useQuery<CandidateSpecialization[], Error>(
    [CANDIDATE_SPECIALIZATIONS_FETCH_KEY, candidateId],
    () => getCandidateSpecializations(candidateId)
  );
  return {
    candidateSpecializations: data || [],
    candidateSpecializationsAreLoading: isLoading,
    candidateSpecializationsFetchError: isError,
  };
};

export const useAddSpecializationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(addSpecialization, {
    onSuccess: (_, { candidateID }) => {
      queryClient.invalidateQueries([CANDIDATE_SPECIALIZATIONS_FETCH_KEY, candidateID]);
    },
  });
};

export const useDeleteSpecializationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSpecialization, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries([CANDIDATE_SPECIALIZATIONS_FETCH_KEY, candidateId]);
    },
  });
};

export const useUpdateSpecializationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSpecialization, {
    onSuccess: (_, { candidateID }) => {
      queryClient.invalidateQueries([CANDIDATE_SPECIALIZATIONS_FETCH_KEY, candidateID]);
    },
  });
};
