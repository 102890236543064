import { styled, Alert } from '@mui/material';

const AlertCentered = styled(Alert)(() => ({
  padding: '14px 16px',
  alignItems: 'center',

  '& .MuiAlert-message': {
    overflow: 'hidden',
    padding: '0',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
}));

export default AlertCentered;
