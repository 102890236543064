import ai from 'api/domain/axios';
import ENDPOINTS from 'api/domain/endpoints';
import { LOG_TYPES_LIST } from 'constants/candidate';
import { Log, LogType } from 'types';

interface GetCandidateLogsPayload {
  candidateId: string;
  projectRelated?: boolean;
  applicationRelated?: boolean;
}

export const getCandidateLogs = async ({
  candidateId,
  projectRelated = true,
  applicationRelated = true,
}: GetCandidateLogsPayload): Promise<Log[]> => {
  const filter: Partial<Record<LogType, boolean>> | false = (!projectRelated || !applicationRelated) && {
    [LogType.PROJECT_MATCHING]: projectRelated,
    [LogType.APPLICATION_STAGE]: applicationRelated,
  };

  const params = filter ? { types: LOG_TYPES_LIST.filter((logType) => filter[logType] !== false) } : null;

  return ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/logs`, {
    ...(params && { params }),
  });
};
