import React from 'react';
import Highlighter from 'react-highlight-words';

import { TextCell } from 'components';

interface Props {
  fieldValue: string;
  searchValue: string | undefined;
  // workaround
  tokenize?: (value: string) => string[];
}

const tokenizeValue = (value: string): string[] =>
  value
    .split(' ')
    .filter((match) => match !== ' ')
    .map((match) => (match ? match.trim() : match))
    .filter((match) => match !== '');

// TODO: refactor
// - make searchValue array of string
// - remove tokenizeValue
const SearchHighlightCell: React.FC<Props> = ({ fieldValue, searchValue, tokenize }) => {
  if (!searchValue) {
    return <TextCell>{fieldValue}</TextCell>;
  }

  const searchTokens = tokenize ? tokenize(searchValue) : tokenizeValue(searchValue);

  return (
    <TextCell tooltipWhenOverflow={fieldValue}>
      <Highlighter
        autoEscape
        searchWords={searchTokens}
        textToHighlight={fieldValue}
        highlightStyle={{
          backgroundColor: 'rgb(131, 44, 224, 0.3)',
          borderRadius: '3px',
          fontWeight: 700,
        }}
      />
    </TextCell>
  );
};

export default SearchHighlightCell;
