import { HubspotProjectSkill } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface DeleteHubspotProjectSkillPayload {
  hubspotProjectId: string;
  skillId: string;
}

export const deleteHubspotProjectSkill = ({
  hubspotProjectId,
  skillId,
}: DeleteHubspotProjectSkillPayload): Promise<HubspotProjectSkill> =>
  ai.delete(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/skills/${skillId}`);
