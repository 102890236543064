import { HubspotProjectSpecialization } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface DeleteHubspotProjectSpecializationPayload {
  hubspotProjectId: string;
  specializationId: string;
}

export const deleteHubspotProjectSpecialization = ({
  hubspotProjectId,
  specializationId,
}: DeleteHubspotProjectSpecializationPayload): Promise<HubspotProjectSpecialization> =>
  ai.delete(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/specializations/${specializationId}`);
