import React from 'react';
import { FormControl, InputLabel, IconButton, Input, SxProps, Box, Tooltip, FormHelperText } from '@mui/material';
import clsx from 'clsx';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { FlexRow } from '../..';

type InputIcons = 'copy' | 'navigate';

const IconToComponent = {
  copy: ContentCopyIcon,
  navigate: LaunchOutlinedIcon,
};
interface AdornmentProps {
  icon: InputIcons;
  onIconClick?: () => void;
  tooltip?: string;
}

const Adornment: React.FC<AdornmentProps> = ({ icon, onIconClick = () => {}, tooltip }) => {
  const Component = IconToComponent[icon];
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <IconButton onClick={onIconClick}>
          <Component sx={{ height: '20px', width: '20px' }} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <IconButton onClick={onIconClick}>
      <Component sx={{ height: '20px', width: '20px' }} />
    </IconButton>
  );
};

export interface Props {
  className?: string;
  sx?: SxProps;
  type?: string;
  value?: string;
  label: string;
  multiline?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  onChange?: (value: string) => void;
  icon?: 'copy' | 'navigate';
  onIconClick?: () => void;
  iconTooltip?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  focused?: boolean;
  hovered?: boolean;
  tabIndex?: number;
  error?: boolean | string;
  disabled?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  dataTestId?: string;
}

/*
  TODO: Remove props icon, onIconClick, iconTooltip
*/

const FormInput: React.FC<Props> = React.forwardRef(function FormInput(
  {
    className,
    type,
    value = '',
    label,
    sx = {},
    multiline = false,
    onChange = () => null,
    onBlur = () => null,
    onKeyUp = () => null,
    color = 'info',
    focused,
    hovered,
    tabIndex,
    error,
    icon,
    onIconClick = () => {},
    iconTooltip,
    onFocus = () => {},
    disabled = false,
    autoFocus,
    helperText,
    dataTestId,
  },
  ref
) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <FlexRow sx={sx} ref={ref}>
      <FormControl className={clsx(className)} variant="standard" fullWidth error={!!error} disabled={disabled}>
        <InputLabel
          focused={focused}
          sx={{
            ...(typeof focused === 'boolean'
              ? focused || value
                ? { transform: 'translate(0, -1.5px) scale(0.75)' }
                : { transform: 'translate(0, 20px) scale(1)' }
              : {}),
          }}
        >
          {label}
        </InputLabel>
        <Input
          className={clsx(focused && 'Mui-focused')}
          sx={(theme) => ({
            '&::after': { borderColor: theme.palette.primary.main },
            ...(hovered ? { '&::before': { borderBottom: `2px solid ${theme.palette.common.black}` } } : {}),
          })}
          autoComplete="none"
          value={value}
          color={color}
          multiline={multiline}
          onChange={handleChange}
          onBlur={onBlur}
          error={!!error}
          inputProps={{ tabIndex, type, 'data-testid': dataTestId }}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          disabled={disabled}
          autoFocus={autoFocus}
          {...(dataTestId && { 'data-testid': `${dataTestId}__wrapper` })}
        />
        {(typeof error === 'string' || typeof helperText === 'string') && (
          <FormHelperText>{!error || typeof error === 'boolean' ? helperText : error}</FormHelperText>
        )}
      </FormControl>
      {icon && (
        <Box
          sx={{
            display: 'flex',
            alignItems: error ? 'flex-center' : 'flex-end',
          }}
        >
          <Adornment icon={icon} onIconClick={onIconClick} tooltip={iconTooltip} />
        </Box>
      )}
    </FlexRow>
  );
});

export default FormInput;
