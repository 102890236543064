import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  fetchHubspotProjectSkills,
  createHubspotProjectSkill,
  updateHubspotProjectSkill,
  deleteHubspotProjectSkill,
} from './hubspot-project-skills-service';

export const useFetchHubspotProjectSkills = (payload: GetPayload<typeof fetchHubspotProjectSkills>) => {
  const result = useQuery(['hubspotProjectSkills', payload.hubspotProjectId], () => fetchHubspotProjectSkills(payload));
  return prepareQueryResult('hubspotProjectSkills', result, []);
};

export const useCreateHubspotProjectSkill = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createHubspotProjectSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSkills']);
    },
  });
  return prepareMutation('createHubspotProjectSkill', mutation);
};

export const useUpdateHubspotProjectSkill = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateHubspotProjectSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSkills']);
    },
  });
  return prepareMutation('updateHubspotProjectSkill', mutation);
};

export const useDeleteHubspotProjectSkill = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteHubspotProjectSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSkills']);
    },
  });
  return prepareMutation('deleteHubspotProjectSkill', mutation);
};
