import React from 'react';

import Router from 'next/router';
import { AUTH_LS_KEY, envType, EnvType, localEnvAuthKey, Routes } from 'config';

export const useAuth = (targetDependencies: Array<string | null | undefined>) => {
  const [authKey, setAuthKey] = React.useState<string | null>(window.localStorage.getItem(AUTH_LS_KEY));

  React.useEffect(() => {
    const targetIsReady = targetDependencies.every((token) => Boolean(token));
    if (!targetIsReady) {
      return;
    }
    if (!authKey) {
      if (envType !== EnvType.LOCAL) {
        Router.push(`${Routes.login}#${targetDependencies.join('/')}`);
      } else {
        window.localStorage.setItem(AUTH_LS_KEY, localEnvAuthKey);
        setAuthKey(localEnvAuthKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...targetDependencies, authKey]);

  return { isAuthenticated: authKey !== null };
};
