import { createContext } from 'react';
import { GridRowId } from '@mui/x-data-grid-pro';

import {
  GridSettings,
  GridSettingsSetter,
  ColumnsStoredSettings,
  SortedColumnSetter,
  ColumnSearchQuerySetter,
} from 'types';
import { ContactRoles, COLUMNS_INIT_ORDER } from 'config';

import initSettings from './init-settings';

export type DashboardContextState = {
  gridSettings: GridSettings;
  columnsStoredSettings: ColumnsStoredSettings;
  isInCompactView: boolean;
  isInColDnDMode: boolean;
  columnsOrder: string[];
  selectedIds: GridRowId[];
};

type DashboardContextMutators = {
  setColumnsOrder: (order: string[]) => void;
  setSelectedIds: (selectedIds: GridRowId[]) => void;
  setIsInCompactView: (status: boolean) => void;
  setIsInColDnDMode: (status: boolean) => void;
  setGridSettingsItem: GridSettingsSetter;
  setGridSettings: (setter: (gridSettings: GridSettings) => GridSettings) => void;
  resetGeneralFilters: () => void;
  setRole: (role: ContactRoles) => void;
  storeColumnVisibility: (colField: string, isVisible: boolean) => void;
  storeColumnWidth: (colField: string, width: number) => void;
  setSortedColumn: SortedColumnSetter;
  setColumnSearchQuery: ColumnSearchQuerySetter;
  resetLocalStorage: () => void;
  resetStatuses: () => void;
  resetTags: () => void;
  resetAdvancedFilters: () => void;
  resetFiltersBySources: () => void;
  resetAll: () => void;
};

type AdditionalGridSettingsContextProps = {
  filtersCount: number;
};

type GridSettingsContextProps = DashboardContextState & DashboardContextMutators & AdditionalGridSettingsContextProps;

const GridSettingsContext = createContext<GridSettingsContextProps>({
  gridSettings: initSettings,
  columnsStoredSettings: {},
  isInCompactView: false,
  isInColDnDMode: false,
  columnsOrder: COLUMNS_INIT_ORDER,
  selectedIds: [],
  setColumnsOrder() {},
  setSelectedIds() {},
  setIsInCompactView() {},
  setIsInColDnDMode() {},
  setGridSettingsItem() {},
  setGridSettings() {},
  resetGeneralFilters() {},
  setRole() {},
  storeColumnVisibility() {},
  storeColumnWidth() {},
  setSortedColumn() {},
  setColumnSearchQuery() {},
  resetLocalStorage() {},
  resetStatuses() {},
  resetTags() {},
  resetAdvancedFilters() {},
  resetFiltersBySources() {},
  filtersCount: 0,
  resetAll() {},
});

export default GridSettingsContext;
