import { styled } from '@mui/system';
import SimpleBar from 'simplebar-react';

const SIMPLEBAR_CONTENT_WRAPPERS_SELECTOR = `
  .simplebar-content,
  .simplebar-wrapper,
  .simplebar-height-auto-observer-wrapper,
  .simplebar-mask,
  .simplebar-offset
`;

interface StyledSimpleBarProps {
  fitContent: boolean;
}

export const StyledSimpleBar = styled(SimpleBar)<StyledSimpleBarProps>(({ fitContent }) => ({
  ...(fitContent && {
    [SIMPLEBAR_CONTENT_WRAPPERS_SELECTOR]: {
      width: 'fit-content',
    },
  }),

  '.simplebar-scrollbar::before': {
    backgroundColor: '#E0E0E0',
    opacity: '1',
  },

  '&:hover .simplebar-scrollbar::before': {
    backgroundColor: '#BDBDBD',
  },
}));
