import { ApplicationStage, AvailabilityType, LogType } from 'types';
import { CONTACT_AVAILABILITY_HOURS, ContactAvailabilityHours, ContactStatuses } from 'config/domain-vars';

type AvailabilityTypeConfig = {
  label: string;
  hoursList: Array<string>;
  defaultHours: typeof CONTACT_AVAILABILITY_HOURS[0] | '';
  /**
   * indicates whether availability_hours field should be in error state after
   * changing value in the availability_type
   */
  errorSideEffect?: boolean;
};

type AvailabilityTypeMap = { [key in AvailabilityType]: AvailabilityTypeConfig };

export const AVAILABILITY_TYPE_MAP: AvailabilityTypeMap = {
  [AvailabilityType.PartTimeOnly]: {
    label: 'Part-time only',
    hoursList: CONTACT_AVAILABILITY_HOURS.filter((hours) => hours !== ContactAvailabilityHours.FORTY),
    defaultHours: '',
    errorSideEffect: true,
  },
  [AvailabilityType.FullTimeOnly]: {
    label: 'Full-time only',
    hoursList: CONTACT_AVAILABILITY_HOURS.filter((hours) => hours === ContactAvailabilityHours.FORTY),
    defaultHours: ContactAvailabilityHours.FORTY,
  },
  [AvailabilityType.BothPartAndFullTime]: {
    label: 'Both part-time and full-time',
    hoursList: CONTACT_AVAILABILITY_HOURS,
    defaultHours: '',
  },
  [AvailabilityType.PartTimeCanSwitchToFullTime]: {
    label: 'Part-time / can switch to full-time',
    hoursList: CONTACT_AVAILABILITY_HOURS.filter((hours) => hours !== ContactAvailabilityHours.FORTY),
    defaultHours: '',
  },
  [AvailabilityType.Unknown]: {
    label: 'Unknown',
    hoursList: CONTACT_AVAILABILITY_HOURS,
    defaultHours: '',
  },
  [AvailabilityType.Unavailable]: {
    label: 'Unavailable',
    hoursList: CONTACT_AVAILABILITY_HOURS,
    defaultHours: '',
  },
};

export const ACTIVE_CANDIDATE_STATUSES = [
  ContactStatuses.AVAILABLE,
  ContactStatuses.PARTNERS_LISTED,
  ContactStatuses.ENGAGED,
  ContactStatuses.FULLY_ENGAGED,
  ContactStatuses.TEMPORARY_UNAVAILABLE,
];

export const LOG_TYPES_LIST = [
  LogType.STATUS,
  LogType.AVAILABILITY,
  LogType.AVAILABILITY_TYPE,
  LogType.CONTRACT,
  LogType.NOTE,
  LogType.EMAIL,
  LogType.DISQUALIFICATION_REASONS,
  LogType.PROJECT_MATCHING,
  LogType.APPLICATION_STAGE,
];

type ApplicationStageMap = { [key in ApplicationStage]: string };

export const APPLICATION_STAGE_MAP: ApplicationStageMap = {
  [ApplicationStage.ApplicationStarted]: 'Application started',
  [ApplicationStage.VideoAskStarted]: 'Videoask started',
  [ApplicationStage.ApplicationReopened]: 'Application reopened',
  [ApplicationStage.VideoAskCompleted]: 'Videoask completed',
  [ApplicationStage.ProfileSubmittedForReview]: 'Profile submitted for review',
  [ApplicationStage.ScreeningCallInvitationSent]: 'Screening call invitation sent',
  [ApplicationStage.ScreeningCallBooked]: 'Screening call booked',
  [ApplicationStage.ScreeningCallMade]: 'Screening call made',
  [ApplicationStage.HardSkillsInvitationSent]: 'Hard skills invitation sent',
  [ApplicationStage.HardSkillsInterviewBooked]: 'Hard skills interview booked',
  [ApplicationStage.HardSkillsInterviewMade]: 'Hard skills interview made',
  [ApplicationStage.FinalBriefingStarted]: 'Final briefing started',
  [ApplicationStage.ExperienceSubmittedForReview]: 'Experience submitted for review',
  [ApplicationStage.OnboardedToCommunity]: 'Onboarded to community',
  [ApplicationStage.ApplicationPaused]: 'Application paused',
};
