import { useEffect, useState, useCallback, useMemo } from 'react';
import { detect } from 'detect-browser';

interface UseAltHeldDownResult {
  /**
   * Boolean flag that represents if whether
   * Alt is held down.
   */
  altHeldDown: boolean;

  /**
   * Resets boolean flag of Alt key watcher.
   * It can help prevent cases when alt "sticks"
   * (for example, when opening new tab on Windows).
   */
  resetAltWatcher: () => void;
}

/**
 * Hook that allows to detect if
 * Alt (or Command on MacOS) is held down.
 *
 * It can be especially useful if we want to
 * implement opening the link in a new tab
 * when Alt is held down.
 */
export const useAltHeldDown = (): UseAltHeldDownResult => {
  const [isHeld, setIsHeld] = useState(false);

  const keyToWatch = useMemo(() => {
    const browser = detect();
    const isMacOS = browser?.os === 'Mac OS';
    return isMacOS ? 'Meta' : 'Alt';
  }, []);

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === keyToWatch) {
        setIsHeld(true);
      }
    },
    [keyToWatch]
  );

  const handleKeyUp = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === keyToWatch) {
        setIsHeld(false);
      }
    },
    [keyToWatch]
  );

  const handleSearchKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'f' && (event.metaKey || event.ctrlKey)) {
      setIsHeld(false);
    }
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      setIsHeld(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keydown', handleSearchKeyPress);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keydown', handleSearchKeyPress);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleKeyDown, handleKeyUp, handleSearchKeyPress, handleVisibilityChange]);

  return { altHeldDown: isHeld, resetAltWatcher: () => setIsHeld(false) };
};
