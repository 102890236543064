import React, { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { EditorBlockFormat } from 'types';

import BaseToolbarButton, { Props as BaseToolbarButtonProps } from './base-toolbar-button';
import { isBlockActive, toggleBlock } from './block-button.utils';

interface Props extends Omit<BaseToolbarButtonProps, 'isActive' | 'onMouseDown'> {
  format: EditorBlockFormat;
}

const BlockButton: React.FC<Props> = ({ format, ...rest }) => {
  const editor = useSlate();

  const handleMouseDown = useCallback(() => {
    toggleBlock(editor, format);
  }, [editor, format]);

  return <BaseToolbarButton isActive={isBlockActive(editor, format)} onMouseDown={handleMouseDown} {...rest} />;
};

export default memo(BlockButton);
