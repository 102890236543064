import { HubspotProjectSpecialization } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface CreateHubspotProjectSpecializationPayload {
  hubspotProjectId: string;
  specialization: Pick<HubspotProjectSpecialization, 'title' | 'seniority' | 'has_custom_title'>;
}

export const createHubspotProjectSpecialization = ({
  hubspotProjectId,
  specialization,
}: CreateHubspotProjectSpecializationPayload): Promise<HubspotProjectSpecialization> =>
  ai.post(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/specializations`, specialization);
