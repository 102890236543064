import { HubspotProjectSkill } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectSkillsPayload {
  hubspotProjectId: string;
}

export const fetchHubspotProjectSkills = ({
  hubspotProjectId,
}: FetchHubspotProjectSkillsPayload): Promise<Array<HubspotProjectSkill>> =>
  ai.get(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/skills`);
