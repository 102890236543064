import React from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Title } from './header-cell.styled';
import { SortOption } from 'types';
import { SearchInput } from '..';

interface Props {
  fieldName: string;
  title: string;
  sortable?: boolean;
  searchable?: boolean;
  sortState?: SortOption;
  onSortChange?: (fieldName: string, sortState: SortOption) => void;
  search?: string;
  onSearchChange?: (fieldName: string, query: string) => void;
  isInDnDMode?: boolean;
  sortByDescFirst?: boolean;
  dataTestId?: string;
}

const HeaderCell: React.FC<Props> = ({
  title,
  fieldName,
  sortable = false,
  searchable = false,
  sortState = SortOption.NONE,
  onSortChange = () => {},
  search,
  onSearchChange = () => {},
  isInDnDMode = false,
  sortByDescFirst = true,
  dataTestId,
}) => {
  const handleSortIconClick = () => {
    const updatedSortState =
      sortState === SortOption.DESC || (sortState === SortOption.NONE && !sortByDescFirst)
        ? SortOption.ASC
        : SortOption.DESC;

    onSortChange(fieldName, updatedSortState);
  };

  if (isInDnDMode) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', cursor: 'grab' }}>
        <Title>{title}</Title>
      </Box>
    );
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}
      {...(dataTestId && { 'data-testid': `${dataTestId}__root` })}
    >
      {searchable ? (
        <SearchInput
          size="small"
          hiddenLabel={true}
          variant="standard"
          placeholder={title}
          color="primary"
          sx={{ flex: 1 }}
          value={search ?? ''}
          onQueryChange={(query: string) => onSearchChange(fieldName, query)}
          controlled
          {...(dataTestId && { dataTestId: `${dataTestId}__search-input` })}
        />
      ) : (
        <Title sx={{ flex: 1 }} color={sortState !== SortOption.NONE ? 'primary' : undefined}>
          {title}
        </Title>
      )}
      {sortable && (
        <IconButton
          aria-label="upload picture"
          component="span"
          size="small"
          sx={{ height: '28px', width: '28px', ml: '4px' }}
          onClick={handleSortIconClick}
          color={sortState !== SortOption.NONE ? 'primary' : undefined}
          {...(dataTestId && { 'data-testid': `${dataTestId}__sort-button` })}
        >
          {sortState === SortOption.NONE || sortState === SortOption.DESC ? (
            <ArrowDownwardIcon
              sx={{ fontSize: '18px' }}
              color={sortState !== SortOption.NONE ? 'primary' : undefined}
            />
          ) : (
            <ArrowUpwardIcon sx={{ fontSize: '18px' }} color="primary" />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default HeaderCell;
