import { styled } from '@mui/system';
import { Modal, Box } from '@mui/material';

export const Root = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '32px',
  overflow: 'auto',

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
  },
}));

export const Backdrop = styled(Box)(() => ({
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.16)',
  pointerEvents: 'none',
  zIndex: '-1',
}));

export const ModalInner = styled(Box)(() => ({
  width: '600px',
  boxShadow: '0px 9px 46px 8px #0000001F, 0px 24px 38px 3px #00000024, 0px 11px 15px -7px #00000033',
  background: '#FFFFFF',
  borderRadius: '4px',
  margin: 'auto',
}));
