import { CandidateProfileDiff } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchCandidateDiffsPayload {
  candidateId: string;
}

export const fetchCandidateDiffs = ({
  candidateId,
}: FetchCandidateDiffsPayload): Promise<Array<CandidateProfileDiff>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/diffs`);
