import React, { memo } from 'react';
import { SxProps, Theme, Tooltip } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

import { Root, ChipWrapper, Chip } from './chip-list.styled';

export interface ChipItem {
  tooltip?: string;
  text: string;
  icon?: SvgIconComponent | null;
}

interface ChipListProps {
  className?: string;
  sx?: SxProps<Theme>;
  items: Array<ChipItem>;
}

const ChipList = ({ className, sx, items }: ChipListProps) => {
  return (
    <Root className={className} sx={sx}>
      {items
        .filter((item) => item)
        .map((item, i) => {
          const Icon = item.icon;

          return (
            <Tooltip key={`${item}-${i}`} title={item.tooltip ?? ''}>
              <ChipWrapper>
                <Chip>
                  {Icon && <Icon sx={{ mr: '3px', ml: '-6px' }} />}
                  {item.text}
                </Chip>
              </ChipWrapper>
            </Tooltip>
          );
        })}
    </Root>
  );
};

export default memo(ChipList);
