import React, { useMemo, useState, useCallback } from 'react';
import { type Control, type UseFormSetValue, Controller, useWatch } from 'react-hook-form';

import { FormInput, Autocomplete, CountrySelect } from 'components';
import { useFetchConstants, useFetchTeams } from 'api';
import { AddCandidateFormValues, AvailabilityType, SelectItem } from 'types';
import { AVAILABILITY_TYPE_MAP } from 'constants/candidate';
import { getError, makeSelectItems } from 'utils';
import { CONTACT_AVAILABILITY_HOURS } from 'config/domain-vars';

interface UseFormFieldsOptions {
  control: Control<AddCandidateFormValues>;
  setValue: UseFormSetValue<AddCandidateFormValues>;
}

export const useFormFields = ({ control, setValue }: UseFormFieldsOptions) => {
  const { data: constants } = useFetchConstants();

  const { teams } = useFetchTeams();

  const { availabilityType, role, team } = useWatch({ control });

  const [availableHoursItems, setAvailableHoursItems] = useState<Array<SelectItem<string>>>(
    makeSelectItems(CONTACT_AVAILABILITY_HOURS)
  );

  const roleItems = useMemo<Array<SelectItem<string>>>(
    () => [
      { name: 'Freelancer', value: 'freelancer' },
      { name: 'Team', value: 'team' },
    ],
    []
  );

  const teamItems = useMemo<Array<SelectItem<string>>>(
    () => teams.map<SelectItem<string>>((team) => ({ name: team.name ?? '', value: team.name ?? '' })),
    [teams]
  );

  const availabilityTypeItems = useMemo<Array<SelectItem<string>>>(
    () =>
      (constants?.availabilityTypes ?? []).map<SelectItem<string>>((type) => ({
        value: type,
        name: AVAILABILITY_TYPE_MAP[type as AvailabilityType]?.label ?? type,
      })),
    [constants]
  );

  const handleAvailabilityTypeChange = useCallback(
    (_availabilityType: string | null) => {
      const availabilityType = _availabilityType as AvailabilityType;
      const setValueOptions: Parameters<typeof setValue>[2] = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      setAvailableHoursItems(makeSelectItems(AVAILABILITY_TYPE_MAP[availabilityType].hoursList));
      setValue('availableHours', AVAILABILITY_TYPE_MAP[availabilityType].defaultHours, setValueOptions);
      setValue('availabilityType', availabilityType, setValueOptions);
    },
    [setValue]
  );

  const handleRoleChange = useCallback(
    (role: string | null) => {
      role = role as string;
      setValue('role', role);
      setValue('team', '');
      setValue('country', '');
    },
    [setValue]
  );

  const handleTeamChange = useCallback(
    (team: string | null) => {
      team = team as string;

      const foundTeam = teams.find((item) => item.name === team);

      setValue('team', team);
      setValue('country', (foundTeam?.country ?? '').trim());
    },
    [setValue, teams]
  );

  const showTeamField = role === 'team';
  const showMainFields = role === 'freelancer' || (role === 'team' && team);

  const fields: Array<React.ReactNode> = [];

  fields.push(
    <Controller
      name="role"
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          onChange={handleRoleChange}
          error={!!getError(fieldState)}
          label="Role"
          color="primary"
          items={roleItems}
          withoutClear
        />
      )}
    />
  );

  if (showTeamField) {
    fields.push(
      <Controller
        name="team"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            onChange={handleTeamChange}
            error={!!getError(fieldState)}
            label="Team"
            color="primary"
            items={teamItems}
            withoutClear
          />
        )}
      />
    );
  }

  if (showMainFields) {
    fields.push(
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput {...field} error={!!getError(fieldState)} label="First name*" color="primary" />
        )}
      />
    );

    fields.push(
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput {...field} error={!!getError(fieldState)} label="Last name*" color="primary" />
        )}
      />
    );

    fields.push(
      <Controller
        name="country"
        control={control}
        render={({ field, fieldState }) => (
          <CountrySelect {...field} error={!!getError(fieldState)} label="Country" color="primary" />
        )}
      />
    );

    fields.push(
      <Controller
        name="availabilityType"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            onChange={handleAvailabilityTypeChange}
            error={!!getError(fieldState)}
            label="Availability type*"
            color="primary"
            items={availabilityTypeItems}
            withoutClear
          />
        )}
      />
    );

    fields.push(
      <Controller
        name="availableHours"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            error={availabilityType && !!getError(fieldState)}
            label="Available hours*"
            color="primary"
            items={availableHoursItems}
            disabled={!availabilityType}
            withoutClear
          />
        )}
      />
    );
  }

  return fields;
};
