import { memo } from 'react';
import Highlighter from 'react-highlight-words';
import { Checkbox, Tooltip } from '@mui/material';

import { useTextOverflow } from 'hooks';
import { SelectItem } from 'types';

import { Root, Label } from './checkbox-item.styled';

interface CheckboxItemProps {
  item: SelectItem<string>;
  value: boolean;
  onChange: (item: SelectItem<string>, value: boolean) => void;
  isSelectAll?: boolean;
  search?: string;
}

const CheckboxItem = ({ item, value, onChange, isSelectAll = false, search }: CheckboxItemProps) => {
  const [textRef, isOverflow] = useTextOverflow();

  const handleClick = () => {
    onChange(item, !value);
  };

  return (
    <Root $isSelected={value} $isSelectAll={isSelectAll} onClick={handleClick}>
      <Checkbox checked={value} />
      <Tooltip title={isOverflow ? item.name : ''}>
        <Label ref={textRef}>
          <Highlighter
            searchWords={[search ?? '']}
            textToHighlight={item.name}
            highlightStyle={{ backgroundColor: 'rgb(220, 255, 2, 0.5)' }}
          />
        </Label>
      </Tooltip>
    </Root>
  );
};

export default memo(CheckboxItem);
