import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

type ApproveHubspotProjectCandidate = Pick<
  HubspotProjectCandidate,
  | 'contactId'
  | 'hubspotProjectId'
  | 'candidateRatePerHour'
  | 'rateCommentForDev'
  | 'readyToStartAt'
  | 'customerSuccessManagerId'
>;

export const approveHubspotProjectCandidate = (
  payload: ApproveHubspotProjectCandidate
): Promise<HubspotProjectCandidate> => ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts/approve`, payload);
