import { HubspotProject } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateProjectAssigneePayload {
  dealId: string;
  assigneeId: string;
}

export const updateProjectAssignee = ({ dealId, assigneeId }: UpdateProjectAssigneePayload): Promise<HubspotProject> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/${dealId}/assignee`, { userId: assigneeId });
