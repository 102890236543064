import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateHubspotProjectTagsPayload {
  tagIds: Array<string>;
  hubspotProjectId: string;
}

export const updateHubspotProjectTags = (payload: UpdateHubspotProjectTagsPayload): Promise<void> =>
  ai.put(`${ENDPOINTS.TAGS}/hubspot-projects`, payload);
