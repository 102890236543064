import { Descendant } from 'slate';
import { useSortable } from '@dnd-kit/sortable';
import { ContactRoles, ContactStatuses, ContactAvailabilityTypes, ContactAvailabilityHours } from 'config';

export enum SortOption {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export type GridSettingsProp = keyof GridSettings;

export interface ColumnStoredSettings {
  width?: number;
  isVisible: boolean;
}

export type ColumnsStoredSettings = Record<string, ColumnStoredSettings>;

export type SortedColumn = {
  fieldName?: string;
  sortOption?: SortOption;
};

export interface GridSettings {
  role: ContactRoles;
  team: null | string;
  operatorId: null | string;
  dedicatedOperatorId: null | string;
  availabilityType: ContactAvailabilityTypes[];
  availabilityHours: ContactAvailabilityHours[];
  statuses: ContactStatuses[];
  globalSearch: string;
  sortedColumn: SortedColumn;
  columnSearches: Record<string, string>;
  page: number;
  perPage: number;
  leadType: string;
  campaignTagsInclude: Array<string>;
  campaignTagsExclude: Array<string>;
  forFutureReasonsInclude: Array<string>;
  forFutureReasonsExclude: Array<string>;
  selfAssessmentInclude: Array<string>;
  commercialExperience: number | null;
  relevantCountriesOnly: boolean;
  websiteSources: Array<string>;
  adTypeSources: Array<string>;
  campaignSources: Array<string>;
  customSources: Array<string>;
  leadTypeSource: string;
  referredBySomeone: boolean;
  referredById: string | null;
  tagIds: string[];
}

export type GridSettingsSetter = (
  item: GridSettingsProp,
  value: any,
  settingChangeRequiresConfirmation?: boolean
) => void;

export type SortedColumnSetter = (fieldName: string, sortOption: SortOption) => void;

export type ColumnSearchQuerySetter = (fieldName: string, query: string) => void;

export interface CustomElement {
  type: EditorBlockFormat;
  children: Array<Descendant>;
}

export interface CustomText {
  text: string;
  bold?: true;
  italic?: true;
  link?: true;
}

export type EditorMarkFormat = 'bold' | 'italic';

export type EditorBlockFormat =
  | 'paragraph'
  | 'bulleted-list'
  | 'numbered-list'
  | 'list-item'
  | 'list-item-text'
  | 'link';

export interface EditorTemplate {
  content: Array<Descendant>;
  tooltip: string;
  icon: React.ReactNode;
}

export interface MdEditorTemplate {
  content: string;
  tooltip: string;
  icon: React.ReactNode;
}

export interface SelectItem<T extends string | number> {
  value: T;
  name: string;
}

type UseSortableResult = ReturnType<typeof useSortable>;

export interface SortableItemRenderOptions {
  attributes: UseSortableResult['attributes'];
  listeners: UseSortableResult['listeners'];
  setNodeRef: UseSortableResult['setNodeRef'];
  style: { transform?: string; transition?: string };
}

export interface AddCandidateFormValues {
  role: string;
  team: string;
  firstName: string;
  lastName: string;
  country: string;
  availabilityType: string;
  availableHours: string;
}

export interface AddStatusFormValues {
  status: string;
  comment: string;
  otherReasons?: string;
  disqualificationReasons: Array<string>;
}

export interface AddNoteFormValues {
  note: string;
}

export interface AddAttachmentsFormValues {
  fileName: string;
  file: File | null;
}

export interface CandidateUndoOperation {
  field: string;
  // TODO: for future - need to refactor FieldMutator so that it accepts field name (i.e. Availability Hours vs availability_hours)
  // name: string;
  revertChange: () => void;
}

export interface OrderedCandidateUndoOperation extends CandidateUndoOperation {
  order: number;
}

/**
 * Returns type of the first parameter (object) of function.
 * Note: used for api services
 */
export type GetPayload<F extends (payload: P) => void, P = Parameters<F>[0]> = Parameters<F>[0];

export type DashboardColumnConfig = {
  field: string;
  visible: boolean;
  artificial?: boolean;
  nativeFields?: string[];
};

export type CandidateOperatorsPayload = {
  candidateId: string;
  operatorId: string;
  isDedicated?: boolean;
};

export interface InviteToACallFormValues {
  callType: string;
  invitationLink: string;
}

export type Setter<T> = (value: T) => void;
