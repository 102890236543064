import { GridSettings, LeadTypeOptionValue } from 'types';
import { ContactRoles } from 'config';

export const getFiltersCount = (gridSettings: GridSettings): number => {
  let count = 0;

  const {
    role,
    availabilityType,
    availabilityHours,
    operatorId,
    dedicatedOperatorId,
    statuses,
    leadType,
    campaignTagsInclude,
    campaignTagsExclude,
    forFutureReasonsInclude,
    forFutureReasonsExclude,
    selfAssessmentInclude,
    commercialExperience,
    relevantCountriesOnly,
    columnSearches,
    leadTypeSource,
    websiteSources,
    adTypeSources,
    campaignSources,
    customSources,
    tagIds,
  } = gridSettings;

  if (role !== ContactRoles.ALL) {
    count += 1;
  }

  if (availabilityType.length) {
    count += 1;
  }

  if (availabilityHours.length) {
    count += 1;
  }

  if (operatorId) {
    count += 1;
  }

  if (dedicatedOperatorId) {
    count += 1;
  }

  if (statuses.length) {
    count += 1;
  }

  if (leadType !== LeadTypeOptionValue.ALL_LEADS) {
    count += 1;
  }

  if (campaignTagsInclude.length) {
    count += 1;
  }

  if (campaignTagsExclude.length) {
    count += 1;
  }

  if (forFutureReasonsInclude.length) {
    count += 1;
  }

  if (forFutureReasonsExclude.length) {
    count += 1;
  }

  if (selfAssessmentInclude.length) {
    count += 1;
  }

  if (typeof commercialExperience === 'number') {
    count += 1;
  }

  if (relevantCountriesOnly) {
    count += 1;
  }

  if (leadTypeSource !== LeadTypeOptionValue.ALL_LEADS) {
    count += 1;
  }

  if (websiteSources.length) {
    count += 1;
  }

  if (adTypeSources.length) {
    count += 1;
  }

  if (campaignSources.length) {
    count += 1;
  }

  if (customSources.length) {
    count += 1;
  }

  if (tagIds.length) {
    count += 1;
  }

  const columnSearchesCount = Object.keys(columnSearches).length;
  count += columnSearchesCount;

  return count;
};
