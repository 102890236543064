import React from 'react';
import { Chip, Box } from '@mui/material';
import { FlexCenteredCell } from '..';

interface Props {
  priority: string;
}

type ChipCssProps = {
  backgroundColor: string;
  iconColor: string;
};

const priorityToColor: Record<string, ChipCssProps> = {
  low: { backgroundColor: 'rgba(76, 175, 80, 0.08)', iconColor: '#4CAF50' },
  medium: { backgroundColor: 'rgba(237, 108, 2, 0.08)', iconColor: '#FFB547' },
  high: { backgroundColor: 'rgba(244, 67, 54, 0.08)', iconColor: '#F44336' },
};

const priorityToLabel: Record<string, string> = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

const PriorityCell = ({ priority }: Props) => {
  if (!priority) {
    return null;
  }

  const colors = priorityToColor[priority];

  return (
    <Chip
      label={priorityToLabel[priority]}
      size="small"
      sx={{
        height: '24px',
        ...(colors
          ? {
              '&.MuiChip-root': { backgroundColor: colors.backgroundColor },
              '& .MuiChip-deleteIcon': { cursor: 'auto' },
            }
          : {}),
      }}
      {...(colors && {
        onDelete: () => null,
        deleteIcon: (
          <FlexCenteredCell>
            <Box sx={{ width: '10px', height: '10px', borderRadius: '5px', backgroundColor: colors.iconColor }} />
          </FlexCenteredCell>
        ),
      })}
    />
  );
};

export default PriorityCell;
