import { useQuery } from 'react-query';

import { prepareQueryResult } from '../utils';
import { fetchTeams, fetchTeamNames } from './teams-service';

export const useFetchTeams = () => {
  const result = useQuery('teams', () => fetchTeams());
  return prepareQueryResult('teams', result, []);
};

export const useFetchTeamNames = () => {
  const result = useQuery('team-names', () => fetchTeamNames());
  return prepareQueryResult('teamNames', result, []);
};
