import React, { memo, useCallback } from 'react';
import { Descendant, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { EditorTemplate } from 'types';

import BaseToolbarButton from './base-toolbar-button';

interface Props {
  className?: string;
  template: EditorTemplate;
  rerenderEditor: () => void;
  onChange: (value: Array<Descendant>) => void;
  onSubmit: (value: Array<Descendant>) => void;
}

const TemplateButton = ({
  className,
  template: { content, tooltip, icon },
  rerenderEditor,
  onChange,
  onSubmit,
}: Props) => {
  const editor = useSlate();

  const handleMouseDown = useCallback(() => {
    editor.children = [
      ...content,
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
      ...editor.children,
    ];

    onChange(editor.children);
    onSubmit(editor.children);
    Transforms.deselect(editor);
    ReactEditor.blur(editor);
    rerenderEditor();
  }, [editor, content, onChange, onSubmit, rerenderEditor]);

  return (
    <BaseToolbarButton
      className={className}
      isActive={false}
      onMouseDown={handleMouseDown}
      tooltip={tooltip}
      icon={icon}
    />
  );
};

export default memo(TemplateButton);
