import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.17px',
}));
