import React, { memo } from 'react';
import { Box } from '@mui/material';

import { Root, Header, Content } from './card.styled';

export interface CardProps {
  className?: string;
  /**
   * Returns react node to render
   * in left side of the card header
   */
  renderHeaderLeftPart?: () => React.ReactNode;
  /**
   * Returns react node to render
   * in right side of the card header
   */
  renderHeaderRightPart?: () => React.ReactNode;
  /**
   * Node to be rendered
   * before the header
   */
  preheader?: React.ReactNode;
  /**
   * Returns react node to render
   * in the end of the card
   */
  renderAfterContent?: () => React.ReactNode;
  children: React.ReactNode;
}

const Card = ({
  className,
  renderHeaderLeftPart,
  renderHeaderRightPart,
  preheader,
  renderAfterContent,
  children,
}: CardProps) => {
  return (
    <Root className={className}>
      <Box>{preheader}</Box>
      <Header>
        {renderHeaderLeftPart ? renderHeaderLeftPart() : <div></div>}
        {renderHeaderRightPart ? renderHeaderRightPart() : <div></div>}
      </Header>
      <Content>{children}</Content>
      <Box>{renderAfterContent ? renderAfterContent() : <div></div>}</Box>
    </Root>
  );
};

export default memo(Card);
