import { Tag } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchCandidateTagsPayload {
  candidateId: string;
}

export const fetchCandidateTags = ({
  candidateId,
}: FetchCandidateTagsPayload): Promise<{ contactProjectTags: Array<Tag>; contactTags: Array<Tag> }> =>
  ai.get(`${ENDPOINTS.TAGS}/contacts/${candidateId}`);
