import React, { memo } from 'react';
import { Box } from '@mui/material';
import { RenderLeafProps } from 'slate-react';

interface Props {
  attributes: RenderLeafProps['attributes'];
  leaf: RenderLeafProps['leaf'];
  children: React.ReactNode;
  altHeldDown: boolean;
  resetAltWatcher: () => void;
  readOnly: boolean;
}

const Leaf: React.FC<Props> = ({ attributes, leaf, children, altHeldDown, resetAltWatcher, readOnly }) => {
  let jsxToRender = children;

  if (leaf.link) {
    jsxToRender = (
      <Box
        sx={{
          display: 'contents',
          '& > a': {
            ...(!altHeldDown && !readOnly && { cursor: 'auto !important' }),
          },
        }}
        component="span"
      >
        <a
          href={leaf.text}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();

            if (altHeldDown || readOnly) {
              resetAltWatcher();
              window.open(leaf.text, '_blank');
            }
          }}
        >
          {children}
        </a>
      </Box>
    );
  }

  if (leaf.bold) {
    jsxToRender = <strong>{jsxToRender}</strong>;
  }

  if (leaf.italic) {
    jsxToRender = <em>{jsxToRender}</em>;
  }

  return <span {...attributes}>{jsxToRender}</span>;
};

export default memo(Leaf);
