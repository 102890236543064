import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Reminder, ReminderBase } from 'types';
import { fetchRemainders, addReminder, completeReminder } from './service';

export const useFetchReminders = (candidateId: string) => {
  const { data, isLoading, isError, isFetched } = useQuery<Reminder[], Error>(['fetch-reminders', candidateId], () =>
    fetchRemainders(candidateId)
  );
  return {
    reminders: data || [],
    remindersAreLoading: isLoading,
    remindersFetchIsError: isError,
    remindersFetchCompleted: isFetched,
  };
};

export const useAddReminder = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: ReminderBase) => addReminder(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('fetch-reminders');
    },
  });
};

export const useCompleteReminder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (reminderId: string) => completeReminder({ reminderId, patch: { completedDatetimeMs: new Date().valueOf() } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetch-reminders');
      },
    }
  );
};
