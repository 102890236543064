import React, { memo } from 'react';
import { RenderElementProps } from 'slate-react';

type Element = RenderElementProps['element'];

interface Props {
  attributes: RenderElementProps['attributes'];
  element: Element;
  children: React.ReactNode;
}

const Element: React.FC<Props> = ({ attributes, element, children }) => {
  const typeMap: Record<Element['type'], () => React.ReactElement> = {
    'bulleted-list': () => <ul {...attributes}>{children}</ul>,
    'numbered-list': () => <ol {...attributes}>{children}</ol>,
    'list-item': () => <li {...attributes}>{children}</li>,
    'list-item-text': () => <span {...attributes}>{children}</span>,
    paragraph: () => <p {...attributes}>{children}</p>,
    link: () => (
      <a {...attributes} href={(element as unknown as { href: string }).href} target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  };

  return typeMap[element.type]();
};

export default memo(Element);
