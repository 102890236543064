import { Editor } from 'slate';

import { EditorMarkFormat } from 'types';

export const isMarkActive = (editor: Editor, format: EditorMarkFormat) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: EditorMarkFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
    return;
  }

  Editor.addMark(editor, format, true);
};
