import React, { useCallback, useState } from 'react';

export class EntityMutatorAdvancedError extends Error {
  constructor({ message, skipValidation }: { message: string; skipValidation: boolean }) {
    super(message);
    this.skipValidation = skipValidation;
  }

  /**
   * Show error message without preventing mutating.
   */
  public skipValidation: boolean;
}

export interface EntityMutatorProps<Entity extends object, FieldName extends keyof Entity> {
  /**
   * Comparison strategy for field.
   * Useful to avoid extra mutation if there is no changes.
   *
   * If `null` then it ignores checking value.
   */
  equals?: ((a: Entity[FieldName], b: Entity[FieldName]) => boolean) | null;
  /**
   * Validation for field.
   */
  validation?: {
    fn: (value: Entity[FieldName]) => Error | null;
    message: (value: Entity[FieldName], error: Error) => string;
  };
  /**
   * Target entity to mutate.
   */
  entity: Entity;
  /**
   * Target field to mutate.
   */
  fieldName: FieldName;
  /**
   * Field label.
   */
  label: string;
  /**
   * Mutation handler.
   */
  onMutate: (value: Entity[FieldName], fieldName: FieldName) => void;
  /**
   * Focus handler.
   */
  onFocus?: () => void;
  /**
   * Blur handler.
   */
  onBlur?: () => void;
  /**
   * Renders field.
   */
  render: (renderParams: {
    label: string;
    initialValue: Entity[FieldName];
    onSubmit: (value: Entity[FieldName]) => void;
    onFocus: () => void;
    onBlur: () => void;
    error?: string;
  }) => React.ReactNode;
}

/**
 * Wrapper for single fields for mutating certain entity.
 * Based on this abstract component we can create spefic mutators.
 * See examples: CandidateMutator, ProjectMutator.
 */
const EntityMutator = function <Entity extends object, FieldName extends keyof Entity>({
  equals,
  validation,
  entity,
  fieldName,
  label,
  onMutate,
  onFocus,
  onBlur,
  render,
}: EntityMutatorProps<Entity, FieldName>) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const value = entity[fieldName];

  const handleSubmit = useCallback(
    (submittedValue: Entity[FieldName]) => {
      if (validation) {
        const { fn, message } = validation;

        const error = fn(submittedValue);

        if (error) {
          setErrorMessage(message(submittedValue, error));

          const isAdvancedError = error instanceof EntityMutatorAdvancedError;

          if (!isAdvancedError || !error.skipValidation) {
            return;
          }
        }
      }

      const ignoreEqualityCheck = equals === null;
      const valueIsNotChanged = equals ? equals(value, submittedValue) : value === submittedValue;

      if (!ignoreEqualityCheck && valueIsNotChanged) {
        return;
      }

      onMutate(submittedValue, fieldName);
    },
    [equals, fieldName, onMutate, validation, value]
  );

  const handleFocus = useCallback(() => {
    setErrorMessage(undefined);

    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  return (
    <>
      {render({
        label,
        initialValue: value,
        onSubmit: handleSubmit,
        onFocus: handleFocus,
        onBlur: handleBlur,
        error: errorMessage,
      })}
    </>
  );
};

export default EntityMutator;
