import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UnassignCandidateTagPayload {
  tagId: string;
  candidateId: string;
}

export const unassignCandidateTag = (payload: UnassignCandidateTagPayload): Promise<void> =>
  ai.delete(`${ENDPOINTS.TAGS}/${payload.tagId}/contacts/${payload.candidateId}`);
