import React from 'react';
import { useRouter } from 'next/router';
import { Tabs, Tab, Chip, useMediaQuery, useTheme } from '@mui/material';

import { useAltHeldDown } from 'hooks';

import CustomTab from './custom-tab';
import { PageTab } from './pages-tabs.types';
import { TabTitle, TabContainer, TabsWrapper } from './page-tabs.styled';

export interface PageTabsProps {
  tabs: Array<PageTab>;
  pageId: string;
  onSelect: (pageId: string) => void;
  /**
   * Hides tab labels on screen widths less than 'lg'.
   * Default value: true
   */
  hideLabelsDownLg?: boolean;
}

const PageTabs: React.FC<PageTabsProps> = ({ tabs, pageId, onSelect, hideLabelsDownLg = true }) => {
  const router = useRouter();

  const getTabLink = (pageId: string) => {
    const pathWithoutQuery = router.asPath.split('?')[0];
    return `${pathWithoutQuery}?tab=${pageId}`;
  };

  const { altHeldDown, resetAltWatcher } = useAltHeldDown();

  const selectedTabIdx = tabs.findIndex((tab) => tab.pageId === pageId);

  const theme = useTheme();

  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <TabsWrapper>
      <Tabs value={selectedTabIdx} textColor="primary" indicatorColor="primary" variant="scrollable">
        {tabs.map(({ name, badgeValue, Icon, pageId, dataTestId }) => (
          <Tab
            key={`profile-tab-${name}`}
            component={CustomTab}
            customProps={{
              href: getTabLink(pageId),
              tooltipTitle: Icon && downLg ? name : '',
              altHeldDown: altHeldDown,
              resetAltWatcher: resetAltWatcher,
              onSelect: () => onSelect(pageId),
            }}
            sx={(theme) => ({
              textTransform: 'none',
              ...(hideLabelsDownLg && {
                [theme.breakpoints.down('lg')]: {
                  minWidth: '75px',
                },
                [theme.breakpoints.down('md')]: {
                  minWidth: '30px',
                },
              }),
            })}
            label={
              <TabContainer>
                {Icon && downLg && <Icon />}
                <TabTitle hideLabelsDownLg={hideLabelsDownLg}>{name}</TabTitle>
                {!!badgeValue && <Chip label={badgeValue} size="small" color="primary" sx={{ marginLeft: '4px' }} />}
              </TabContainer>
            }
            data-testid={dataTestId}
          />
        ))}
      </Tabs>
    </TabsWrapper>
  );
};

export default PageTabs;
