import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const SectionTitle = styled(Typography)(() => ({
  width: '100%',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
}));

export const StyledAddButton = styled(Button)(() => ({
  height: '36px',
  padding: '6px 16px',
  marginLeft: '16px',
  textTransform: 'none',
}));

export const backgrounds = {
  primary: 'white',
  secondary: '#EEEEEE',
};
