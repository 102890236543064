import React, { memo, useMemo, useState } from 'react';
import {
  SxProps,
  Autocomplete,
  TextField,
  Chip,
  AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps,
} from '@mui/material';

import { SelectItem } from 'types';

interface Props {
  sx?: SxProps;
  label: string;
  placeholder?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  chipColor?: 'default' | 'primary';
  variant?: 'standard' | 'outlined';
  items: Array<SelectItem<string>>;
  value?: Array<string>;
  onChange?: (value: Array<string>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean | string;
  freeSolo?: boolean;
  disabledItems?: Array<string>;
  /**
   * Workaround for preventing input value reset
   * P.S. this is MUI internal bug
   */
  inputControlled?: boolean;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  renderTags?: (selectedItems: SelectItem<string>[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  dataTestId?: string;
}

const MultiAutocomplete = ({
  sx,
  label,
  placeholder,
  color = 'primary',
  chipColor = 'default',
  variant = 'standard',
  items,
  value = [],
  onChange = () => null,
  onBlur,
  error,
  freeSolo = false,
  disabledItems,
  inputControlled,
  inputValue,
  onInputChange = () => {},
  renderTags,
  dataTestId,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const itemsMap = useMemo(
    () => items.reduce<Record<string, SelectItem<string>>>((acc, item) => ({ ...acc, [item.value]: item }), {}),
    [items]
  );

  const adjustParams = (params: AutocompleteRenderInputParams): AutocompleteRenderInputParams =>
    inputControlled
      ? {
          ...params,
          inputProps: {
            ...params.inputProps,
            value: inputValue ?? '',
            onChange: () => null,
            onInput: (event) => {
              const inputValue = (event.nativeEvent.target as HTMLInputElement).value;
              onInputChange(inputValue);
              setIsOpen(true);
            },
          },
        }
      : params;

  return (
    <Autocomplete
      sx={sx}
      open={isOpen}
      multiple
      freeSolo={freeSolo}
      options={items}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      getOptionDisabled={(option) =>
        [...(value ?? []), ...(disabledItems ?? [])].includes(typeof option === 'string' ? option : option.value)
      }
      fullWidth
      value={value.map((v) => itemsMap[v] ?? v)}
      onChange={(_, items) => onChange(items.map((item) => (typeof item === 'string' ? item : item?.value)))}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderTags={(selectedItems, getTagProps) =>
        renderTags
          ? renderTags(selectedItems, getTagProps)
          : selectedItems.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                color={chipColor}
                label={typeof item === 'string' ? item : item?.name}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
      }
      renderInput={(params) => {
        const adjustedParams = adjustParams(params);

        return (
          <TextField
            {...adjustedParams}
            sx={(theme) => ({
              '.MuiInput-root::after': {
                borderColor: theme.palette.primary.main,
              },
              ...(variant === 'outlined' && {
                '.MuiOutlinedInput-root.MuiOutlinedInput-root': {
                  pt: '5px',
                  pb: '5px',
                  backgroundColor: '#fff',
                },
                ...(!value.length && {
                  '.MuiInputLabel-root:not(.Mui-focused)': {
                    transform: 'translate(14px, 13px) scale(1)',
                  },
                }),
              }),
            })}
            autoComplete="none"
            variant={variant}
            fullWidth
            placeholder={placeholder}
            label={label}
            color={color}
            onBlur={onBlur}
            error={!!error}
            {...(typeof error === 'string' ? { helperText: error } : null)}
            {...(dataTestId && { 'data-testid': `${dataTestId}__input-wrapper` })}
            inputProps={{
              ...adjustedParams.inputProps,
              'data-testid': `${dataTestId}__input`,
            }}
            InputProps={{
              ...adjustedParams.InputProps,
              endAdornment: adjustedParams?.InputProps?.endAdornment
                ? React.cloneElement(adjustedParams?.InputProps?.endAdornment as React.ReactElement, {
                    ...(dataTestId && { 'data-testid': `${dataTestId}__end-adornment` }),
                  })
                : null,
            }}
          />
        );
      }}
    />
  );
};

export default memo(MultiAutocomplete);
