import React, { useMemo } from 'react';

import { Autocomplete as OriginalAutocomplete } from './autocomplete';

/**
 * HOC for Autocomplete for avoiding duplicates in items
 * because it results in incorrect rendering
 */
export const mapProps = (Component: typeof OriginalAutocomplete): typeof OriginalAutocomplete => {
  const Autocomplete: typeof OriginalAutocomplete = ({ items, ...rest }) => {
    const uniqueItems = useMemo(() => {
      const occurenceMap: Record<string, boolean> = {};
      return items.filter((item) => {
        if (occurenceMap[item.value]) {
          return false;
        }

        occurenceMap[item.value] = true;
        return true;
      });
    }, [items]);
    return <Component items={uniqueItems} {...rest} />;
  };
  return Autocomplete;
};
