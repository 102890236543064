import React, { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z as zod } from 'zod';
import { Box } from '@mui/material';

import { FlexColumn } from 'components';
import { Content, Title, Actions } from 'components/modal-form';
import { AddCandidateFormValues } from 'types';
import { useCreateCandidate } from 'api';
import { validators } from 'utils/validation';

import { useFormFields } from './add-candidate-form.hooks';

const schema = zod
  .object({
    role: zod.string().refine(validators.string.min({ value: 1, trim: true })),
    team: zod.string(),
    firstName: zod.string().refine(validators.string.min({ value: 1, trim: true })),
    lastName: zod.string().refine(validators.string.min({ value: 1, trim: true })),
    country: zod.string(),
    availabilityType: zod.string().min(1),
    availableHours: zod.string().min(1),
  })
  .superRefine((data, ctx) => {
    if (data.role === 'Team' && !data.team) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        path: ['team'],
      });
    }
  });

interface Props {
  onClose: () => void;
}

const AddCandidateForm = ({ onClose }: Props) => {
  const { mutate: createCandidate, isLoading } = useCreateCandidate();

  const defaultValues: AddCandidateFormValues = useMemo(
    () => ({
      role: 'freelancer',
      team: '',
      firstName: '',
      lastName: '',
      country: '',
      availabilityType: '',
      availableHours: '',
    }),
    []
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<AddCandidateFormValues>({ defaultValues, resolver: zodResolver(schema), mode: 'onChange' });

  const submit = handleSubmit((data) => {
    createCandidate(
      {
        role: data.role,
        ...(data.team ? { team: data.team } : {}),
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        availability_type: data.availabilityType,
        available_hours_per_week: data.availableHours,
      },
      {
        onSuccess: (candidate) => {
          window.open(`/profile/${candidate.id}`, '_blank');
          onClose();
        },
      }
    );
  });

  const fields = useFormFields({ control, setValue });

  return (
    <Box>
      <Content>
        <Title sx={{ mb: '24px' }}>Add candidate</Title>
        <FlexColumn sx={{ gap: '24px' }}>
          {fields.map((field, i) => (
            <Box key={`add-candidate-form-field-${i}`}>{field}</Box>
          ))}
        </FlexColumn>
      </Content>
      <Actions submitLabel="Add candidate" onCancel={onClose} onSubmit={submit} disabled={!isValid || isLoading} />
    </Box>
  );
};

export default memo(AddCandidateForm);
