export interface Tag {
  id: string;
  name: string;
  category: TagCategory;
  group: TagGroup;
  authorId: string;
  contactMentions?: number;
  contactProjectMentions?: number;
  hubspotProjectMentions?: number;
  createdDate: Date;
  lastChangeDate: Date;
  deletedDate?: Date;
}

export enum ProjectTagCategory {
  Industries = 'Industries',
  ProductTypes = 'ProductTypes',
  BusinessModels = 'BusinessModels',
}

export enum HonorsTagCategory {
  LemonHonors = 'LemonHonors',
  ClientHonors = 'ClientHonors',
}

export enum CampaignTagCategory {
  Reactivation = 'Reactivation',
}

export type TagCategory = ProjectTagCategory | HonorsTagCategory | CampaignTagCategory;

export enum TagGroup {
  Project = 'Project',
  Honors = 'Honors',
  Campaign = 'Campaign',
}

export interface TagLog {
  id: string;
  itemType: TagLogItemType;
  actionType: TagLogActionType;
  contactId?: string;
  hubspotProjectId?: string;
  tagId: string;
  tagName?: string;
  authorType: string;
  authorId: string;
  comment?: string;
  newValue?: string;
  oldValue?: string;
  createdDate: string;
  lastChangeDate: string;
}

export enum TagLogItemType {
  Tag = 'Tag',
  ContactTag = 'ContactTag',
  HubspotProjectTag = 'HubspotProjectTag',
  HubspotProjectContactTag = 'HubspotProjectContactTag',
}

export enum TagLogActionType {
  TagCreated = 'TagCreated',
  TagRenamed = 'TagRenamed',
  TagDeleted = 'TagDeleted',
  ContactTagAssigned = 'ContactTagAssigned',
  ContactTagUnassigned = 'ContactTagUnassigned',
  ContactProjectTagAssigned = 'ContactProjectTagAssigned',
  ContactProjectTagUnassigned = 'ContactProjectTagUnassigned',
}
