import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateCandidateProjectTagsPayload {
  tagIds: Array<string>;
  projectId: string;
  contactId: string;
}

export const updateCandidateProjectTags = (payload: UpdateCandidateProjectTagsPayload): Promise<void> =>
  ai.put(`${ENDPOINTS.TAGS}/contacts/projects`, payload);
