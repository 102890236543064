import React, { useState, useCallback, useEffect } from 'react';
import { Subtract } from 'utility-types';

import { Props as FormInputProps } from '../text-inputs/form-input';

type ExcludedProps = Pick<FormInputProps, 'value'>;

type UncontrolledAdditionalProps = {
  initialValue?: string;
  onSubmit?: (value: string) => void;
};

/**
 * HOC for convertation a controlled component
 * that extend FormInput to an uncontrolled component
 */
export function makeUncontrolledInput<OriginalComponentProps extends FormInputProps>(
  Component: React.FC<OriginalComponentProps>
) {
  type Props = UncontrolledAdditionalProps & Subtract<OriginalComponentProps, ExcludedProps>;

  const WrappedComponent = ({
    initialValue = '',
    onSubmit = () => null,
    onChange = () => null,
    onBlur = () => null,
    onKeyUp = () => null,
    multiline,
    ...baseProps
  }: Props) => {
    const [localValue, setLocalValue] = useState<string>(initialValue);

    const handleSubmit = useCallback(() => {
      if (localValue !== initialValue) {
        onSubmit(localValue);
      }
    }, [onSubmit, initialValue, localValue]);

    const handleChange = useCallback(
      (value: string) => {
        setLocalValue(value);
        onChange(value);
      },
      [onChange]
    );

    const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (event) => {
        onBlur(event);
        handleSubmit();
      },
      [onBlur, handleSubmit]
    );

    const handleKeyUp = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
      (event) => {
        onKeyUp(event);
        if (!multiline && event.key === 'Enter') {
          handleSubmit();
        }
      },
      [multiline, onKeyUp, handleSubmit]
    );

    useEffect(() => {
      setLocalValue(initialValue);
    }, [initialValue]);

    return (
      <Component
        {...(baseProps as OriginalComponentProps)}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        multiline={multiline}
      />
    );
  };

  return WrappedComponent;
}
