import React, { memo } from 'react';

interface Props {
  condition: boolean;
  children?: React.ReactNode;
  render?: () => React.ReactNode;
  otherwise?: () => React.ReactNode;
}

const If: React.FC<Props> = ({ condition, children, render, otherwise }: Props) => {
  if (condition) {
    return <>{children ?? (render ? render() : null)}</>;
  }

  return otherwise ? <>{otherwise()}</> : null;
};

If.displayName = 'IfComponent';

export default memo(If);
