import React, { memo } from 'react';

import { CopyButton } from 'components';

import ReadonlyField, { ReadonlyFieldProps } from './readonly-field';

type CopyReadonlyField = Omit<ReadonlyFieldProps, 'endIcon'>;

const CopyReadonlyField = (props: CopyReadonlyField) => {
  const { value } = props;

  return (
    <ReadonlyField
      {...props}
      endIcon={value ? <CopyButton sx={{ flexShrink: 0, mt: '12px' }} value={value} /> : null}
    />
  );
};

export default memo(CopyReadonlyField);
