import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

export interface UpdateHubspotProjectCandidatePayload {
  fieldsToUpdate: Omit<
    Partial<HubspotProjectCandidate>,
    'id' | 'contactId' | 'contact' | 'createdAt' | 'hubspotProjectId' | 'hubspotProject' | 'matcherId'
  > &
    Pick<HubspotProjectCandidate, 'contactId' | 'hubspotProjectId'>;
}

export const updateHubspotProjectCandidate = ({
  fieldsToUpdate,
}: UpdateHubspotProjectCandidatePayload): Promise<HubspotProjectCandidate> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts`, fieldsToUpdate);
