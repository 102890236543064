import { HubspotProjectSpecialization } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectSpecializationsPayload {
  hubspotProjectId: string;
}

export const fetchHubspotProjectSpecializations = ({
  hubspotProjectId,
}: FetchHubspotProjectSpecializationsPayload): Promise<Array<HubspotProjectSpecialization>> =>
  ai.get(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/specializations`);
