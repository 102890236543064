import { HubspotProject } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectByProjectIdPayload {
  hubspotProjectId: string | null;
}

export const fetchHubspotProjectByProjectId = async ({
  hubspotProjectId,
}: FetchHubspotProjectByProjectIdPayload): Promise<HubspotProject | null> =>
  hubspotProjectId ? ai.get(`${ENDPOINTS.HUBSPOT_PROJECTS}/crm/${hubspotProjectId}`) : null;
