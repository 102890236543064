import { useEffect, useMemo } from 'react';

import { debounce } from 'utils';

export const useDebounce = <T extends (...args: any[]) => any>(
  fn: T,
  ms: number
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  const [debouncedFun, dispose] = useMemo(() => debounce(fn, ms), [fn, ms]);

  useEffect(() => () => dispose(), [dispose]);

  return debouncedFun;
};
