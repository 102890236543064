import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HotStack } from 'types/domain';
import { addHotStack, deleteHotStack, getHotStacks } from 'api/domain/hot-stacks-service/hot-stacks-service';

const HOT_STACKS_FETCH_KEY = 'hot-stacks-fetch';

export const useHotStacksFetch = () => {
  const { data, isLoading, isError } = useQuery<HotStack[], Error>(HOT_STACKS_FETCH_KEY, async () => {
    const hotStacks = (await getHotStacks())
      .map((hs) => {
        return {
          ...hs,
          skillExperiencePairs: hs.skillExperiencePairs.sort((sep1, sep2) => {
            return sep1.index - sep2.index;
          }),
        };
      })
      .sort((s1: HotStack, s2: HotStack) =>
        s1.skillExperiencePairs[0].title > s2.skillExperiencePairs[0].title ? 1 : -1
      );
    return hotStacks;
  });
  return {
    hotStacks: data || [],
    hotStacksAreLoading: isLoading,
    hotStacksFetchError: isError,
  };
};

export const useAddHotStackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(addHotStack, {
    onSuccess: () => {
      queryClient.invalidateQueries(HOT_STACKS_FETCH_KEY);
    },
  });
};

export const useDeleteHotStackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteHotStack, {
    onSuccess: () => {
      queryClient.invalidateQueries(HOT_STACKS_FETCH_KEY);
    },
  });
};
