import { HubspotProject } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface SendHubspotProjectToMatchIsReady {
  hubspotProjectId: string;
  dealStage: string;
  matchingOutcome: string;
  noMatchReasons?: Array<string>;
  noMatchComment?: string;
}

export const sendHubspotProjectToMatchIsReady = (payload: SendHubspotProjectToMatchIsReady): Promise<HubspotProject> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/stages`, payload);
