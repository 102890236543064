import { Editor, Element, Transforms } from 'slate';

import { CustomElement, EditorBlockFormat } from 'types';

export const isBlockActive = (editor: Editor, format: EditorBlockFormat) => {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const LIST_TYPES: Array<EditorBlockFormat> = ['bulleted-list', 'numbered-list'];

export const toggleBlock = (editor: Editor, format: EditorBlockFormat) => {
  const isActive = isBlockActive(editor, format);

  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });

  const newProperties: Partial<Element> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };

  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block: CustomElement = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
