import { memo, useState, useCallback } from 'react';
import { Subtract } from 'utility-types';

import FormDateInput, { Props as FormDateInputProps } from './form-date-input';

type ExcludedProps = Pick<FormDateInputProps, 'value' | 'onChange'>;

type NoUndefined<T> = T extends undefined ? never : T;
type DateInputValue = NoUndefined<FormDateInputProps['value']>;

type UncontrolledAdditionalProps = {
  initialValue?: DateInputValue;
  onSubmit?: (value: DateInputValue) => void;
};

type Props = UncontrolledAdditionalProps & Subtract<FormDateInputProps, ExcludedProps>;

const UFormDateInput = ({ initialValue = null, onSubmit = () => null, onKeyUp = () => null, ...baseProps }: Props) => {
  const [localValue, setLocalValue] = useState<DateInputValue>(initialValue);

  const handleChange = useCallback(
    (value: Date | null) => {
      setLocalValue(value);
      onSubmit(value);
    },
    [onSubmit]
  );

  const handleKeyUp = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      onKeyUp(event);

      if (event.key === 'Enter') {
        onSubmit(localValue);
      }
    },
    [localValue, onKeyUp, onSubmit]
  );

  return <FormDateInput {...baseProps} value={localValue} onChange={handleChange} onKeyUp={handleKeyUp} />;
};

export default memo(UFormDateInput);
