import { Tag, TagCategory } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface CreateTagPayload {
  name: string;
  category: TagCategory;
}

export const createTag = (payload: CreateTagPayload): Promise<Tag> => ai.post(`${ENDPOINTS.TAGS}/`, payload);
