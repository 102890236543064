import { useEffect } from 'react';

export const useUndoListener = (callback: () => void) => {
  const processKeyPress = (evt: KeyboardEvent) => {
    evt.stopImmediatePropagation();
    if (evt.code === 'KeyZ' && (evt.ctrlKey || evt.metaKey)) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', processKeyPress);
    return () => {
      window.removeEventListener('keydown', processKeyPress);
    };
  });
};
