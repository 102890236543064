import React, { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { EditorMarkFormat } from 'types';

import BaseToolbarButton, { Props as BaseToolbarButtonProps } from './base-toolbar-button';
import { isMarkActive, toggleMark } from './mark-button.utils';

interface Props extends Omit<BaseToolbarButtonProps, 'isActive' | 'onMouseDown'> {
  format: EditorMarkFormat;
}

const MarkButton: React.FC<Props> = ({ format, ...rest }) => {
  const editor = useSlate();

  const handleMouseDown = useCallback(() => {
    toggleMark(editor, format);
  }, [editor, format]);

  return <BaseToolbarButton isActive={isMarkActive(editor, format)} onMouseDown={handleMouseDown} {...rest} />;
};

export default memo(MarkButton);
