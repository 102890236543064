import { useQuery } from 'react-query';

import { fetchSlackUsers } from './service';
import { CommunitySlackUser, LemonSlackUser } from 'types';

export const useFetchLemonSlackUsers = () => {
  return useQuery<LemonSlackUser[], Error>('lemonSlackUsers', () => fetchSlackUsers<LemonSlackUser>('lemon'), {
    staleTime: Infinity,
    cacheTime: 3600000,
  });
};

export const useFetchCommunitySlackUsers = () => {
  return useQuery<CommunitySlackUser[], Error>(
    'communitySlackUsers',
    () => fetchSlackUsers<CommunitySlackUser>('community'),
    {
      staleTime: Infinity,
      cacheTime: 3600000,
    }
  );
};
