import { styled, Box } from '@mui/material';

export const Root = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-4px',
}));

export const ChipWrapper = styled(Box)(() => ({
  padding: '4px',
}));

export const Chip = styled(Box)(({ theme }) => ({
  padding: '3px 10px',
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.secondary,
  borderRadius: '16px',
  display: 'flex',
}));
