import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

type AddHubspotProjectCandidatePayload = Omit<
  HubspotProjectCandidate,
  | 'id'
  | 'contact'
  | 'createdAt'
  | 'hubspotProject'
  | 'declinedAt'
  | 'declineReasons'
  | 'declineFeedback'
  | 'declineStage'
  | 'approvedAt'
  | 'isForClientReview'
  | 'matcherId'
  | 'customerSuccessManagerId'
>;

export const addHubspotProjectCandidate = (
  payload: AddHubspotProjectCandidatePayload
): Promise<HubspotProjectCandidate> =>
  ai.post(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts`, { ...payload, isForClientReview: false });
