import { styled, Box } from '@mui/material';

interface RootProps {
  withIcon: boolean;
  withDelete: boolean;
  bgColor: string;
}

export const Root = styled(Box)<RootProps>(({ withIcon, withDelete, bgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '280px',
  height: '36px',
  paddingLeft: withIcon ? '8px' : '12px',
  paddingRight: withDelete ? '8px' : '12px',
  backgroundColor: bgColor,
  overflow: 'hidden',
}));

export const IconWrapper = styled(Box)(() => ({
  display: 'flex',
  fontSize: '0',
  flexShrink: 0,
}));

export const LabelWrapper = styled(Box)(() => ({
  fontWeight: 500,
  flexShrink: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
