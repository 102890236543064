import React from 'react';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import { formatDateFromTimestamp } from 'utils/dates';

interface Props {
  timestamp: number;
}
const HottestReminderDateCell: React.FC<Props> = ({ timestamp }) => {
  if (!timestamp) {
    return null;
  }
  return (
    <>
      <LocalFireDepartmentOutlinedIcon sx={{ mr: '4px' }} color="error" />
      {formatDateFromTimestamp(timestamp)}
    </>
  );
};
export default HottestReminderDateCell;
