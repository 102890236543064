import { Element, Descendant } from 'slate';
import { type ListsSchema, ListType } from '@prezly/slate-lists';

import { EditorMarkFormat } from 'types';

export const INITIAL_VALUE: Array<Descendant> = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const HOTKEYS: Record<string, EditorMarkFormat> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
};

export const LISTS_SCHEMA: ListsSchema = {
  isConvertibleToListTextNode(node) {
    return Element.isElementType(node, 'paragraph');
  },
  isDefaultTextNode(node) {
    return Element.isElementType(node, 'paragraph');
  },
  isListNode(node, type?) {
    if (type === ListType.UNORDERED) return Element.isElementType(node, 'bulleted-list');
    if (type === ListType.ORDERED) return Element.isElementType(node, 'numbered-list');
    return Element.isElementType(node, 'bulleted-list') || Element.isElementType(node, 'numbered-list');
  },
  isListItemNode(node) {
    return Element.isElementType(node, 'list-item');
  },
  isListItemTextNode(node) {
    return Element.isElementType(node, 'list-item-text');
  },
  createDefaultTextNode({ children } = {}) {
    return {
      type: 'paragraph',
      children: children ?? [{ text: '' }],
    };
  },
  createListNode(type = ListType.UNORDERED, { children } = {}) {
    return {
      type: type === ListType.UNORDERED ? 'bulleted-list' : 'numbered-list',
      children: children ?? [this.createListItemNode()],
    };
  },
  createListItemNode({ children } = {}) {
    return {
      type: 'list-item',
      children: children ?? [this.createListItemTextNode()],
    };
  },
  createListItemTextNode({ children } = {}) {
    return {
      type: 'list-item-text',
      children: children ?? [{ text: '' }],
    };
  },
};
