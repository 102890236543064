import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const FlexCenteredRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FlexYCenteredRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const FlexRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const FlexColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FlexCenteredColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FlexCenteredCell = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FlexYCenteredCell = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
