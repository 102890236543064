import { styled, Box } from '@mui/material';

export const Root = styled(Box)(() => ({
  overflow: 'hidden',
}));

export const CheckboxItemsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));
