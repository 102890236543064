import React from 'react';
import { Avatar, SxProps } from '@mui/material';

interface Props {
  id?: string;
  size?: number;
  children: JSX.Element;
  sx?: SxProps;
}
const CircleAvatar: React.FC<Props> = ({ id, children, size, sx = {} }) => {
  const props = React.useMemo(
    () => ({
      sx: {
        ...sx,
        ...(size
          ? {
              height: `${size}px`,
              width: `${size}px`,
            }
          : {}),
        cursor: 'pointer',
      },
      ...(id ? { id } : {}),
    }),
    [id, size, sx]
  );

  return <Avatar {...props}>{children}</Avatar>;
};

export default CircleAvatar;
