import { type Descendant } from 'slate';
import { jsx } from 'slate-hyperscript';
import { type Content, type Parent, type List, type Root, Link } from 'mdast-util-from-markdown/lib';

import { CustomText } from 'types';

const createEmptyLine = (): Descendant => jsx('element', { type: 'paragraph' }, jsx('text', {}, ''));

const makeChildren = (childNodes: Array<Content>, mapCallback: (childNodes: Array<Content>) => Array<Descendant>) => {
  const linesBefore = childNodes.map((node, i) => {
    const prevNode = childNodes[i - 1];
    return i !== 0 && node.position && prevNode.position
      ? node.position.start.line - prevNode.position.end.line - 1
      : 0;
  });

  const children = mapCallback(childNodes);

  const resultChildren: Array<Descendant> = Array(
    children.length + linesBefore.reduce((acc, amount) => acc + (amount > 0 ? 1 : 0), 0)
  );

  let s = 0;

  for (let i = 0; i < children.length; i++) {
    const linesCount = linesBefore[i];

    if (linesCount > 0) {
      resultChildren[s++] = createEmptyLine();
    }

    resultChildren[s++] = children[i];
  }

  return resultChildren;
};

type MarkAttributes = Omit<CustomText, 'text'>;

interface MapMdAstNodeOptions {
  markAttributes: MarkAttributes;
  atRoot?: boolean;
  atListItem?: boolean;
}

const adjustChildren = (parent: Parent): Parent['children'] => {
  if (parent.type === 'listItem' && parent.children.length === 0 && parent.position) {
    return [
      {
        type: 'text',
        value: '',
        position: {
          ...parent.position,
          start: {
            ...parent.position.start,
            column: parent.position.start.column + 2,
            offset: (parent.position.start.offset ?? 0) + 2,
          },
        },
      },
    ];
  }

  return parent.children;
};

export const mapMdAstNode = (
  node: Content,
  { markAttributes, atRoot = false, atListItem = false }: MapMdAstNodeOptions
): Descendant | Array<Descendant> => {
  if (node.type === 'text') {
    if (atRoot) {
      return jsx('element', { type: 'paragraph' }, jsx('text', markAttributes, node.value));
    }

    if (atListItem) {
      return jsx('element', { type: 'list-item-text' }, jsx('text', markAttributes, node.value));
    }

    return jsx('text', markAttributes, node.value);
  }

  const updatedMarkAttributes: MarkAttributes = {
    ...markAttributes,
    ...(node.type === 'strong' && { bold: true }),
    ...(node.type === 'emphasis' && { italic: true }),
  };

  const children: Array<Descendant> = makeChildren(adjustChildren(node as Parent) ?? [], (childNodes) =>
    childNodes
      .map((childNode) =>
        mapMdAstNode(childNode, {
          markAttributes: updatedMarkAttributes,
          atListItem: node.type === 'listItem',
        })
      )
      .flat()
  );

  if (children.length === 0) {
    children.push(jsx('text', updatedMarkAttributes, ''));
  }

  const defaultDescendantGetter = () => jsx('fragment', {}, children);

  const typeMap: Record<Content['type'], () => Descendant | Array<Descendant>> = {
    list: () => jsx('element', { type: (node as List).ordered ? 'numbered-list' : 'bulleted-list' }, children),
    listItem: () => jsx('element', { type: 'list-item' }, children),
    strong: () => jsx('fragment', {}, children),
    emphasis: () => jsx('fragment', {}, children),
    paragraph: () => jsx('element', { type: atListItem ? 'list-item-text' : 'paragraph' }, children),
    link: () => jsx('element', { type: 'link', href: (node as Link).url }, children),
    blockquote: defaultDescendantGetter,
    code: defaultDescendantGetter,
    html: defaultDescendantGetter,
    table: defaultDescendantGetter,
    image: defaultDescendantGetter,
    text: defaultDescendantGetter,
    definition: defaultDescendantGetter,
    heading: defaultDescendantGetter,
    yaml: defaultDescendantGetter,
    footnoteDefinition: defaultDescendantGetter,
    tableRow: defaultDescendantGetter,
    tableCell: defaultDescendantGetter,
    linkReference: defaultDescendantGetter,
    delete: defaultDescendantGetter,
    break: defaultDescendantGetter,
    footnote: defaultDescendantGetter,
    thematicBreak: defaultDescendantGetter,
    inlineCode: defaultDescendantGetter,
    imageReference: defaultDescendantGetter,
    footnoteReference: defaultDescendantGetter,
  };

  return typeMap[node.type] ? typeMap[node.type]() : typeMap['paragraph']();
};

interface MapMdAstOptions {
  root?: boolean;
}

const mapMdAst = (mdAst: Root, { root = false }: MapMdAstOptions = {}): Array<Descendant> => {
  const descendantList: Array<Descendant> = jsx(
    'fragment',
    {},
    makeChildren(
      mdAst.children,
      (childNodes) =>
        childNodes.map((content) =>
          mapMdAstNode(content, {
            markAttributes: {},
            atRoot: root,
          })
        ) as Array<Descendant>
    )
  );

  if (descendantList.length === 0) {
    descendantList.push(createEmptyLine());
  }

  return descendantList;
};

export { mapMdAst };
