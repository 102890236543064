import { downloadFile } from 'utils';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

export const downloadTags = async (): Promise<void> => {
  const data = (await ai.get(`${ENDPOINTS.TAGS}/download`, { responseType: 'blob' })) as Blob;
  const fileName = `tags-dict_${Date.now()}`;
  downloadFile(data, fileName);
};
