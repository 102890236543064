import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface AssignTagToContactPayload {
  tagId: string;
  contactId: string;
}

export const assignTagToContactPayload = (payload: AssignTagToContactPayload): Promise<void> =>
  ai.post(`${ENDPOINTS.TAGS}/contacts`, payload);
