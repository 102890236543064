import { Tag } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FindTagsPayload {
  filters?: {
    name?: string;
    categories?: string[];
    groups?: string[];
    isDeleted?: boolean;
  };
  searches?: {
    name?: string;
    category?: string;
    group?: string;
  };
  pagination?: {
    page: number;
    perPage: number;
  };
  sorting?: Record<string, 'ASC' | 'DESC'>;
}

interface FindTagsResponse {
  tags: Array<Tag>;
  total: number;
}

export const findTags = (payload: FindTagsPayload): Promise<FindTagsResponse> =>
  ai.post(`${ENDPOINTS.TAGS}/find`, payload);
