import ai from './../axios';
import {
  Candidate,
  GridSettings,
  Log,
  CandidateScreening,
  CandidateProject,
  OperatorNote,
  Education,
  EditableEducation,
  LogType,
  DynamicCandidateStatistics,
  DuplicatesResponse,
  UnmarkDuplicatesResponse,
  UnmarkAllDuplicatesResponse,
  CandidateFeedback,
  FeedbackSource,
  FeedbackMood,
  CandidateLanguage,
  CandidateLanguageToUpsert,
} from 'types';

import ENDPOINTS from './../endpoints';
import buildFindCandidatePayload from './find-payload-builder';

/* Fetch candidates */

export const fetchCandidates = async (settings: GridSettings, visibleColumns: string[]): Promise<any> =>
  ai.post(ENDPOINTS.MANY_CANDIDATES, buildFindCandidatePayload(settings, visibleColumns));

export const getCandidate = async (candidateId: string | null): Promise<Candidate | null> =>
  (candidateId ? await ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}`) : null) as unknown as Candidate;

interface UpdateCandidatePayload {
  candidateId: string;
  fieldsToUpdate: Partial<Candidate>;
}

export const updateCandidate = (payload: UpdateCandidatePayload): Promise<Candidate> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${payload.candidateId}`, payload.fieldsToUpdate);

/* Create candidate */

export interface CreateCandidatePayload {
  role: string;
  team?: string;
  firstName: string;
  lastName: string;
  country: string;
  availability_type: string;
  available_hours_per_week: string;
}

export const createCandidate = (payload: CreateCandidatePayload): Promise<Candidate> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}`, payload);

/* Fetch candidate screenings */

export interface FetchCandidateScreeningsPayload {
  candidateId: string;
}

export interface FetchCandidateFeedbacksPayload {
  candidateId: string;
}

export const fetchCandidateScreenings = ({
  candidateId,
}: FetchCandidateScreeningsPayload): Promise<Array<CandidateScreening>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/screening`);

export const fetchCandidateFeedbacks = ({
  candidateId,
}: FetchCandidateFeedbacksPayload): Promise<Array<CandidateFeedback>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/feedbacks`);

/* Update candidate screening */

export interface UpdateCandidateScreeningPayload {
  candidateId: string;
  screeningId: string;
  screening: {
    stage: string;
    screening_conductor: string;
    screening_date: string;
    comment: string;
    link: string;
  };
}

export const updateCandidateScreening = ({
  candidateId,
  screeningId,
  screening,
}: UpdateCandidateScreeningPayload): Promise<CandidateScreening> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/screening/${screeningId}`, screening);

/* Create candidate screening */

export interface CreateCandidateScreeningPayload {
  candidateId: string;
  screening: {
    stage: string;
    screening_conductor: string;
    screening_date: string;
    comment: string;
    link: string;
  };
}

export const createCandidateScreening = ({
  candidateId,
  screening,
}: CreateCandidateScreeningPayload): Promise<CandidateScreening> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/screening`, screening);

/* Update candidate feedback */

export interface UpdateCandidateFeedbackPayload {
  candidateId: string;
  feedbackId: string;
  feedback: {
    feedbackText?: string;
    source?: FeedbackSource;
    hubspotProjectId?: string | null;
    feedbackDate?: string;
    displayForClients?: boolean;
    feedbackMood?: FeedbackMood;
    link?: string;
    isProcessed?: boolean;
  };
}

export const updateCandidateFeedback = ({
  candidateId,
  feedbackId,
  feedback,
}: UpdateCandidateFeedbackPayload): Promise<CandidateFeedback> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/feedbacks/${feedbackId}`, feedback);

/* Create candidate feedback */

export interface CreateCandidateFeedbackPayload {
  candidateId: string;
  feedback: {
    feedbackText: string;
    source: FeedbackSource;
    hubspotProjectId?: string | null;
    feedbackDate: string;
    displayForClients: boolean;
    feedbackMood: FeedbackMood;
    link?: string;
    author: string;
    authorId: string;
    isProcessed: boolean;
  };
}

export const createCandidateFeedback = ({
  candidateId,
  feedback,
}: CreateCandidateFeedbackPayload): Promise<CandidateFeedback> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/feedbacks`, feedback);

/* Create candidate log */

export interface CreateCandidateLogPayload {
  candidateId: string;
  log: {
    comment: string;
    itemType: LogType.STATUS | LogType.NOTE;
    newValue?: string;
    disqualificationReasons?: Array<string>;
    otherReasons?: string;
  };
}

export const createCandidateLog = async ({ candidateId, log }: CreateCandidateLogPayload): Promise<Log> => {
  const disqualificationReasons = [
    ...(log.disqualificationReasons ? log.disqualificationReasons : []),
    ...(log.otherReasons ? [log.otherReasons] : []),
  ];

  const createdLog = (await ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/logs`, {
    comment: log.disqualificationReasons ? `${log.comment} * ${disqualificationReasons.join(', ')}` : log.comment,
    itemType: log.itemType,
    newValue: log.newValue,
  })) as Log;

  if (log.newValue === 'For future') {
    await ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}`, {
      status_in_system: 'For future',
      disqualification_reasons: disqualificationReasons,
    });
  }

  return createdLog;
};

/* Fetch candidate projects */

export interface FetchCandidateProjectsPayload {
  candidateId: string;
}

export const fetchCandidateProjects = ({
  candidateId,
}: FetchCandidateProjectsPayload): Promise<Array<CandidateProject>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/projects`);

/* Create candidate project */

export interface CreateCandidateProjectPayload {
  candidateId: string;
  project: {
    name: string;
    link: string;
    role: string;
    startDate: string;
    endDate: string;
    description: string;
    responsibilities: string;
    weight: number;
    tech_stack: string;
  };
}

export const createCandidateProject = ({
  candidateId,
  project,
}: CreateCandidateProjectPayload): Promise<CandidateProject> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/projects`, project);

/* Update candidate project */

export interface UpdateCandidateProjectPayload {
  candidateId: string;
  projectId: string;
  project: Partial<CandidateProject>;
}

const updateCandidateProjectPatchedFields: string[] = ['show_in_cv', 'approved'];
const updateCandidateProjectExcludedFields: string[] = ['id', 'created_date', 'candidateID', '_id', 'authorId'];

type CookedProject = Omit<Partial<CandidateProject>, 'show_in_cv' | 'approved' | 'id'> & {
  show_in_cv?: string;
  approved?: string;
};

const cookProjectForUpdatePayload = (project: Partial<CandidateProject>): CookedProject =>
  Object.entries(project)
    .filter(([key]) => !updateCandidateProjectExcludedFields.includes(key))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: (() => {
          if (updateCandidateProjectPatchedFields.includes(key)) {
            return `${value}`;
          }
          return value;
        })(),
      }),
      {} as CookedProject
    );

export const updateCandidateProject = ({
  candidateId,
  projectId,
  project,
}: UpdateCandidateProjectPayload): Promise<CandidateProject> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/projects/${projectId}`, cookProjectForUpdatePayload(project));

/* Delete candidate project */

export interface DeleteCandidateProjectPayload {
  candidateId: string;
  projectId: string;
}

export const deleteCandidateProject = ({
  candidateId,
  projectId,
}: DeleteCandidateProjectPayload): Promise<CandidateProject> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/projects/${projectId}`);

/* Generate Lemon ID */

export interface GenerateLemonIdPayload {
  candidate: Candidate;
}

export const generateLemonId = async ({
  candidate,
}: GenerateLemonIdPayload): Promise<{ token: string; userId: string; candidate: Candidate }> => {
  const generateResult: {
    token: string;
    userId: string;
  } = await ai.post('create-developer-in-lemon', candidate);

  const updatedCandidate = await updateCandidate({
    candidateId: candidate.id,
    fieldsToUpdate: { lemonId: generateResult.userId },
  });

  return {
    ...generateResult,
    candidate: updatedCandidate,
  };
};

/* Grant Me.Lemon access */

export interface GrantMeLemonAccessPayload {
  candidateId: string;
}

export const grantMeLemonAccess = async ({ candidateId }: GrantMeLemonAccessPayload): Promise<void> =>
  ai.post(`grant-me-lemon-access/${candidateId}`);

/* Fetch App Lemon User Account */

export interface FetchAppLemonUserAccountPayload {
  candidateId: string;
}

export const fetchAppLemonUserAccount = async ({ candidateId }: FetchAppLemonUserAccountPayload): Promise<{} | null> =>
  ai.get(`find-app-lemon-account-for-contact/${candidateId}`);

/* Fetch duplicates */

export interface FetchDuplicatesPayload {
  candidateId: string;
}

export const fetchDuplicates = ({ candidateId }: FetchDuplicatesPayload): Promise<DuplicatesResponse> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/duplicates`);

/* Unmark duplicates */

export interface UnmarkDuplicatesPayload {
  candidate1Id: string;
  candidate2Id: string;
}

export const unmarkDuplicates = ({
  candidate1Id,
  candidate2Id,
}: UnmarkDuplicatesPayload): Promise<UnmarkDuplicatesResponse> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/unmark-duplicates/${candidate1Id}/${candidate2Id}`);

/* Unmark all duplicates */

export interface UnmarkAllDuplicatesPayload {
  candidate1Id: string;
  otherContactIds: string[];
}

export const unmarkAllDuplicates = ({
  candidate1Id,
  otherContactIds,
}: UnmarkAllDuplicatesPayload): Promise<UnmarkAllDuplicatesResponse> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/unmark-all-duplicates/${candidate1Id}`, { otherContactIds });

/* Send email */

export interface SendEmailPayload {
  candidateId: string;
  emailType: string;
}

export const sendEmail = ({ candidateId, emailType }: SendEmailPayload): Promise<void> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/emails`, { emailType });

/* Fetch a private operator note on the candidate */

export interface FetchOperatorNotePayload {
  candidateId: string;
}

export const fetchOperatorNote = ({ candidateId }: FetchOperatorNotePayload): Promise<OperatorNote> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators/notes`);

/* Update a private operator note for the candidate */

export interface UpdateOperatorNotePayload {
  candidateId: string;
  note: string;
}

export const updateOperatorNote = ({ candidateId, note }: UpdateOperatorNotePayload): Promise<OperatorNote> =>
  ai.put(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators/notes`, { note });

/* Fetch educations */

interface FetchEducationsPayload {
  candidateId: string;
}

export const fetchEducations = ({ candidateId }: FetchEducationsPayload): Promise<Array<Education>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/educations`);

const mapEditableEducation = (education: Partial<EditableEducation>) =>
  Object.keys(education).reduce(
    (dto, key) => ({ ...dto, [key]: String(education[key as keyof EditableEducation]) }),
    {} as { [Property in keyof EditableEducation]: string }
  );

/* Create education */

interface CreateEducationPayload {
  candidateId: string;
  education: EditableEducation;
}

export const createEducation = ({ candidateId, education }: CreateEducationPayload): Promise<Education> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/educations`, mapEditableEducation(education));

/* Update education */

interface UpdateEducationPayload {
  candidateId: string;
  educationId: string;
  education: Partial<EditableEducation>;
}

export const updateEducation = ({ candidateId, educationId, education }: UpdateEducationPayload): Promise<Education> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/educations/${educationId}`, mapEditableEducation(education));

/* Delete education */

interface DeleteEducationPayload {
  candidateId: string;
  educationId: string;
}

export const deleteEducation = ({ candidateId, educationId }: DeleteEducationPayload): Promise<void> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/educations/${educationId}`);

/* Invite to a call */

export interface InviteCandidateToACallPayload {
  candidateId: string;
  isHardSkillsInvitation: boolean;
  invitationLink: string;
}

export const inviteCandidateToACall = ({
  candidateId,
  isHardSkillsInvitation,
  invitationLink,
}: InviteCandidateToACallPayload): Promise<boolean> => {
  return ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/call-invitation`, {
    isHardSkillsInvitation,
    invitationLink,
  }) as Promise<boolean>;
};

/* Create Slack Channel */

export interface CreateSlackChannelPayload {
  candidateId: string;
}

export const createSlackChannel = async ({ candidateId }: CreateSlackChannelPayload): Promise<void> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/slack/onboard`);

export interface FetchStatisticsPayload {
  candidateId: string;
  startDate: Date;
}

export const fetchStatistics = ({
  candidateId,
  startDate,
}: FetchStatisticsPayload): Promise<DynamicCandidateStatistics> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/dynamic-dev-stats/${startDate}`);

/* Soft delete candidate */

export interface SoftDeleteCandidatePayload {
  candidateId: string;
}

export const softDeleteCandidate = ({ candidateId }: SoftDeleteCandidatePayload): Promise<Candidate> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/anonymize/${candidateId}`);

/* Fetch candidate languages */

export interface FetchCandidateLanguagesPayload {
  candidateId: string;
}

export const fetchCandidateLanguages = ({
  candidateId,
}: FetchCandidateLanguagesPayload): Promise<Array<CandidateLanguage>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/languages`);

/* Add candidate language */

export interface AddCandidateLanguagePayload {
  candidateId: string;
  data: CandidateLanguageToUpsert;
}

export const addCandidateLanguage = ({ candidateId, data }: AddCandidateLanguagePayload): Promise<CandidateLanguage> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/languages`, data);

/* Update candidate language */

export interface UpdateCandidateLanguagePayload {
  candidateId: string;
  languageId: string;
  data: CandidateLanguageToUpsert;
}

export const updateCandidateLanguage = ({
  candidateId,
  languageId,
  data,
}: UpdateCandidateLanguagePayload): Promise<CandidateLanguage> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/languages/${languageId}`, data);

/* Delete candidate language */

export interface DeleteCandidateLanguagePayload {
  candidateId: string;
  languageId: string;
}

export const deleteCandidateLanguage = ({
  candidateId,
  languageId,
}: DeleteCandidateLanguagePayload): Promise<CandidateLanguage> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/languages/${languageId}`);
