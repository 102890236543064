import ai from './../axios';
import { CandidateSpecialization, SpecializationBase, SpecializationUpdateBase } from 'types';
import ENDPOINTS from './../endpoints';

export const getCandidateSpecializations = async (candidateId: string): Promise<CandidateSpecialization[]> =>
  (await ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/specializations`)) as unknown as CandidateSpecialization[];

export const addSpecialization = async ({
  candidateID,
  seniority,
  title,
  show_in_cv,
  has_custom_title,
}: SpecializationBase): Promise<CandidateSpecialization> =>
  (await ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateID}/specializations`, {
    title,
    seniority,
    show_in_cv,
    has_custom_title,
  })) as unknown as CandidateSpecialization;

type DeleteSpecializationParams = {
  candidateId: string;
  specializationId: string;
};

export const deleteSpecialization = async ({
  candidateId,
  specializationId,
}: DeleteSpecializationParams): Promise<void> =>
  await ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/specializations/${specializationId}`);

export const updateSpecialization = async ({
  candidateID,
  title,
  has_custom_title,
  show_in_cv,
  seniority,
  id,
}: SpecializationUpdateBase): Promise<CandidateSpecialization> =>
  (await ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateID}/specializations/${id}`, {
    title,
    seniority,
    has_custom_title,
    show_in_cv,
  })) as unknown as CandidateSpecialization;
