import { type Descendant } from 'slate';
import { fromMarkdown } from 'mdast-util-from-markdown';

import { mapMdAst } from '../mdast-utils';

const parse = (md: string): Array<Descendant> => {
  const trimmedMd = md.trim();
  const root = fromMarkdown(trimmedMd, 'utf8', {});
  return mapMdAst(root, { root: true });
};

export default parse;
