import React, { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { Box, Tooltip } from '@mui/material';
import { CandidateSkill, HubspotProjectSkill } from 'types';
import { includesCaseInsensitive } from 'utils';

type Skill = CandidateSkill | HubspotProjectSkill;
interface Props {
  skills: Skill[];
  match: string;
}

const SkillsCell: React.FC<Props> = ({ skills, match }) => {
  const formattedSkills = (skills || [])
    .map((skill: Skill) => (skill.name ? skill.name.trim() : skill.name))
    .filter((skill: string) => skill !== '');

  const skillsAsStrings = formattedSkills.join(', ');

  const matchingSkills: string[] = useMemo(() => {
    const substrMatches = match
      ? match
          .replace(/@\d+/g, '')
          .split(' ')
          .filter((match) => match !== ' ')
          .map((match) => (match ? match.trim() : match))
          .filter((match) => match !== '')
      : [];

    return formattedSkills.filter((skill) => substrMatches.some((match) => includesCaseInsensitive([skill], match)));
  }, [formattedSkills, match]);

  return (
    <Tooltip
      title={
        <Highlighter
          searchWords={matchingSkills}
          textToHighlight={skillsAsStrings}
          highlightStyle={{
            backgroundColor: 'rgb(220, 255, 2, 0.5)',
            borderRadius: '3px',
          }}
          autoEscape
        />
      }
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Highlighter
          searchWords={matchingSkills}
          textToHighlight={skillsAsStrings}
          highlightStyle={{
            backgroundColor: 'rgb(131, 44, 224, 0.3)',
            borderRadius: '3px',
            fontWeight: 700,
          }}
          autoEscape
        />
      </Box>
    </Tooltip>
  );
};

export default SkillsCell;
