import React from 'react';
import { SxProps, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlexRow, FlexYCenteredRow, FlexYCenteredCell } from '../flex';
import { Title, Reset } from './resettable-section.styled';

export enum CheckboxState {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
  INDETERMINATE = 'indeterminate',
}

interface Props {
  sx?: SxProps;
  title: string;
  onReset?: () => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  checkBoxState?: CheckboxState;
}

const ResettableSection: React.FC<Props> = ({
  sx = {},
  title,
  onReset,
  onSelectAll = () => {},
  onDeselectAll = () => {},
  checkBoxState,
}) => {
  const handleCheckboxStateChange = () => {
    if (checkBoxState === CheckboxState.UNCHECKED || checkBoxState === CheckboxState.INDETERMINATE) {
      onSelectAll();
    } else {
      onDeselectAll();
    }
  };

  return (
    <FlexRow sx={sx}>
      <FlexYCenteredRow sx={{ flexGrow: 1 }}>
        {checkBoxState && (
          <Checkbox
            size="small"
            color="primary"
            onChange={handleCheckboxStateChange}
            checked={checkBoxState === CheckboxState.CHECKED}
            indeterminate={checkBoxState === CheckboxState.INDETERMINATE}
          />
        )}
        <Title>{title}</Title>
      </FlexYCenteredRow>
      {onReset && (
        <FlexYCenteredCell sx={{ cursor: 'pointer' }} onClick={onReset}>
          <CloseIcon sx={{ fontSize: '16px', mr: '4px' }} color="primary" />
          <Reset color="primary">Reset</Reset>
        </FlexYCenteredCell>
      )}
    </FlexRow>
  );
};

export default ResettableSection;
