import React from 'react';
import 'simplebar/src/simplebar.css';

import { StyledSimpleBar } from './scroll-box.styled';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  height: string | number | undefined;
  disabledScroll?: boolean;
  style?: React.CSSProperties;
  fitContent?: boolean;
}

const ScrollBox: React.FC<Props> = ({ children, height, disabledScroll = false, style = {}, fitContent = false }) => {
  return (
    <StyledSimpleBar
      fitContent={fitContent}
      forceVisible="y"
      autoHide={false}
      style={{
        height: typeof height === 'string' || height === undefined ? height : `${height}px`,
        overflowY: !disabledScroll ? 'auto' : 'hidden',
        overflowX: 'hidden',
        ...style,
      }}
    >
      {children}
    </StyledSimpleBar>
  );
};

export default ScrollBox;
