import { HubspotProjectSpecialization } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateHubspotProjectSpecializationPayload {
  hubspotProjectId: string;
  specializationId: string;
  specialization: Partial<Pick<HubspotProjectSpecialization, 'title' | 'seniority' | 'has_custom_title'>>;
}

export const updateHubspotProjectSpecialization = ({
  hubspotProjectId,
  specializationId,
  specialization,
}: UpdateHubspotProjectSpecializationPayload): Promise<HubspotProjectSpecialization> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/specializations/${specializationId}`, specialization);
