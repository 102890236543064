import { memo, useCallback, useMemo } from 'react';

import { SelectItem } from 'types';

import CheckboxItem from './checkbox-item';
import { Root, CheckboxItemsContainer } from './checkbox-list.styled';

interface CheckboxListProps {
  items: Array<SelectItem<string>>;
  values: Array<string>;
  onChange: (values: Array<string>) => void;
  search?: string;
}

const CheckboxList = ({ items, values, onChange, search }: CheckboxListProps) => {
  const filteredItems = useMemo(
    () => items.filter((item) => (search ? item.name.toLowerCase().includes(search) : true)),
    [items, search]
  );

  const isAllSelected = !!filteredItems.length && filteredItems.every((item) => values.includes(item.value));

  const handleAllSelectClick = useCallback(() => {
    if (isAllSelected) {
      onChange([]);
      return;
    }

    onChange(filteredItems.map((item) => item.value));
  }, [isAllSelected, filteredItems, onChange]);

  const handleItemChange = useCallback(
    (item: SelectItem<string>, value: boolean) => {
      if (value) {
        onChange([...values, item.value]);
        return;
      }

      onChange(values.filter((value) => value !== item.value));
    },
    [onChange, values]
  );

  return (
    <Root>
      <CheckboxItem
        item={{ name: 'Select all', value: '' }}
        value={isAllSelected}
        onChange={handleAllSelectClick}
        isSelectAll
      />
      <CheckboxItemsContainer>
        {filteredItems.map((item) => (
          <CheckboxItem
            key={item.value}
            item={item}
            value={values.includes(item.value)}
            onChange={handleItemChange}
            search={search}
          />
        ))}
      </CheckboxItemsContainer>
    </Root>
  );
};

export default memo(CheckboxList);
