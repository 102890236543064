import React, { memo } from 'react';
import { Descendant } from 'slate';
import { detect } from 'detect-browser';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { FlexYCenteredRow } from 'components/flex';
import { EditorTemplate } from 'types';

import MarkButton from './mark-button';
import BlockButton from './block-button';
import TemplateButton from './template-button';
import { TemplateButtons } from './toolbar.styled';

interface Props {
  templates?: Array<EditorTemplate>;
  rerenderEditor: () => void;
  onChange: (value: Array<Descendant>) => void;
  onSubmit: (value: Array<Descendant>) => void;
}

const Toolbar = ({ templates, rerenderEditor, onChange, onSubmit }: Props) => {
  const browser = detect();
  const cmd = browser?.os === 'Mac OS' ? 'Cmd' : 'Ctrl';

  return (
    <FlexYCenteredRow>
      <MarkButton format="bold" tooltip={`Bold (${cmd} + B)`} icon={<FormatBoldIcon />} />
      <MarkButton format="italic" tooltip={`Italic (${cmd} + I)`} icon={<FormatItalicIcon />} />
      <BlockButton format="bulleted-list" tooltip="Bulleted list" icon={<FormatListBulletedIcon />} />
      <BlockButton format="numbered-list" tooltip="Numbered list" icon={<FormatListNumberedIcon />} />
      {templates?.length && (
        <TemplateButtons>
          {templates.map((template, index) => (
            <TemplateButton
              key={index}
              template={template}
              rerenderEditor={rerenderEditor}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          ))}
        </TemplateButtons>
      )}
    </FlexYCenteredRow>
  );
};

export default memo(Toolbar);
