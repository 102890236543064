import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { FlexCenteredCell, FlexColumn, FlexCenteredRow, ValueChip } from '..';
import { formatToTwoDecimals } from 'utils';
import { RateLabel, DollarSign, RateContent } from './rate-cell.styled';
import { Candidate } from 'types';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const NOT_AVAILABLE = 'n/a';

interface Props {
  candidate: Candidate;
}

const CellContent: React.FC<Props> = ({ candidate }) => {
  const { min_rate_per_hour, max_rate_per_hour, rateInfo } = candidate;
  const minRatePerHour = min_rate_per_hour ? formatToTwoDecimals(min_rate_per_hour) : NOT_AVAILABLE;
  const maxRatePerHour = max_rate_per_hour ? formatToTwoDecimals(max_rate_per_hour) : NOT_AVAILABLE;
  return (
    <FlexCenteredRow>
      {!min_rate_per_hour && !max_rate_per_hour && <RateContent>{NOT_AVAILABLE}</RateContent>}
      {min_rate_per_hour && max_rate_per_hour && <RateContent>{`${minRatePerHour} - ${maxRatePerHour}`}</RateContent>}
      {!min_rate_per_hour && max_rate_per_hour && <RateContent>{maxRatePerHour}</RateContent>}
      {min_rate_per_hour && !max_rate_per_hour && <RateContent>{minRatePerHour}</RateContent>}
      {rateInfo && (
        <FlexCenteredCell>
          <InfoIcon fontSize="small" sx={{ ml: '4px' }} />
        </FlexCenteredCell>
      )}
    </FlexCenteredRow>
  );
};

export const RateCell: React.FC<Props> = ({ candidate }) => {
  const { rateInfo } = candidate;
  if (rateInfo) {
    return (
      <Tooltip
        title={
          <FlexColumn>
            <RateLabel>Rate info: </RateLabel>
            <Typography>{rateInfo}</Typography>
          </FlexColumn>
        }
      >
        <Box>
          <ValueChip>
            <DollarSign />
            <CellContent candidate={candidate} />
          </ValueChip>
        </Box>
      </Tooltip>
    );
  }
  return (
    <ValueChip>
      <DollarSign />
      <CellContent candidate={candidate} />
    </ValueChip>
  );
};

interface ClientRateCellProps {
  clientRate: number | string | undefined;
}

export const ClientRateCell: React.FC<ClientRateCellProps> = ({ clientRate }) => {
  if (!clientRate) {
    return null;
  }
  return (
    <ValueChip>
      <DollarSign />
      <RateContent>{formatToTwoDecimals(clientRate)}</RateContent>
    </ValueChip>
  );
};
