import { Log } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectLogsPayload {
  dealId: string;
}

export const fetchHubspotProjectLogs = ({ dealId }: FetchHubspotProjectLogsPayload): Promise<Array<Log>> =>
  ai.get(`${ENDPOINTS.HUBSPOT_PROJECTS}/${dealId}/logs`);
