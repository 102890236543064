import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  findHubspotProjectCandidates,
  fetchHubspotProjectCandidates,
  addHubspotProjectCandidate,
  updateHubspotProjectCandidate,
  declineHubspotProjectCandidate,
  UpdateHubspotProjectCandidatePayload,
  approveHubspotProjectCandidate,
  reviewHubspotProjectCandidate,
  restoreHubspotProjectCandidate,
} from './hubspot-project-candidates-service';

export { type UpdateHubspotProjectCandidatePayload };

/* TODO: Move to somewhere so next time it can be reused. */
const generateFoundHubspotProjectCandidateKey = ({
  filters,
  pagination,
}: GetPayload<typeof findHubspotProjectCandidates>) => {
  const objectToSerialize: Record<string, string | number | boolean> = { ...filters, ...pagination };
  return Object.keys(objectToSerialize)
    .reduce<Array<string>>((acc, key) => [...acc, `${key}=${objectToSerialize[key]}`], [])
    .join(';');
};

export const useFindHubspotProjectCandidates = (payload: GetPayload<typeof findHubspotProjectCandidates>) => {
  const result = useQuery(['found-hubspot-project-candidates', generateFoundHubspotProjectCandidateKey(payload)], () =>
    findHubspotProjectCandidates(payload)
  );
  return prepareQueryResult('foundHubspotProjectCandidates', result, null, true);
};

export const useFetchHubspotProjectCandidates = (payload: GetPayload<typeof fetchHubspotProjectCandidates>) => {
  const result = useQuery(
    ['hubspot-project-candidates', payload.hubspotProjectId],
    () => fetchHubspotProjectCandidates(payload),
    { staleTime: 0 }
  );
  return prepareQueryResult('hubspotProjectCandidates', result, []);
};

export const useAddHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('addHubspotProjectCandidate', mutation);
};

export const useUpdateHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { fieldsToUpdate: { contactId, hubspotProjectId } }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('updateHubspotProjectCandidate', mutation);
};

export const useDeclineHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(declineHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('declineHubspotProjectCandidate', mutation);
};

export const useRestoreHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(restoreHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('restoreHubspotProjectCandidate', mutation);
};

export const useApproveHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(approveHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('approveHubspotProjectCandidate', mutation);
};

export const useReviewHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(reviewHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('reviewHubspotProjectCandidate', mutation);
};
