import React, { useState, useCallback, useEffect } from 'react';
import { Subtract } from 'utility-types';

type ExcludedProps<T> = {
  value?: T;
  onChange?: (value: T) => void;
};

type UncontrolledAdditionalProps<T> = {
  initialValue?: T;
  onSubmit?: (value: T) => void;
};

interface ConvertToUncontrolledOptions {
  submitOnBlur?: boolean;
}

/**
 * HOC for simple convertation a controlled component
 * to an uncontrolled component with submit on change event
 */
export function convertToUncontrolled<P extends ExcludedProps<T>, T>(
  Component: React.FC<P>,
  defaultValue: T,
  options?: ConvertToUncontrolledOptions
) {
  type Props = UncontrolledAdditionalProps<T> & Subtract<P, ExcludedProps<T>>;

  const WrappedComponent = ({ initialValue = defaultValue, onSubmit = () => null, ...baseProps }: Props) => {
    const [localValue, setLocalValue] = useState<T>(initialValue);

    const handleChange = useCallback(
      (value: T) => {
        setLocalValue(value);

        if (!options?.submitOnBlur) {
          onSubmit(value);
        }
      },
      [onSubmit]
    );

    const handleBlur = useCallback(() => {
      onSubmit(localValue);
    }, [localValue, onSubmit]);

    useEffect(() => {
      setLocalValue(initialValue);
    }, [initialValue]);

    return (
      <Component
        {...(baseProps as P)}
        value={localValue}
        onChange={handleChange}
        {...(options?.submitOnBlur && { onBlur: handleBlur })}
      />
    );
  };

  return WrappedComponent;
}
