import ai from './../axios';
import { CandidateSkill, SkillBase } from 'types';
import ENDPOINTS from './../endpoints';

export const getCandidateSkills = async (candidateId: string): Promise<CandidateSkill[]> =>
  (await ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/skills`)) as unknown as CandidateSkill[];

export const addSkill = async ({
  candidateId,
  years,
  name,
  show_in_cv,
  main,
}: SkillBase & { candidateId: string }): Promise<CandidateSkill> =>
  (await ai.put(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/skills`, {
    name,
    years,
    show_in_cv,
    main,
  })) as unknown as CandidateSkill;

type DeleteSkillParams = {
  candidateId: string;
  skillId: string;
};

export const deleteSkill = async ({ candidateId, skillId }: DeleteSkillParams): Promise<void> =>
  await ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/skills/${skillId}`);

export const updateSkill = async ({
  years,
  name,
  show_in_cv,
  main,
  id,
  candidateID,
}: CandidateSkill): Promise<CandidateSkill> =>
  (await ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateID}/skills/${id}`, {
    name,
    years,
    show_in_cv,
    main,
  })) as unknown as CandidateSkill;
