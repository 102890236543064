import { SxProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { FlexCenteredCell } from '..';

interface Props {
  children: ReactNode;
  sx?: SxProps;
}

const ValueChip: React.FC<Props> = ({ children, sx = {} }) => (
  <FlexCenteredCell
    sx={{ ...sx, backgroundColor: '#F5F5F5', borderRadius: '16px', py: '4px', px: '8px', fontSize: '12px' }}
  >
    {' '}
    {children}
  </FlexCenteredCell>
);

export default ValueChip;
