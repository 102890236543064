import { useQuery } from 'react-query';

import { Log, GetPayload } from 'types';

import { prepareQueryResult } from '../utils';
import { fetchCandidatesByFullName, getCandidateLogs, fetchCandidateDiffs } from './candidate-service';

export const useFetchCandidatesByFullName = ({
  fullName,
  onlyActiveStatus,
}: GetPayload<typeof fetchCandidatesByFullName>) => {
  return useQuery(
    ['candidates', `fullname: ${fullName}`],
    () => fetchCandidatesByFullName({ fullName, onlyActiveStatus }),
    {
      keepPreviousData: true,
    }
  );
};

export const useCandidateLogsFetch = ({
  candidateId,
  projectRelated,
  applicationRelated,
}: GetPayload<typeof getCandidateLogs>) => {
  const { data, isLoading, isError } = useQuery<Log[], Error>(
    ['candidate-logs', candidateId, `projectRelated=${projectRelated}`, `applicationRelated=${applicationRelated}`],
    () => getCandidateLogs({ candidateId, projectRelated, applicationRelated })
  );
  return {
    candidateLogs: data || [],
    candidateLogsAreLoading: isLoading,
    candidateLogsFetchError: isError,
  };
};

export const useFetchCandidateDiffs = (payload: GetPayload<typeof fetchCandidateDiffs>) => {
  const result = useQuery(['candidate-diffs', payload.candidateId], () => fetchCandidateDiffs(payload));
  return prepareQueryResult('candidateDiffs', result, []);
};
