import React from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open: boolean;
}

const Loader: React.FC<Props> = ({ open }) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'transparent' }} open={open}>
    <CircularProgress color="primary" size={75} thickness={3} variant="indeterminate" />
  </Backdrop>
);

export default Loader;
