import ai from './../axios';
import ENDPOINTS from './../endpoints';

import { CommunitySlackUser, LemonSlackUser } from 'types';

export const fetchSlackUsers = <SlackUserType extends CommunitySlackUser | LemonSlackUser>(
  workspace: string
): Promise<SlackUserType[]> => {
  return ai
    .get<{ members: SlackUserType[] } | SlackUserType[], { members: SlackUserType[] } | SlackUserType[]>(
      `${ENDPOINTS.SLACK_USERS}?workspace=${workspace}`
    )
    .then((response) => {
      return (
        workspace === 'community' ? (response as { members: SlackUserType[] }).members : response
      ) as SlackUserType[];
    });
};
