import { Tag } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateTagPayload {
  tagId: string;
  updates: {
    name?: string;
  };
}

export const updateTag = (payload: UpdateTagPayload): Promise<Tag> =>
  ai.patch(`${ENDPOINTS.TAGS}/${payload.tagId}`, payload.updates);
