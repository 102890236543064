import { HubspotProjectSkill } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateHubspotProjectSkillPayload {
  hubspotProjectId: string;
  skillId: string;
  skill: Partial<Pick<HubspotProjectSkill, 'name' | 'years' | 'main'>>;
}

export const updateHubspotProjectSkill = ({
  hubspotProjectId,
  skillId,
  skill,
}: UpdateHubspotProjectSkillPayload): Promise<HubspotProjectSkill> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/skills/${skillId}`, skill);
