import React from 'react';
import { Tooltip, useMediaQuery, useTheme, SxProps } from '@mui/material';

import { FlexColumn, FlexRow } from 'components';

import { SectionTitle, StyledAddButton, backgrounds } from './section.styled';

export type SectionButtonDefinition = {
  title: string;
  icon?: React.ReactNode;
  btnVariant?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  onClick: () => void;
  disabled?: boolean;
  dataTestId?: string;
};

interface Props {
  className?: string;
  title?: string;
  children?: React.ReactNode;
  buttons?: SectionButtonDefinition[];
  headerOtherContent?: React.ReactNode;
  icons?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  sx?: SxProps;
  headerSx?: SxProps;
  /**
   * Boolean value that determines whether the buttons
   * will shrink when the window is smaller than md
   * or ```mobile``` equals true.
   */
  buttonsShrink?: boolean;
  /**
   * Boolean value that determines when to show
   * mobile view of the component. By default, it renders
   * mobile view when screen is smaller than md.
   */
  mobile?: boolean;
  dataTestId?: string;
}

const Section: React.FC<Props> = ({
  className,
  title,
  children,
  buttons,
  headerOtherContent,
  variant = 'primary',
  sx,
  headerSx,
  buttonsShrink,
  mobile,
  dataTestId,
}) => {
  const theme = useTheme();

  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = mobile || !upMd;

  return (
    <FlexColumn
      className={className}
      sx={{
        backgroundColor: backgrounds[variant],
        marginBottom: '24px',
        padding: '16px',
        borderRadius: '4px',

        '&:last-child': {
          marginBottom: '0',
        },

        ...sx,
      }}
    >
      <FlexRow
        sx={{
          ...((title || buttons?.length) && { marginBottom: '16px' }),
          ...headerSx,
        }}
      >
        {title && <SectionTitle data-testid={dataTestId}>{title}</SectionTitle>}
        <FlexRow sx={{ justifyContent: 'flex-end', alignItems: 'center', width: 'auto' }}>
          {buttons &&
            buttons.map(({ title: btnTitle, onClick, icon, btnVariant, dataTestId, disabled }) => (
              <Tooltip key={`section-${title}-button-${btnTitle}`} title={buttonsShrink && !upMd ? btnTitle : ''}>
                <StyledAddButton
                  sx={{
                    ...(buttonsShrink &&
                      isMobile && {
                        padding: '0',
                        minWidth: '36px',
                        width: '36px',

                        '& .MuiButton-startIcon': {
                          margin: '0',
                        },
                      }),
                  }}
                  onClick={onClick}
                  color={btnVariant || variant}
                  variant="outlined"
                  size="small"
                  startIcon={icon}
                  data-testid={dataTestId}
                  disabled={disabled}
                >
                  {buttonsShrink && isMobile ? '' : btnTitle}
                </StyledAddButton>
              </Tooltip>
            ))}
          {headerOtherContent}
        </FlexRow>
      </FlexRow>
      <FlexColumn>{children}</FlexColumn>
    </FlexColumn>
  );
};

export default Section;
