import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { PageTab } from './pages-tabs.types';

interface UsePageTabsParams {
  tabs: Array<PageTab>;
}

export const usePageTabs = ({ tabs }: UsePageTabsParams) => {
  const router = useRouter();

  const idFromUrl = (router.query.tab ?? '') as string;

  const [selectedPageId, setSelectedPageId] = useState<string>(() => {
    if (tabs.some((tab) => tab.pageId === idFromUrl)) {
      return idFromUrl;
    }

    return tabs[0].pageId;
  });

  const handleSelectPageId = useCallback(
    (pageId: string) => {
      setSelectedPageId((prev) => (prev !== idFromUrl ? prev : pageId));
    },
    [idFromUrl]
  );

  const pathWithoutQuery = router.asPath.split('?')[0];

  /*
    Local state synchronization with ID in URL
  */
  useEffect(() => {
    if (idFromUrl) {
      setSelectedPageId(idFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFromUrl]);

  /* 
    Synchronization of ID in URL with local state
  */
  useEffect(() => {
    if (router.isReady && idFromUrl !== selectedPageId) {
      router.push({
        pathname: pathWithoutQuery,
        query: {
          tab: selectedPageId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageId, router.isReady]);

  return { selectedPageId, handleSelectPageId };
};
