import React, { memo, useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';

export interface Props {
  className?: string;
  isActive: boolean;
  onMouseDown: () => void;
  tooltip: string;
  icon: React.ReactNode;
}

const BaseToolbarButton: React.FC<Props> = ({ className, isActive, onMouseDown, tooltip, icon }) => {
  const handleMouseDown = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      onMouseDown();
    },
    [onMouseDown]
  );

  return (
    <Tooltip title={tooltip}>
      <IconButton className={className} onMouseDown={handleMouseDown} color={isActive ? 'primary' : 'default'}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default memo(BaseToolbarButton);
