import { styled, Typography, Box } from '@mui/material';

export const TabsWrapper = styled(Box)(() => ({
  borderBottom: 1,
  borderColor: 'divider',
  backgroundColor: '#EEEEEE',
  height: '48px',
}));

export const TabContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const TabTitle = styled(Typography)<{ hideLabelsDownLg: boolean }>(({ theme, hideLabelsDownLg }) => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  ...(hideLabelsDownLg && {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
}));
