import React, { useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type SearchInputProps = TextFieldProps & {
  onQueryChange?: (query: string) => void;
  /**
   * -- workaround --
   * Controlled only when value is empty so we can clear input value
   */
  controlled?: boolean;
  dataTestId?: string;
};

type SearchInputJSXElement = (props: SearchInputProps) => JSX.Element;

/**
 * Just a wrapper around TextField MUI component that delays triggering onChange event
 */
const SearchInput: SearchInputJSXElement = ({ onQueryChange = () => {}, controlled, dataTestId, ...rest }) => {
  const [displayedMessage, setDisplayedMessage] = React.useState<string>((rest.value as string) || '');

  const handleQueryChange = (query: string) => {
    setDisplayedMessage(query);

    onQueryChange(query);
  };

  useEffect(() => {
    if (controlled) {
      setDisplayedMessage((rest.value as string) ?? '');
    }
  }, [controlled, rest.value]);

  return (
    <TextField
      {...rest}
      autoComplete="none"
      value={displayedMessage}
      onChange={(event) => handleQueryChange(event.target.value)}
      inputProps={{ ...(dataTestId && { 'data-testid': dataTestId }) }}
    />
  );
};

export default SearchInput;
