import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface BulkAssignToContactsPayload {
  userIdToAssignAsDedicatedOperator?: string;
  tagId?: string;
  contactIds: string[];
}

export const bulkAssignToContactsPayload = (payload: BulkAssignToContactsPayload): Promise<void> =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/bulk-assign`, payload);
