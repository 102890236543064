import { useQueryClient, useQuery, useMutation } from 'react-query';

import { GetPayload } from 'types';

import { fetchAttachments, addAttachment, deleteAttachment, updateAttachment } from './service';

export const useFetchAttachments = (payload: GetPayload<typeof fetchAttachments>) => {
  return useQuery('attachments', () => fetchAttachments(payload));
};

export const useAddAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: GetPayload<typeof addAttachment>) => addAttachment(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('attachments');
    },
  });
};

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: GetPayload<typeof deleteAttachment>) => deleteAttachment(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('attachments');
    },
  });
};

export const useUpdateAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: GetPayload<typeof updateAttachment>) => updateAttachment(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('attachments');
    },
  });
};
