import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';

export const DEFAULT_EXPANDED_SIDEBAR_WIDTH = 352;
export const COLLAPSED_SIDEBAR_WIDTH = 56;
export const COLLAPSE_CONTROL_HEIGHT = 53;

export const MenuFooterContainer = styled(Box)<{ showCollapseOnMdOnly: boolean }>(
  ({ showCollapseOnMdOnly, theme }) => ({
    borderTop: '0.5px solid lightgray',
    height: `${COLLAPSE_CONTROL_HEIGHT}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: '#EEEEEE',
    cursor: 'pointer',
    ...(showCollapseOnMdOnly
      ? {
          [theme.breakpoints.up('lg')]: {
            display: 'none',
          },
        }
      : {}),
  })
);

export const FilterTogglerTitle = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '22px',
}));

interface CollapsedContentProps {
  collapsedContent?: ReactNode | ReactNode[];
  position: 'left' | 'right';
}

export const CollapsedContentComponent = ({ collapsedContent, position }: CollapsedContentProps) => {
  if (collapsedContent) {
    return <>{collapsedContent}</>;
  }
  return position === 'left' ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />;
};
