import { TagLog, TagLogActionType, TagLogItemType } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FindTagLogsPayload {
  filters?: {
    actionType?: TagLogActionType;
    itemType?: TagLogItemType;
    tagId?: string;
    contactId?: string;
    hubspotProjectId?: string;
  };
  pagination?: {
    page: number;
    perPage: number;
  };
  sorting?: Record<string, 'ASC' | 'DESC'>;
}

interface FindTagLogsResponse {
  tagLogs: Array<TagLog>;
  total: number;
}

export const findTagLogs = (payload: FindTagLogsPayload): Promise<FindTagLogsResponse> =>
  ai.post(`${ENDPOINTS.TAGS}/logs/find`, payload);
