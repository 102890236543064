import ai from 'api/domain/axios';
import ENDPOINTS from 'api/domain/endpoints';
import { ACTIVE_CANDIDATE_STATUSES } from 'constants/candidate';
import { Candidate } from 'types';

type MinifiedCandidate = Pick<Candidate, 'id' | 'firstName' | 'lastName'>;
type FetchCandidatesByFullNameResponse = { contacts: Array<MinifiedCandidate> };
interface FetchCandidatesByFullNamePayload {
  fullName: string;
  onlyActiveStatus?: boolean;
}

export const fetchCandidatesByFullName = async ({
  fullName,
  onlyActiveStatus = false,
}: FetchCandidatesByFullNamePayload): Promise<Array<MinifiedCandidate>> => {
  const payload = {
    fields: ['id', 'firstName', 'lastName'],
    pagination: { page: 1, perPage: 20 },
    ...(onlyActiveStatus && { filters: { statuses: ACTIVE_CANDIDATE_STATUSES } }),
    ...(fullName && { searches: { fullName } }),
  };

  const response = (await ai.post(ENDPOINTS.MANY_CANDIDATES, payload)) as FetchCandidatesByFullNameResponse;
  return response.contacts.filter((contact) => contact?.firstName?.trim() || contact?.lastName?.trim());
};
