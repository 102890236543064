import axios, { AxiosInstance } from 'axios';
import ENDPOINTS from './endpoints';
import { baseAuthUrl, AUTH_LS_KEY } from 'config';

const authInstance: AxiosInstance = axios.create({
  baseURL: baseAuthUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

authInstance.interceptors.response.use((response) => response.data);

type SuccessAuthResponse = {
  authKey: string;
};

export const getAuthKey = async (): Promise<string> => {
  const response = await authInstance.get(ENDPOINTS.GET_AUTH_KEY);
  const successResponse = response as unknown as SuccessAuthResponse;
  return successResponse.authKey;
};

export const logout = async (): Promise<void> => {
  window.localStorage.removeItem(AUTH_LS_KEY);
  await authInstance.get(ENDPOINTS.LOGOUT);
};
