import React, { memo, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';

import { useFetchConstants } from 'api';
import { Autocomplete, AutocompleteProps, Flag } from 'components';
import { SelectItem } from 'types';
import { makeSelectItems } from 'utils/app';

type Props = Omit<AutocompleteProps, 'items'>;

const CountrySelect = (props: Props) => {
  const { data: constants } = useFetchConstants();

  const countriesItems = useMemo<Array<SelectItem<string>>>(() => {
    if (!constants) {
      return [];
    }

    return makeSelectItems(Object.keys(constants.countriesData));
  }, [constants]);

  return (
    <Autocomplete
      items={countriesItems}
      {...props}
      renderOption={(optionProps, item) => (
        <Box component="li" {...optionProps}>
          {<Flag country={item.name} />}
          <Box sx={{ marginLeft: '8px' }} component="span">
            {item.name}
          </Box>
        </Box>
      )}
      modifyRenderInputParams={(params) => ({
        ...params,
        InputProps: {
          ...params.InputProps,
          ...(props.value
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Flag country={props.value} />
                  </InputAdornment>
                ),
              }
            : {}),
        },
      })}
    />
  );
};

export default memo(CountrySelect);
