import React from 'react';
import { Switch, FormControlLabel, SxProps } from '@mui/material';

export interface Props {
  value?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  sx?: SxProps;
  size?: 'medium' | 'small';
  dataTestId?: string;
}

const FormSwitch: React.FC<Props> = ({
  label,
  value = false,
  onChange = () => {},
  sx = {},
  size = 'medium',
  dataTestId,
}) => {
  return (
    <FormControlLabel
      sx={{ ml: '0', mr: '0', ...sx }}
      checked={value}
      onChange={(_, checked) => onChange(checked)}
      control={<Switch size={size} color="primary" />}
      label={label}
      componentsProps={{ typography: { sx: { ml: '2px' } } }}
      {...(dataTestId && { 'data-testid': `${dataTestId}` })}
    />
  );
};

export default FormSwitch;
