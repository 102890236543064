import ai from './../axios';
import ENDPOINTS from './../endpoints';

import { Reminder, ReminderBase } from 'types';

export const fetchRemainders = (candidateId: string): Promise<Reminder[]> =>
  ai.get(`${ENDPOINTS.REMAINDERS}/?contactId=${candidateId}`);

export const addReminder = (payload: ReminderBase): Promise<Reminder> => ai.post(`${ENDPOINTS.REMAINDERS}`, payload);

type CompleteReminderPayload = {
  reminderId: string;
  patch: Partial<Reminder>;
};

export const completeReminder = ({ reminderId, patch }: CompleteReminderPayload): Promise<Reminder> =>
  ai.patch(`${ENDPOINTS.REMAINDERS}/${reminderId}`, patch);
