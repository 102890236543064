import { styled } from '@mui/system';
import { Box, Typography, Button } from '@mui/material';

import { FlexRow } from '../flex';

export const Content = styled(Box)(() => ({
  padding: '16px 24px 8px',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '500',
  fontSize: '20px',
}));

const ActionsWrapper = styled(FlexRow)(() => ({
  padding: '8px',
  gap: '8px',
  justifyContent: 'flex-end',
}));

interface ActionsProps {
  submitLabel: string;
  cancelLabel?: string;
  submitColor?: 'primary' | 'error';
  cancelColor?: 'primary' | 'error';
  onSubmit?: () => void;
  onCancel: () => void;
  disabled?: boolean;
  dataTestId?: string;
}

export const Actions = ({
  submitLabel,
  cancelLabel = 'Cancel',
  submitColor = 'primary',
  cancelColor = 'error',
  onSubmit,
  onCancel,
  disabled = false,
  dataTestId,
}: ActionsProps) => (
  <ActionsWrapper>
    <Button
      onClick={onCancel}
      sx={{ p: '8px', textTransform: 'none' }}
      color={cancelColor}
      {...(dataTestId && { 'data-testid': `${dataTestId}__cancel` })}
    >
      {cancelLabel}
    </Button>
    <Button
      onClick={onSubmit}
      sx={{ p: '8px', textTransform: 'none' }}
      color={submitColor}
      disabled={disabled}
      {...(dataTestId && { 'data-testid': `${dataTestId}__submit` })}
    >
      {submitLabel}
    </Button>
  </ActionsWrapper>
);
