import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SkillDictionaryTerm } from 'types/domain';
import { addSkillDictionaryTerm, getSkillDictionaryTerms } from './skill-dictionary-service';
import { prepareMutation, prepareQueryResult } from 'api/domain/utils';

const SKILL_DICTIONARY_FETCH_KEY = 'skill-dictionary-fetch';

export const useSkillDictionaryTermsFetch = () => {
  const result = useQuery<SkillDictionaryTerm[], Error>(SKILL_DICTIONARY_FETCH_KEY, () => getSkillDictionaryTerms());
  return prepareQueryResult('skillDictionaryTerms', result, []);
};

export const useAddSkillDictionaryTermMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addSkillDictionaryTerm, {
    onSuccess: () => {
      queryClient.invalidateQueries(SKILL_DICTIONARY_FETCH_KEY);
    },
  });

  return prepareMutation('addSkillDictionaryTerm', mutation);
};
