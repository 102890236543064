import { HubspotProject, ProjectDealStage } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FindHubspotProjectsPayload {
  filters: Record<string, any>;
  pagination: {
    page: number;
    perPage: number;
  };
  sorting: Partial<Record<keyof HubspotProject, 'ASC' | 'DESC'>>;
  globalSearch?: string;
}

export interface FindHubspotProjectsResponse {
  projects: Array<HubspotProject>;
  total: number;
  dealStages: Partial<Record<ProjectDealStage, number>>;
}

export const findHubspotProjects = (payload: FindHubspotProjectsPayload): Promise<FindHubspotProjectsResponse> =>
  ai.post(`${ENDPOINTS.HUBSPOT_PROJECTS}/find`, payload);
