import React from 'react';
import { Checkbox, FormControlLabel, SxProps } from '@mui/material';

export interface Props {
  value?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  sx?: SxProps;
  size?: 'medium' | 'small';
  disabled?: boolean;
}

const CheckboxWithLabel: React.FC<Props> = ({
  label,
  value = false,
  onChange = () => null,
  sx = {},
  size = 'medium',
  disabled,
}) => {
  return (
    <FormControlLabel
      sx={{ ml: '0', mr: '0', ...sx }}
      checked={value}
      onChange={(_, checked) => onChange(checked)}
      control={<Checkbox size={size} color="primary" />}
      label={label}
      componentsProps={{ typography: { sx: { ml: '2px' } } }}
      disabled={disabled}
    />
  );
};

export default CheckboxWithLabel;
