import React from 'react';

export const useElementWidth = (elementId: string): number => {
  const [elementWidth, setElementWidth] = React.useState<number>(0);

  React.useLayoutEffect(() => {
    const calcElementWidth = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const calculatedFooterWidth = element.clientWidth;
        setElementWidth(calculatedFooterWidth);
      }
    };
    calcElementWidth();
    window.addEventListener('resize', calcElementWidth);
    return () => {
      window.removeEventListener('resize', calcElementWidth);
    };
  }, [elementId]);

  return elementWidth;
};
