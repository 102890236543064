import React, { memo } from 'react';
import { SxProps } from '@mui/material';

import { Root, Backdrop, ModalInner } from './modal.styled';

interface Props {
  sx?: SxProps;
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const Modal = ({ sx, isOpen, onClose = () => null, children }: Props) => {
  return (
    <Root open={isOpen} onClose={onClose} hideBackdrop>
      <>
        <Backdrop onMouseDown={(e) => e.preventDefault()} />
        <ModalInner sx={sx}>{children}</ModalInner>
      </>
    </Root>
  );
};

export default memo(Modal);
