import React, { memo } from 'react';
import { Box } from '@mui/material';

interface DashboardChipProps {
  children: React.ReactNode;
}

const DashboardChip = ({ children }: DashboardChipProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        padding: '4px 16px',
        height: '30px',
        borderRadius: '48px',
        border: `1px solid ${theme.palette.primary.main}`,
        fontSize: '13px',
        fontWeight: 500,
        color: theme.palette.primary.main,
      })}
    >
      {children}
    </Box>
  );
};

export default memo(DashboardChip);
