import { Tag } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchCandidateProjectTagsPayload {
  candidateId: string;
  projectId: string;
}

export const fetchCandidateProjectTags = ({
  candidateId,
  projectId,
}: FetchCandidateProjectTagsPayload): Promise<Array<Tag>> =>
  ai.get(`${ENDPOINTS.TAGS}/contacts/${candidateId}/projects/${projectId}`);
