import { useLayoutEffect, useState } from 'react';

import { debounce } from 'utils';

export const useTextOverflow = () => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const [textNode, setTextNode] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (!textNode) {
      return;
    }

    const [handleObserve, dispose] = debounce((entries) => {
      for (const _ of entries) {
        const newIsOverflow = textNode.scrollWidth > textNode.offsetWidth;
        setIsOverflow(newIsOverflow);
      }
    }, 250);

    const observer = new ResizeObserver(handleObserve);

    observer.observe(textNode);

    return () => {
      observer.unobserve(textNode);
      dispose();
    };
  }, [textNode]);

  return [setTextNode, isOverflow] as const;
};
