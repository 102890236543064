import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

export const RateLabel = styled(Typography)(() => ({
  fontWeight: 700,
  width: '150px',
}));

export const RateContent = styled(Typography)(() => ({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '14px',
  letterSpacing: '0.00938em',
}));

export const DollarSign = () => (
  <Box
    sx={{
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '0.00938em',
    }}
  >
    {'$'}
  </Box>
);
