import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FindHubspotProjectCandidatesPayload {
  filters: {
    contactId?: string;
    hubspotProjectId: string;
    isDeclined?: boolean;
    isForClientReview?: boolean;
  };
  pagination: {
    page: number;
    perPage?: number;
  };
}

interface FindHubspotProjectCandidatesResponse {
  projectContacts: Array<HubspotProjectCandidate>;
  total: number;
}

export const findHubspotProjectCandidates = (
  payload: FindHubspotProjectCandidatesPayload
): Promise<FindHubspotProjectCandidatesResponse> => ai.post(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts/find`, payload);
