import React, { memo } from 'react';
import { Box, Tooltip, SxProps, Theme } from '@mui/material';

import { Editor } from 'components';
import { useTextOverflow } from 'hooks';
import { urlsToHtmlATags, urlsToMdLinks } from 'utils';

export interface ReadonlyFieldProps {
  sx?: SxProps<Theme>;
  label: string;
  value?: string;
  noWrap?: boolean;
  endIcon?: React.ReactNode;
  md?: boolean;
  link?: boolean;
  linkUrl?: string;
  linkLabel?: string;
  /**
   * For reuse purposes for more complex components
   */
  renderValue?: () => React.ReactNode;
}

const ReadonlyField = ({ sx, label, value: propsValue, noWrap, endIcon, md, renderValue }: ReadonlyFieldProps) => {
  const value = propsValue?.trim();

  const [textRef, isOverflow] = useTextOverflow();

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', ...sx }}>
      {!!value && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: '0',
            left: '0',
            color: theme.palette.primary.light,
            fontSize: '0.75rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
          })}
        >
          {label}
        </Box>
      )}
      {renderValue ? (
        renderValue()
      ) : (
        <Tooltip title={noWrap && isOverflow ? value : ''}>
          <Box
            ref={textRef}
            sx={(theme) => ({
              maxWidth: '100%',
              padding: '4px 0 5px',
              marginTop: '16px',
              color: value ? theme.palette.text.primary : theme.palette.text.disabled,
              wordWrap: 'break-word',
              ...(noWrap && {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            })}
          >
            {(() => {
              if (!value) {
                return label;
              }

              if (md) {
                return <Editor initialValue={urlsToMdLinks(value ?? '')} readOnly />;
              }

              return <div dangerouslySetInnerHTML={{ __html: urlsToHtmlATags(value) }}></div>;
            })()}
          </Box>
        </Tooltip>
      )}
      {endIcon}
    </Box>
  );
};
export default memo(ReadonlyField);
