import React, { forwardRef, memo } from 'react';
import { Box, SxProps } from '@mui/material';

import { TagChip } from 'components';
import { Tag, TagGroup } from 'types';

interface TagListProps {
  sx?: SxProps;
  tags: Array<Tag>;
  onDelete?: (tag: Tag) => void;
  availableTagsToRender?: number;
}

const TagList = forwardRef(function TagList(
  { sx, tags, onDelete = () => null, availableTagsToRender: availableTagsToRenderProps }: TagListProps,
  ref
) {
  const availableTagsToRender =
    typeof availableTagsToRenderProps === 'number' ? availableTagsToRenderProps : tags.length;

  return (
    <Box
      ref={ref}
      sx={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap', ...sx }}
      data-testid="tag-list"
    >
      {tags.slice(0, availableTagsToRender).map((tag) => (
        <TagChip
          sx={{ flexShrink: 0 }}
          key={tag.id}
          type={tag.group}
          label={tag.name}
          {...((tag.group === TagGroup.Honors || tag.group === TagGroup.Campaign) && {
            onDelete: () => onDelete(tag),
          })}
          dataTestId="tag-chip"
        />
      ))}
      {availableTagsToRender < tags.length && (
        <TagChip
          sx={{ flexShrink: 0 }}
          type={tags[availableTagsToRender].group}
          label="..."
          withIcon={false}
          dataTestId="tag-chip"
        />
      )}
    </Box>
  );
});

export default memo(TagList);
