import { styled, Box } from '@mui/material';

interface CheckboxItemProps {
  $isSelected: boolean;
  $isSelectAll?: boolean;
}

export const Root = styled(Box)<CheckboxItemProps>(({ $isSelected, $isSelectAll = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '2px 16px',
  borderRadius: '4px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#C58FFF29',
  },

  ...($isSelected ? { backgroundColor: '#C58FFF29' } : {}),
  ...($isSelectAll ? { paddingLeft: '0', marginBottom: '4px' } : {}),
}));

export const Label = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
