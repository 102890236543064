import { HubspotProject } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface UpdateHubspotProjectPayload {
  dealId: string;
  fieldsToUpdate: Partial<HubspotProject>;
}

export const updateHubspotProject = ({
  dealId,
  fieldsToUpdate,
}: UpdateHubspotProjectPayload): Promise<HubspotProject> =>
  ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/${dealId}`, fieldsToUpdate);
