import React, { ReactNode } from 'react';
import { Button, Typography, IconButton, SxProps, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

import LemonLogo from './lemon-logo';
import LemonLogoNoText from './lemon-logo-no-text';

interface LogoProps {
  onClick: () => void;
}

export const Logo = ({ onClick }: LogoProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          cursor: 'pointer',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
        onClick={onClick}
      >
        <LemonLogo />
      </Box>
      <Box
        sx={(theme) => ({
          cursor: 'pointer',
          [theme.breakpoints.up('lg')]: {
            display: 'none',
          },
        })}
        onClick={onClick}
      >
        <LemonLogoNoText />
      </Box>
    </>
  );
};

export const PageTitle = styled(Typography)(() => ({
  flexGrow: 1,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '175%',
  letterSpacing: '0.15px',
}));

export const Delimiter = () => (
  <Typography variant="h5" sx={{ mx: '16px', color: 'rgba(220, 255, 2, 1)' }}>
    |
  </Typography>
);

export const AddCandidate = styled(Button)(() => ({
  marginRight: 4,
  border: '1px solid #ECFF79',
  color: '#ECFF79',
  textTransform: 'none',
  minWidth: '154px',
  '&&: hover': {
    backgroundColor: 'rgba(236, 255, 121, 0.16)',
    border: '1px solid #ECFF79',
  },
}));

interface NavLinkProps {
  children: ReactNode;
  sx?: SxProps;
  onClick?: () => void;
}

export const NavLink: React.FC<NavLinkProps> = ({ children, sx = {}, onClick = () => {} }) => (
  <IconButton
    size="small"
    aria-label="show 4 new mails"
    color="inherit"
    sx={{
      marginRight: 2,
      '&&: hover': {
        color: '#ECFF79',
      },
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </IconButton>
);

export const HeaderLinkTitle = styled(Typography)(({ theme }) => ({
  marginRight: 1,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0.46px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const RightContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
}));

export const AddonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const SideAddonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const MdAddonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export const MdSubHeader = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));
