import ai from '../axios';
import uploadFileAxios from './upload-file-axios';

import { Attachment } from 'types';
import ENDPOINTS from '../endpoints';

// Fetch Attachments

interface FetchAttachmentsPayload {
  candidateId: string;
}

export const fetchAttachments = ({ candidateId }: FetchAttachmentsPayload): Promise<Array<Attachment>> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/attachments`);

// Add Attachment

interface AddAttachmentPayload {
  candidateId: string;
  fileName: string;
  file: File;
}

export const addAttachment = ({ candidateId, fileName, file }: AddAttachmentPayload): Promise<Attachment> => {
  const formData = new FormData();
  formData.append('name', fileName);
  formData.append('attachment', file as File);
  formData.append('type', 'CV');
  return uploadFileAxios.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/attachments`, formData);
};

// Delete Attachment

interface DeleteAttachmentPayload {
  candidateId: string;
  attachmentId: string;
}

export const deleteAttachment = ({ candidateId, attachmentId }: DeleteAttachmentPayload): Promise<Attachment> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/attachments/${attachmentId}`);

// Update Attachment

interface UpdateAttachmentPayload {
  candidateId: string;
  attachmentId: string;
  fieldsToUpdate: Pick<Attachment, 'type'>;
}

export const updateAttachment = ({ candidateId, attachmentId, fieldsToUpdate }: UpdateAttachmentPayload) =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/attachments/${attachmentId}`, fieldsToUpdate);
