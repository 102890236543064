import { styled } from '@mui/system';

import Section from './section';

const SidebarSection = styled(Section)<{ noPadding?: boolean }>(({ noPadding = false }) => ({
  marginBottom: '0',
  paddingBottom: '8px',
  borderRadius: '0',
  backgroundColor: 'transparent',
  ...(noPadding && { padding: 0 }),
}));

export default SidebarSection;
