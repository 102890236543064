import React, { memo } from 'react';
import { Box } from '@mui/material';

import Header, { HeaderProps } from '../header';

interface Props {
  headerProps: HeaderProps;
  children: React.ReactNode;
}

const PageLayout = ({ headerProps, children }: Props) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header {...headerProps} />
      <Box
        sx={{
          height: '0',
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default memo(PageLayout);
