import { HubspotProject } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectPayload {
  dealId: string;
}

export const fetchHubspotProject = ({ dealId }: FetchHubspotProjectPayload): Promise<HubspotProject> =>
  ai.get(`${ENDPOINTS.HUBSPOT_PROJECTS}/${dealId}`);
