import { useCallback, useEffect, useState } from 'react';

import { useDebounce } from './use-debounce';

export type WindowSize = {
  width: number | null;
  height: number | null;
};

interface UseWindowSizeParams {
  delay?: number;
}

export const useWindowSize = ({ delay = 250 }: UseWindowSizeParams = {}): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({ width: null, height: null });

  const handleResize = useDebounce(
    useCallback(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, []),
    delay
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return windowSize;
};
