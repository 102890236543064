import { styled, Box } from '@mui/material';

export const Root = styled(Box)(() => ({
  borderRadius: '4px',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033',
  overflow: 'hidden',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '45px',
  justifyContent: 'space-between',
  padding: '4px 12px 4px 16px',
  backgroundColor: theme.palette.grey[50],
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

export const Content = styled(Box)(() => ({
  padding: '8px 16px 16px',
}));
