import React, { memo, useCallback } from 'react';
import { Tooltip, Box } from '@mui/material';

/**
 * Mandatory props for MUI custom tab:
 * - 'aria-selected'
 * - className
 * - children
 */
interface CustomTabProps {
  'aria-selected'?: boolean;
  className: string;
  children: React.ReactNode;
  customProps: {
    href: string;
    tooltipTitle?: string;
    altHeldDown: boolean;
    resetAltWatcher: () => void;
    onSelect: () => void;
  };
}

const CustomTab = ({
  customProps: { href, tooltipTitle, altHeldDown, resetAltWatcher, onSelect },
  ...muiProps
}: CustomTabProps) => {
  const handleClick = useCallback(() => {
    if (altHeldDown) {
      resetAltWatcher();
      window.open(href, '_blank');
      return;
    }

    onSelect();
  }, [altHeldDown, href, onSelect, resetAltWatcher]);

  return (
    <Tooltip title={tooltipTitle}>
      <Box {...muiProps} onClick={handleClick} />
    </Tooltip>
  );
};

export default memo(CustomTab);
