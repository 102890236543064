import React from 'react';
import { ValueChip } from '../';
import {
  CalendarToday,
  DoDisturb,
  QuestionMark,
  AccessAlarm,
  AvTimer,
  CalendarMonth,
  SvgIconComponent,
} from '@mui/icons-material';

interface Props {
  availabilityType: string;
}

type AvailabilityTypeDetails = {
  text: string;
  Icon: SvgIconComponent;
};

const TypeToDetailsMap: Record<string, AvailabilityTypeDetails> = {
  FullTimeOnly: { text: 'FT', Icon: CalendarToday },
  PartTimeOnly: { text: 'PT', Icon: AccessAlarm },
  PartTimeCanSwitchToFullTime: { text: 'PT -> FT', Icon: AvTimer },
  BothPartAndFullTime: { text: 'PT & FT', Icon: CalendarMonth },
  Unknown: { text: 'unknown', Icon: QuestionMark },
};

const unavailableTypeDetails: AvailabilityTypeDetails = {
  text: 'unavailable',
  Icon: DoDisturb,
};

const AvailabilityTypeCell: React.FC<Props> = ({ availabilityType }) => {
  if (!availabilityType) {
    return null;
  }
  const { text, Icon } = TypeToDetailsMap[availabilityType.trim()] || unavailableTypeDetails;
  return (
    <ValueChip>
      <Icon sx={{ mr: '4px', fontSize: '14px' }} />
      {text}
    </ValueChip>
  );
};

export default AvailabilityTypeCell;
