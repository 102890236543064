import { useQuery } from 'react-query';

import { GetPayload } from 'types';

import { prepareQueryResult } from '../utils';
import { fetchHubspotProjectLogs } from './hubspot-project-logs-service';

export const useFetchHubspotProjectLogs = (payload: GetPayload<typeof fetchHubspotProjectLogs>) => {
  const result = useQuery(['hubspot-project-logs', payload.dealId], () => fetchHubspotProjectLogs(payload));
  return prepareQueryResult('hubspotProjectLogs', result, []);
};
