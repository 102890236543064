import React, { useCallback } from 'react';

import { CandidateSpecialization } from 'types';

import SearchHighlightCell from './search-highlight-cell';

interface Props {
  specializations: CandidateSpecialization[];
  match: string;
}

const SpecializationsCell: React.FC<Props> = ({ specializations, match }) => {
  const formattedSpecializations = (specializations || [])
    .map((specialization: CandidateSpecialization) => {
      const specializationSeniority = specialization.seniority ? specialization.seniority.trim() + ' ' : '';
      const specializationTitle = specialization.title ? specialization.title.trim() : specialization.title;

      return specializationSeniority + specializationTitle;
    })
    .filter((specialization: string) => specialization !== '');

  const specializationsAsStrings = formattedSpecializations.join(' / ');

  return (
    <SearchHighlightCell
      searchValue={match}
      fieldValue={specializationsAsStrings}
      tokenize={useCallback((str: string) => str.split(','), [])}
    />
  );
};

export default SpecializationsCell;
