import React, { memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { adjustUrl } from 'utils';

import FormInput, { Props as FormInputProps } from '../form-input';

export interface Props extends FormInputProps {
  btnTooltip?: string;
}

const LinkInput = (props: Props) => {
  const { btnTooltip, sx, ...formInputProps } = props;
  const { value } = formInputProps;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', ...sx }}>
      <FormInput {...formInputProps} />
      {value && (
        <Tooltip title={btnTooltip ?? 'Open'}>
          <IconButton
            sx={{ flexShrink: 0, mt: '12px' }}
            onClick={() => {
              window.open(adjustUrl(value));
            }}
          >
            <LaunchOutlinedIcon sx={{ width: '20px', height: '20px' }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(LinkInput);
