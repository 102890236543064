import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

type ReviewHubspotProjectCandidate = Pick<
  HubspotProjectCandidate,
  'contactId' | 'hubspotProjectId' | 'isForClientReview'
>;

export const reviewHubspotProjectCandidate = (
  payload: ReviewHubspotProjectCandidate
): Promise<HubspotProjectCandidate> => ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts/review`, payload);
