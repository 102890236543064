import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

type RestoreHubspotProjectCandidatePayload = Pick<HubspotProjectCandidate, 'contactId' | 'hubspotProjectId'>;

export const restoreHubspotProjectCandidate = (
  payload: RestoreHubspotProjectCandidatePayload
): Promise<HubspotProjectCandidate> => ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts/restore`, payload);
