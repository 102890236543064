import ai from './../axios';
import ENDPOINTS from './../endpoints';

import { Contracts } from 'types';

export const fetchContracts = (lemonId: string | null): Promise<Contracts | null> => {
  if (!lemonId) {
    return Promise.resolve(null);
  }

  return ai.get(`${ENDPOINTS.CONTRACTS}?id=${lemonId}`);
};
