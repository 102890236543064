import React, { memo } from 'react';

import { Modal } from 'components';

import AddCandidateForm from './add-candidate-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddCandidateModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <AddCandidateForm onClose={onClose} />
    </Modal>
  );
};

export default memo(AddCandidateModal);
