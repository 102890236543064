import { HubspotProjectCandidate } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

type DeclineHubspotProjectCandidatePayload = Pick<
  HubspotProjectCandidate,
  'contactId' | 'hubspotProjectId' | 'declineStage' | 'declineReasons' | 'declineFeedback'
>;

export const declineHubspotProjectCandidate = (
  payload: DeclineHubspotProjectCandidatePayload
): Promise<HubspotProjectCandidate> => ai.patch(`${ENDPOINTS.HUBSPOT_PROJECTS}/contacts/decline`, payload);
