import { Tag } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface FetchHubspotProjectTagsPayload {
  hubspotProjectId: string;
}

export const fetchHubspotProjectTags = ({ hubspotProjectId }: FetchHubspotProjectTagsPayload): Promise<Array<Tag>> =>
  ai.get(`${ENDPOINTS.TAGS}/hubspot-projects/${hubspotProjectId}`);
