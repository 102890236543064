import React from 'react';
import { Box, Tooltip } from '@mui/material';

import { useTextOverflow } from 'hooks';

interface TextCellProps {
  children: React.ReactNode;
  tooltipWhenOverflow?: string;
}

const TextCell = ({ children, tooltipWhenOverflow }: TextCellProps) => {
  const [textRef, isOverflow] = useTextOverflow();

  return (
    <Tooltip title={isOverflow ? tooltipWhenOverflow ?? children : ''}>
      <Box
        ref={textRef}
        sx={{
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default TextCell;
