import { HubspotProjectCandidate } from 'types';

import { findHubspotProjectCandidates } from './find-hubspot-project-candidates';

interface FetchHubspotProjectCandidatesPayload {
  hubspotProjectId: string;
}

export const fetchHubspotProjectCandidates = (
  payload: FetchHubspotProjectCandidatesPayload
): Promise<Array<HubspotProjectCandidate>> =>
  findHubspotProjectCandidates({ filters: { ...payload }, pagination: { page: 1 } }).then(
    ({ projectContacts }) => projectContacts
  );
