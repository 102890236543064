import React, { useCallback, useState } from 'react';
import Router from 'next/router';
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from '@mui/material';
import {
  AccountCircle,
  Group,
  Add,
  LocalFireDepartmentOutlined,
  AccessAlarmOutlined,
  AssignmentOutlined,
} from '@mui/icons-material';

import { Routes, oldCrmUrl, envType, EnvType } from 'config';
import { logout } from 'api/auth';

import { FlexCenteredCell } from '..';

import {
  AddCandidate,
  HeaderLinkTitle,
  NavLink,
  PageTitle,
  Delimiter,
  RightContent,
  AddonContainer,
  Logo,
  MdAddonContainer,
  MdSubHeader,
  SideAddonContainer,
} from './header.styled';
import AddCandidateModal from './add-candidate-modal';

const navLinks = [
  {
    title: 'Projects',
    Icon: AssignmentOutlined,
    onClick: () => {
      Router.push(Routes.projects);
    },
  },
  {
    title: 'Reminders',
    Icon: AccessAlarmOutlined,
    onClick: () => {
      if (envType !== EnvType.LOCAL) {
        window.open(`${oldCrmUrl}/reminders`, '_blank');
      }
    },
  },
  {
    title: 'Teams',
    Icon: Group,
    onClick: () => {
      if (envType !== EnvType.LOCAL) {
        window.open(`${oldCrmUrl}/teams`, '_blank');
      }
    },
  },
  {
    title: 'Hot stacks',
    Icon: LocalFireDepartmentOutlined,
    onClick: () => {
      Router.push(Routes.hotStacks);
    },
  },
];

export interface HeaderProps {
  pageTitle: string | React.ReactElement;
  preTitleAddon?: React.ReactElement;
  centerAddon?: React.ReactElement;
  rightAddon?: React.ReactElement;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, preTitleAddon, centerAddon, rightAddon }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [isAddCandidateOpen, setIsAddCandidateOpen] = useState<boolean>(false);

  const handleNavigateToDashboard = () => {
    Router.push(Routes.dashboard);
  };

  const handleAddCandidateClose = useCallback(() => {
    setIsAddCandidateOpen(false);
  }, []);

  const handleAddCandidateClick = () => {
    setIsAddCandidateOpen(true);
  };

  const handleLogout = async () => {
    await logout();
    Router.push(`${Routes.login}#${Router.asPath}`);
  };

  return (
    <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: 'black' }}>
      <Toolbar>
        <Logo onClick={handleNavigateToDashboard} />
        <Delimiter />
        {preTitleAddon && <SideAddonContainer>{preTitleAddon}</SideAddonContainer>}
        <PageTitle data-testid="header-page-title">{pageTitle}</PageTitle>
        {centerAddon && <AddonContainer>{centerAddon}</AddonContainer>}

        <RightContent>
          <AddCandidate
            variant="outlined"
            data-testid="header-add-candidate-button"
            startIcon={<Add />}
            onClick={handleAddCandidateClick}
          >
            Add candidate
          </AddCandidate>

          {navLinks.map(({ title, Icon, onClick }, idx) => (
            <NavLink key={title} sx={{ marginLeft: idx === 0 ? 2 : 0 }} onClick={onClick}>
              <Icon sx={{ mr: '4px' }} />
              <HeaderLinkTitle>{title}</HeaderLinkTitle>
            </NavLink>
          ))}

          {rightAddon && <SideAddonContainer>{rightAddon}</SideAddonContainer>}

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setIsUserMenuOpen(true)}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isUserMenuOpen}
            onClose={() => setIsUserMenuOpen(false)}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </RightContent>
      </Toolbar>
      {(preTitleAddon || centerAddon || rightAddon) && (
        <MdSubHeader variant="dense">
          {preTitleAddon && <FlexCenteredCell>{preTitleAddon}</FlexCenteredCell>}
          {centerAddon && <MdAddonContainer>{centerAddon}</MdAddonContainer>}
          {rightAddon && <FlexCenteredCell>{rightAddon}</FlexCenteredCell>}
        </MdSubHeader>
      )}

      <AddCandidateModal isOpen={isAddCandidateOpen} onClose={handleAddCandidateClose} />
    </AppBar>
  );
};

export default Header;
