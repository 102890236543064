import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { detect } from 'detect-browser';
import { Box, Tooltip, type SxProps } from '@mui/material';

import { useFetchConstants } from 'api';

interface Props {
  sx?: SxProps;
  country?: string;
  size?: number;
  tooltip?: string;
}

const Flag: React.FC<Props> = ({ country, sx = {}, size, tooltip = '' }) => {
  const browser = detect();
  const isMacOS = browser?.os === 'Mac OS';

  const { data: constants } = useFetchConstants();

  if (!country || !constants) {
    return null;
  }

  const countryCode = constants.countriesData[country]?.code || '';

  return (
    <Tooltip title={tooltip}>
      <Box sx={{ ...sx, fontSize: size ? `${size}px` : 'inherit' }}>
        <ReactCountryFlag svg={!isMacOS} countryCode={countryCode} />
      </Box>
    </Tooltip>
  );
};

export default Flag;
