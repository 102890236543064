import ai from './../axios';
import { Candidate } from 'types';
import ENDPOINTS from './../endpoints';

export const deletePhoto = (candidateId: string): Promise<Candidate> =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/photo`, {
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
  });

type UploadPhotoParams = {
  candidateId: string;
  file: File;
};

export const uploadPhoto = ({ candidateId, file }: UploadPhotoParams): Promise<Candidate> => {
  const formData = new FormData();
  formData.append('profile_photo', file);
  return ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

interface UpdateShowInCvPayload {
  candidateId: string;
  value: boolean;
}

export const updateShowInCv = async ({ candidateId, value }: UpdateShowInCvPayload): Promise<Candidate> =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}`, {
    profile_photo_show_in_cv: value,
  });
