import React, { memo } from 'react';
import { Box } from '@mui/material';

import { Modal } from 'components';
import { Content, Title, Actions } from 'components/modal-form';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string | React.ReactNode;
  confirmLabel: string;
  cancelLabel?: string;
  submitDisabled?: boolean;
  inverse?: boolean;
  dataTestId?: string;
}

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
  confirmLabel,
  cancelLabel,
  submitDisabled,
  inverse = false,
  dataTestId,
}) => (
  <Modal isOpen={isOpen}>
    <Content>
      <>
        <Title sx={{ mb: '24px' }}>{title}</Title>
        {typeof content === 'string' ? <Box>{content}</Box> : content}
      </>
    </Content>
    <Actions
      submitLabel={confirmLabel}
      cancelLabel={cancelLabel}
      disabled={submitDisabled}
      submitColor={inverse ? 'error' : 'primary'}
      cancelColor={inverse ? 'primary' : 'error'}
      onSubmit={onConfirm}
      onCancel={onClose}
      {...(dataTestId && { dataTestId: `${dataTestId}__actions` })}
    />
  </Modal>
);

export default memo(ConfirmModal);
