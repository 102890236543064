import { useContext, useState, useEffect } from 'react';

import { useFindSources } from 'api';
import { GridSettings, SelectItem, SourcesForFilter } from 'types';
import { GridSettingsContext } from 'context';

export const useSettingsChangeHandler = () => {
  const { setGridSettingsItem } = useContext(GridSettingsContext);

  const makeChangeHandler =
    <T extends {}>(gridSettingsItem: keyof GridSettings) =>
    (value: T) =>
      setGridSettingsItem(gridSettingsItem, value);

  return { makeChangeHandler };
};

/**
 * Use this for mapping source value `null` for filter items
 */
export const MAPPED_SOURCE_NULL_VALUE = '<NO_VALUE>';

interface UseSourcesItemsParams {
  gridSettings: GridSettings;
  sources?: SourcesForFilter;
}

type SourcesItems = Record<keyof SourcesForFilter, Array<SelectItem<string>>>;

export const useSourcesItems = ({ gridSettings, sources }: UseSourcesItemsParams) => {
  const mapSource = (item: string | null): SelectItem<string> =>
    typeof item === 'string' ? { name: item, value: item } : { name: 'No value', value: MAPPED_SOURCE_NULL_VALUE };

  const mapSourceValueToApiFormat = (value: string) => (value === MAPPED_SOURCE_NULL_VALUE ? null : value);

  const sourcesKeys: Array<keyof SourcesForFilter> = ['sourceL2', 'sourceL3', 'sourceL4', 'sourceL5'];

  const sourceKeyToGridSettingsKey: Record<keyof SourcesForFilter, keyof GridSettings> = {
    sourceL2: 'websiteSources',
    sourceL3: 'adTypeSources',
    sourceL4: 'campaignSources',
    sourceL5: 'customSources',
  };

  const gridSettingsToAdd = sourcesKeys.reduce((acc, key) => {
    if (!sources?.[key]?.length) {
      return { ...acc };
    }

    return {
      ...acc,
      [sourceKeyToGridSettingsKey[key]]: sources[key].map(mapSourceValueToApiFormat),
    };
  }, {} as Pick<GridSettings, 'websiteSources' | 'adTypeSources' | 'campaignSources' | 'customSources'>);

  const [sourcesForFilter, setSourcesForFilter] = useState<SourcesForFilter | null>(null);

  const { contactSources } = useFindSources({ gridSettings: { ...gridSettings, ...gridSettingsToAdd } });

  useEffect(() => {
    if (contactSources) {
      setSourcesForFilter(contactSources);
    }
  }, [contactSources]);

  const sourcesItems = sourcesKeys.reduce<SourcesItems>(
    (acc, key) => ({
      ...acc,
      [key]: (sourcesForFilter?.[key] ?? []).map(mapSource),
    }),
    {} as SourcesItems
  );

  return { sourcesItems };
};
