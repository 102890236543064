import { Sources, SourcesForFilter } from 'types';
import ai from '../axios';
import ENDPOINTS from '../endpoints';

export const fetchSources = async (): Promise<Sources> => {
  const sources = (await ai.get(ENDPOINTS.SOURCES)) as unknown as Sources;
  return sources;
};

type FindSourcesPayload = Record<string, any>;

export const findSources = (payload: FindSourcesPayload) =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/find-sources`, payload) as unknown as SourcesForFilter;
