import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { FlexColumn } from '..';

interface Props {
  comment: string;
}

const ReminderCommentCell: React.FC<Props> = ({ comment }) => {
  return (
    <Tooltip
      placement="bottom-start"
      title={
        <FlexColumn>
          <Typography>{comment}</Typography>
        </FlexColumn>
      }
    >
      <Typography>{comment}</Typography>
    </Tooltip>
  );
};

export default ReminderCommentCell;
