import { useQuery } from 'react-query';

import { type Constants } from 'types';

import { fetchConstants, fetchNextStatuses } from './service';

export const useFetchConstants = () => {
  return useQuery<Constants, Error>('constants', fetchConstants);
};

export const useFetchNextStatuses = (currentStatus: string) => {
  return useQuery<string[], Error>(['next-statuses', currentStatus], () => fetchNextStatuses(currentStatus));
};
