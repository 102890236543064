import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  updateTag,
  deleteTag,
  unassignContactLog,
  findTags,
  findTagLogs,
  createTag,
  assignTagToContactPayload,
  assignTagToProjectPayload,
  downloadTags,
  fetchCandidateTags,
  fetchCandidateProjectTags,
  unassignCandidateTag,
  unassignCandidateProjectTag,
  updateCandidateProjectTags,
  updateHubspotProjectTags,
  fetchHubspotProjectTags,
  bulkAssignToContactsPayload,
} from './tags-service';

export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
    },
  });

  return prepareMutation('updateTag', mutation);
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
    },
  });

  return prepareMutation('deleteTag', mutation);
};

export const useUnassignContactLog = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(unassignContactLog, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
    },
  });

  return prepareMutation('unassignContactLog', mutation);
};

export const useFindTags = (payload: GetPayload<typeof findTags>) => {
  const result = useQuery(['tags', JSON.stringify(payload)], () => findTags(payload));
  return prepareQueryResult('findTagsResult', result, null, true);
};

export const useFindTagLogs = (payload: GetPayload<typeof findTagLogs>) => {
  const result = useQuery(['tag-logs', JSON.stringify(payload)], () => findTagLogs(payload));
  return prepareQueryResult('findTagLogsResult', result, null, true);
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
    },
  });

  return prepareMutation('createTag', mutation);
};

export const useAssignTagToContact = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(assignTagToContactPayload, {
    onSuccess: (_, { contactId }) => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
      queryClient.invalidateQueries(['candidate-tags', contactId]);
    },
  });

  return prepareMutation('assignTagToContact', mutation);
};

export const useBulkAssignToContacts = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(bulkAssignToContactsPayload, {
    onSuccess: (_, { contactIds }) => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
      contactIds.forEach((contactId) => {
        queryClient.invalidateQueries(['candidate', contactId]);
        queryClient.invalidateQueries(['candidate-tags', contactId]);
      });
    },
  });

  return prepareMutation('bulkAssignToContacts', mutation);
};

export const useAssignTagToProject = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(assignTagToProjectPayload, {
    onSuccess: (_, { contactId }) => {
      queryClient.invalidateQueries(['tags']);
      queryClient.invalidateQueries(['tag-logs']);
      queryClient.invalidateQueries(['candidate-tags', contactId]);
    },
  });

  return prepareMutation('assignTagToProjectPayload', mutation);
};

export const useDownloadTags = () => {
  const mutation = useMutation(downloadTags);
  return prepareMutation('downloadTags', mutation);
};

export const useFetchCandidateTags = (payload: GetPayload<typeof fetchCandidateTags>) => {
  const result = useQuery(['candidate-tags', payload.candidateId], () => fetchCandidateTags(payload));
  return prepareQueryResult('fetchCandidateTagsResult', result, { contactProjectTags: [], contactTags: [] });
};

export const useFetchCandidateProjectTags = ({
  enabled,
  ...payload
}: GetPayload<typeof fetchCandidateProjectTags> & { enabled?: boolean }) => {
  const result = useQuery(
    ['candidate-project-tags', payload.candidateId, payload.projectId],
    () => fetchCandidateProjectTags(payload),
    { enabled }
  );
  return prepareQueryResult(
    'candidateProjectTags',
    result,
    useMemo(() => [], [])
  );
};

export const useUnassignCandidateTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(unassignCandidateTag, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-tags', candidateId]);
    },
  });

  return prepareMutation('unassignCandidateTag', mutation);
};

export const useUnassignCandidateProjectTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(unassignCandidateProjectTag, {
    onSuccess: (_, { candidateId, projectId }) => {
      queryClient.invalidateQueries(['candidate-tags', candidateId]);
      queryClient.invalidateQueries(['candidate-project-tags', candidateId, projectId]);
    },
  });

  return prepareMutation('unassignCandidateProjectTag', mutation);
};

export const useUpdateCandidateProjectTags = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCandidateProjectTags, {
    onSuccess: (_, { contactId, projectId }) => {
      queryClient.invalidateQueries(['candidate-tags', contactId]);
      queryClient.invalidateQueries(['candidate-project-tags', contactId, projectId]);
    },
  });

  return prepareMutation('updateCandidateProjectTags', mutation);
};

export const useUpdateHubspotProjectTags = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateHubspotProjectTags, {
    onSuccess: (_, { hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-projects']);
      queryClient.invalidateQueries(['hubspot-project', hubspotProjectId]);
      queryClient.invalidateQueries(['hubspot-project-tags', hubspotProjectId]);
    },
  });

  return prepareMutation('updateHubspotProjectTags', mutation);
};

export const useFetchHubspotProjectTags = (payload: GetPayload<typeof fetchHubspotProjectTags>) => {
  const result = useQuery(['hubspot-project-tags', payload.hubspotProjectId], () => fetchHubspotProjectTags(payload));
  return prepareQueryResult('hubspotProjectTags', result, []);
};
