import axios, { AxiosInstance } from 'axios';
import { baseApiUrl, AUTH_LS_KEY } from 'config';

import { APIError } from './exceptions';

const ai: AxiosInstance = axios.create({
  baseURL: baseApiUrl,
});

ai.interceptors.request.use(async (config) => {
  const authKey = window.localStorage.getItem(AUTH_LS_KEY);
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'auth-key': authKey!,
    'Cache-control': 'no-store',
  };
  return config;
});

ai.interceptors.response.use(
  (response) => {
    if (response.data.error) {
      let message: string = response.data.error.message;

      if (response.data.error.name == 'ValidationError' && response.data.error.payload?.length > 0) {
        const error = response.data.error.payload[0];
        const constraints = Object.values(error.constraints);
        message = constraints[0] as string;
      }

      throw new Error(message);
    }

    return response.data;
  },
  (error) => {
    throw new APIError(error.response?.data?.error?.message || '', error.response?.data?.error?.traceId || '');
  }
);

export default ai;
