import React, { memo, ReactElement, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
  SxProps,
  Autocomplete as MuiAutocomplete,
  TextField,
  Box,
  type AutocompleteRenderOptionState,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
} from '@mui/material';

import { SelectItem } from 'types';

import { mapProps } from './autocomplete.utils';

type AdjustedRenderOptions = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: string,
  state: AutocompleteRenderOptionState
) => React.ReactNode;

export interface Props {
  sx?: SxProps;
  label: string;
  placeholder?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  variant?: 'standard' | 'outlined';
  items: Array<SelectItem<string>>;
  value?: string | null;
  onChange?: (value: string | null) => void;
  onSubmit?: (value: string | null) => void;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement | Element>;
  error?: boolean | string;
  disabled?: boolean;
  withoutClear?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    item: SelectItem<string>,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  modifyRenderInputParams?: (params: AutocompleteRenderInputParams) => AutocompleteRenderInputParams;
  helperText?: string;
  filterOptions?: (options: Array<string>, state: FilterOptionsState<string>) => Array<string>;
  loading?: boolean;
  autoFocus?: boolean;
  disabledItems?: Array<string>;
  freeSolo?: boolean;
  /**
   * Workaround for preventing input value reset
   * P.S. this is MUI internal bug
   */
  inputControlled?: boolean;
  dataTestId?: string;
}

export const Autocomplete = ({
  sx,
  label,
  placeholder,
  color = 'primary',
  variant = 'standard',
  items,
  value: externalValue,
  onChange = () => {},
  onSubmit = () => {},
  inputValue,
  onInputChange = () => {},
  onBlur,
  onFocus = () => {},
  error,
  disabled,
  withoutClear,
  renderOption,
  modifyRenderInputParams = (params) => params,
  helperText,
  filterOptions,
  loading,
  autoFocus,
  disabledItems,
  freeSolo,
  inputControlled,
  dataTestId,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const itemsMap = useMemo(
    () => items.reduce<Record<string, SelectItem<string>>>((acc, item) => ({ ...acc, [item.value]: item }), {}),
    [items]
  );

  const getItem = useCallback(
    (value: string): SelectItem<string> | null => (typeof value === 'string' ? itemsMap[value] ?? null : null),
    [itemsMap]
  );

  /**
   * Workaround for internal MUI bug related to rendering options
   */
  const adjustedRenderOption = useMemo<AdjustedRenderOptions | undefined>(() => {
    // eslint-disable-next-line react/display-name
    return renderOption
      ? (props, option, state) => (
          <React.Fragment key={String(option)}>
            {renderOption(props, getItem(option) as SelectItem<string>, state)}
          </React.Fragment>
        )
      : (props, option) => (
          <Box component="li" {...props} key={option}>
            {(getItem(option) as SelectItem<string>).name}
          </Box>
        );
  }, [getItem, renderOption]);

  const options = useMemo(() => items.map((item) => item.value), [items]);

  const internalValue = externalValue && options.includes(externalValue) ? externalValue : null;

  useLayoutEffect(() => {
    if (autoFocus) {
      setIsOpen(true);
    }
  }, [autoFocus]);

  const adjustParams = (params: AutocompleteRenderInputParams): AutocompleteRenderInputParams =>
    inputControlled
      ? {
          ...params,
          inputProps: {
            ...params.inputProps,
            value: inputValue ?? '',
            onChange: () => null,
            onInput: (event) => {
              const inputValue = (event.nativeEvent.target as HTMLInputElement).value;
              onInputChange(inputValue);
              setIsOpen(true);
            },
          },
        }
      : params;

  return (
    <MuiAutocomplete
      sx={sx}
      open={isOpen}
      freeSolo={freeSolo}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={options}
      getOptionLabel={(option) => getItem(option)?.name ?? ''}
      getOptionDisabled={(option) => (disabledItems ?? []).includes(getItem(option)?.value as string)}
      filterOptions={
        filterOptions ??
        ((options, state) =>
          options.filter((option) => {
            const item = getItem(option);

            if (!item) {
              return false;
            }

            return (item.name ?? '').toLowerCase().includes(state.inputValue.toLowerCase());
          }))
      }
      loading={loading}
      fullWidth
      disableClearable={withoutClear}
      value={internalValue}
      disabled={disabled}
      onChange={(_, option) => {
        if (withoutClear && !option) {
          return;
        }

        onChange(option);
        onSubmit(option);
      }}
      onInputChange={(_, value) => {
        if (inputControlled) {
          return;
        }
        onInputChange(value);
      }}
      onFocus={(event) => {
        setIsFocused(true);
        onFocus(event);
      }}
      onBlur={() => {
        setIsFocused(false);
        setIsOpen(false);
      }}
      renderOption={adjustedRenderOption}
      renderInput={(params) => {
        const paramsModified = isFocused ? adjustParams(params) : modifyRenderInputParams(adjustParams(params));

        return (
          <TextField
            {...paramsModified}
            sx={(theme) => ({
              '.MuiInput-root::after': {
                borderColor: theme.palette.primary.main,
              },
              ...(variant === 'outlined' && {
                '.MuiOutlinedInput-root.MuiOutlinedInput-root': {
                  pt: '5px',
                  pb: '5px',
                  backgroundColor: '#fff',
                },
                ...(!internalValue && {
                  '.MuiInputLabel-root:not(.Mui-focused)': {
                    transform: 'translate(14px, 13px) scale(1)',
                  },
                }),
              }),
            })}
            autoComplete="none"
            variant={variant}
            fullWidth
            placeholder={placeholder}
            label={label}
            color={color}
            onBlur={onBlur}
            error={!!error}
            helperText={typeof error === 'string' ? error : helperText}
            autoFocus={autoFocus}
            {...(dataTestId && { 'data-testid': `${dataTestId}__wrapper` })}
            inputProps={{
              ...paramsModified.inputProps,
              'data-testid': dataTestId,
            }}
            InputProps={{
              ...paramsModified.InputProps,
              endAdornment: paramsModified?.InputProps?.endAdornment
                ? React.cloneElement(paramsModified?.InputProps?.endAdornment as ReactElement, {
                    ...(dataTestId && { 'data-testid': `${dataTestId}__end-adornment` }),
                  })
                : null,
            }}
          />
        );
      }}
    />
  );
};

export default memo(mapProps(Autocomplete));
