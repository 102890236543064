import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button, SxProps } from '@mui/material';

import { SelectItem } from 'types';

interface Props {
  buttons: SelectItem<string>[];
  initialSelection: string;
  onChange?: (value: string) => void;
  sx?: SxProps;
  size?: 'small' | 'medium' | 'large';
  dataTestId?: string;
}
const RadioButtonGroup: React.FC<Props> = ({
  buttons,
  initialSelection,
  onChange = () => {},
  sx = {},
  size = 'medium',
  dataTestId,
}) => {
  const [selected, setSelected] = useState<string>(initialSelection);

  useEffect(() => {
    setSelected(initialSelection);
  }, [initialSelection]);

  const handleSelectionChange = (selectedButton: string): void => {
    setSelected(selectedButton);
    onChange(selectedButton);
  };
  return (
    <ButtonGroup aria-label="outlined button group" fullWidth sx={sx} color="primary">
      {buttons.map((btn) => (
        <Button
          key={btn.value}
          onClick={() => handleSelectionChange(btn.value)}
          disabled={selected === btn.value}
          size={size}
          sx={{
            backgroundColor: 'white',
            '&:disabled': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
            // '&:hover': {
            // backgroundColor: (theme) => 'red',
            // color: 'white',
            // },
          }}
          {...(dataTestId && { 'data-testid': `${dataTestId}__${btn.value}` })}
        >
          {btn.name}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default RadioButtonGroup;
