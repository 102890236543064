import { useState } from 'react';
import { createLocalStorageService } from 'utils';

interface UseStateWithLSParams<T> {
  /**
   * The initial value of the state. Can be a static value or a function that returns a value.
   */
  initialState: T | (() => T);
  /**
   * The key to use when storing the state value in local storage.
   */
  localStorageKey: string;
  /**
   * The local storage service to use for storing and retrieving values.
   */
  localStorageService: ReturnType<typeof createLocalStorageService>;
}

type UseStateWithLS = <T>(params: UseStateWithLSParams<T>) => [T, (value: T) => void];

/**
 * A React hook that uses local storage to persist state across page reloads.
 *
 * @template T - The type of state value.
 * @param params - The parameters for the `useStateWithLS` hook.
 * @returns A tuple containing the current state value and a function to update the state value.
 *
 * @example
 * import { useStateWithLS } from 'hooks';
 *
 * const MyComponent = () => {
 *   const [count, setCount] = useStateWithLS({
 *     initialState: 0,
 *     localStorageKey: 'count',
 *     localStorageService: myLocalStorageService,
 *   });
 *
 *   const handleIncrement = () => {
 *     setCount(count + 1);
 *   };
 *
 *   return (
 *     <div>
 *       <p>Count: {count}</p>
 *       <button onClick={handleIncrement}>Increment</button>
 *     </div>
 *   );
 * };
 */
export const useStateWithLS: UseStateWithLS = ({ initialState, localStorageKey, localStorageService }) => {
  const { extractFromLocalStorage, decorateSetterWithSync } = localStorageService;

  const [state, setState] = useState(extractFromLocalStorage(localStorageKey) ?? initialState);

  const decoratedSetState = decorateSetterWithSync(setState, localStorageKey);

  return [state, decoratedSetState];
};
