import React, { memo } from 'react';
import TagIcon from '@mui/icons-material/Tag';
import StarsIcon from '@mui/icons-material/Stars';
import CloseIcon from '@mui/icons-material/Close';
import LoopIcon from '@mui/icons-material/Loop';
import { SxProps, Tooltip, alpha } from '@mui/material';

import { useTextOverflow } from 'hooks';
import { TagGroup } from 'types';

import { Root, IconWrapper, LabelWrapper } from './tag-chip.styled';

const MAP_TAG_CHIP_TYPE: Record<TagChipProps['type'], { bgColor: string; icon: React.ReactNode }> = {
  [TagGroup.Project]: { bgColor: '#F6EEFF', icon: <TagIcon sx={{ fill: '#9B56E6' }} /> },
  [TagGroup.Honors]: { bgColor: alpha('#2196F3', 0.08), icon: <StarsIcon sx={{ fill: '#2196F3' }} /> },
  [TagGroup.Campaign]: { bgColor: '#FAFFD9', icon: <LoopIcon sx={{ fill: '#9B9E87' }} /> },
};

interface TagChipProps {
  sx?: SxProps;
  type: TagGroup;
  label: string;
  withIcon?: boolean;
  onDelete?: () => void;
  dataTestId?: string;
}

const TagChip = ({ sx, type, label, withIcon = true, onDelete, dataTestId }: TagChipProps) => {
  const [textRef, isOverflow] = useTextOverflow();

  return (
    <Root
      sx={sx}
      withIcon={withIcon}
      withDelete={!!onDelete}
      bgColor={MAP_TAG_CHIP_TYPE[type].bgColor}
      {...(dataTestId && { 'data-testid': `${dataTestId}__root` })}
    >
      {withIcon && <IconWrapper sx={{ marginRight: '8px' }}>{MAP_TAG_CHIP_TYPE[type].icon}</IconWrapper>}
      <Tooltip title={isOverflow ? label : ''} {...(dataTestId && { 'data-testid': `${dataTestId}__label` })}>
        <LabelWrapper ref={textRef}>{label}</LabelWrapper>
      </Tooltip>
      {onDelete && (
        <IconWrapper
          sx={{ cursor: 'pointer', marginLeft: '8px' }}
          onClick={onDelete}
          {...(dataTestId && { 'data-testid': `${dataTestId}__delete-button` })}
        >
          <CloseIcon sx={{ fill: '#00000099', fontSize: '18px' }} />
        </IconWrapper>
      )}
    </Root>
  );
};

export default memo(TagChip);
