import { useMutation } from 'react-query';

import { deletePhoto, uploadPhoto, updateShowInCv } from './service';

export const useDeleteCandidatePhotoMutation = () => {
  return useMutation(deletePhoto);
};

export const useUploadCandidatePhotoMutation = () => {
  return useMutation(uploadPhoto);
};

export const useUpdateShowInCv = () => {
  return useMutation(updateShowInCv);
};
