import { ContactRoles, ContactStatuses, ContactAvailabilityTypes } from 'config';
import { GridSettings, GridSettingsProp, LeadTypeOptionValue } from 'types';

export const memoGridSettingsProps: GridSettingsProp[] = [
  'role',
  'team',
  'statuses',
  'availabilityType',
  'availabilityHours',
  'perPage',
  'operatorId',
  'dedicatedOperatorId',
  'leadType',
  'campaignTagsInclude',
  'campaignTagsExclude',
  'forFutureReasonsInclude',
  'forFutureReasonsExclude',
  'selfAssessmentInclude',
  'commercialExperience',
  'relevantCountriesOnly',
  'sortedColumn',
  'websiteSources',
  'adTypeSources',
  'campaignSources',
  'customSources',
  'leadTypeSource',
  'tagIds',
];

const initSettings: GridSettings = {
  role: ContactRoles.FREELANCER,
  team: null,
  operatorId: null,
  dedicatedOperatorId: null,
  availabilityType: [ContactAvailabilityTypes.FULL_TIME],
  availabilityHours: [],
  statuses: [ContactStatuses.AVAILABLE, ContactStatuses.ENGAGED],
  globalSearch: '',
  sortedColumn: {},
  columnSearches: {},
  page: 1,
  perPage: 10,
  leadType: LeadTypeOptionValue.ALL_LEADS,
  campaignTagsInclude: [],
  campaignTagsExclude: [],
  forFutureReasonsInclude: [],
  forFutureReasonsExclude: [],
  selfAssessmentInclude: [],
  commercialExperience: null,
  relevantCountriesOnly: false,
  websiteSources: [],
  adTypeSources: [],
  campaignSources: [],
  customSources: [],
  leadTypeSource: LeadTypeOptionValue.ALL_LEADS,
  referredBySomeone: false,
  referredById: null,
  tagIds: [],
};

export const generalFiltersSettingsResetState: Partial<GridSettings> = {
  role: ContactRoles.ALL,
  availabilityType: [],
  availabilityHours: [],
  team: null,
  operatorId: null,
  dedicatedOperatorId: null,
};

export const statusesSettingsResetState: Partial<GridSettings> = {
  statuses: [],
};

export const tagsSettingsResetState: Partial<GridSettings> = {
  tagIds: [],
};

export const advancedFiltersSettingsResetState: Partial<GridSettings> = {
  leadType: LeadTypeOptionValue.ALL_LEADS,
  campaignTagsInclude: [],
  campaignTagsExclude: [],
  forFutureReasonsInclude: [],
  forFutureReasonsExclude: [],
  selfAssessmentInclude: [],
  commercialExperience: null,
  relevantCountriesOnly: false,
  referredBySomeone: false,
  referredById: null,
};

export const filtersBySourcesResetState: Partial<GridSettings> = {
  leadTypeSource: LeadTypeOptionValue.ALL_LEADS,
  websiteSources: [],
  adTypeSources: [],
  campaignSources: [],
  customSources: [],
};

export default initSettings;
