import { Editable } from 'slate-react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { FormInput } from '../../text-inputs';

export const EditorField = styled(Box)(() => ({
  position: 'relative',
  paddingTop: '0.01px',
}));

interface StyledEditableProps {
  heightRestriction: boolean;
}

export const StyledEditable = styled(Editable)<StyledEditableProps>(({ readOnly, heightRestriction }) => ({
  position: 'relative',
  zIndex: 5,

  ...(heightRestriction && {
    maxHeight: '200px',
    overflow: 'auto',
  }),

  ...(!readOnly && {
    paddingTop: '4px',
    paddingBottom: '5px',
    marginTop: '16px',
  }),

  '& *[data-slate-node="element"]': {
    margin: '0',
  },

  '& ul, & ol': {
    paddingLeft: '28px',
  },
}));

export const StyledFormInput = styled(FormInput)(() => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',

  '& .MuiInput-root': {
    height: '100%',
    pointerEvents: 'none',
  },
}));
