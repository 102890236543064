const LemonLogoNoText = () => (
  <svg width="32" height="32" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M256 41L0 470.853H512L256 41Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M453.536 301.326C453.536 282.392 429.484 292.634 395.215 240.831C361.742 190.234 312.802 165.006 256.001 165.006C199.196 165.006 150.252 190.234 116.783 240.831C82.514 292.634 58.4619 282.392 58.4619 301.326C58.4619 320.265 82.514 310.019 116.783 361.825C150.252 412.418 199.196 437.651 256.001 437.651C312.802 437.651 361.742 412.418 395.215 361.825C429.484 310.019 453.536 320.265 453.536 301.326Z"
      fill="#DCFF02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.998 171.395C199.981 171.395 153.672 196.628 122.094 244.366C101.969 274.788 84.6386 285.018 74.29 291.128C66.1407 295.944 64.8291 297.029 64.8291 301.341C64.8291 305.654 66.1407 306.743 74.294 311.555C84.6466 317.669 101.969 327.903 122.094 358.321C153.672 406.055 199.981 431.292 255.998 431.292C312.02 431.292 358.321 406.055 389.898 358.321C410.024 327.903 427.35 317.669 437.703 311.555C445.856 306.743 447.168 305.654 447.168 301.341C447.168 297.029 445.856 295.944 437.707 291.128C427.35 285.018 410.028 274.788 389.898 244.366C358.321 196.628 312.02 171.395 255.998 171.395ZM255.999 444.032C195.503 444.032 145.527 416.829 111.47 365.352C92.9723 337.38 77.8373 328.448 67.8206 322.534C59.3834 317.545 52.0938 313.241 52.0938 301.333C52.0938 289.426 59.3834 285.122 67.8206 280.137C77.8373 274.227 92.9683 265.286 111.47 237.315C145.527 185.841 195.503 158.635 255.999 158.635C316.495 158.635 366.471 185.841 400.523 237.315C419.025 265.286 434.16 274.223 444.173 280.137C452.614 285.122 459.904 289.426 459.904 301.333C459.904 313.241 452.614 317.545 444.173 322.534C434.16 328.448 419.025 337.38 400.523 365.352C366.471 416.829 316.495 444.032 255.999 444.032Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M344.981 301.331C344.981 350.543 305.138 390.434 255.995 390.434C206.851 390.434 167.008 350.543 167.008 301.331C167.008 252.119 206.851 212.229 255.995 212.229C305.138 212.229 344.981 252.119 344.981 301.331Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M310.394 301.339C310.394 331.42 286.042 355.808 256 355.808C225.958 355.808 201.602 331.42 201.602 301.339C201.602 271.258 225.958 246.874 256 246.874C286.042 246.874 310.394 271.258 310.394 301.339Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M310.389 275.814C310.389 291.798 297.449 304.754 281.487 304.754C265.524 304.754 252.584 291.798 252.584 275.814C252.584 259.831 265.524 246.874 281.487 246.874C297.449 246.874 310.389 259.831 310.389 275.814Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.997 218.617C210.44 218.617 173.376 255.725 173.376 301.341C173.376 346.954 210.44 384.07 255.997 384.07C301.554 384.07 338.614 346.954 338.614 301.341C338.614 255.725 301.554 218.617 255.997 218.617ZM255.997 396.814C203.419 396.814 160.641 353.98 160.641 301.333C160.641 248.686 203.419 205.856 255.997 205.856C308.576 205.856 351.35 248.686 351.35 301.333C351.35 353.98 308.576 396.814 255.997 396.814Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export default LemonLogoNoText;
