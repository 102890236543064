import React, { memo, useRef, useState } from 'react';
import { IconButton, SxProps, Theme, Tooltip } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { copyText } from 'utils';

interface CopyButtonProps {
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  value: string;
}

const CopyButton = ({ sx, iconSx, value }: CopyButtonProps) => {
  const timerID = useRef<NodeJS.Timeout | null>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const btnTooltipToShow = isCopied ? '✓ Copied' : 'Copy to clipboard';

  return (
    <Tooltip title={btnTooltipToShow}>
      <IconButton
        sx={sx}
        onClick={() => {
          copyText(value);

          if (timerID.current) {
            clearTimeout(timerID.current);
          }

          setIsCopied(true);

          timerID.current = setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        }}
      >
        <ContentCopyOutlinedIcon sx={{ ...iconSx, width: '20px', height: '20px' }} />
      </IconButton>
    </Tooltip>
  );
};

export default memo(CopyButton);
