import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  spaceLetter: '0.15px',
}));

export const Reset = styled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '22px',
  letterSpacing: '0.46px',
}));
