import React, { useState, ReactNode, useMemo, useEffect } from 'react';
import { Box, SxProps } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useWindowSize } from 'hooks';

import { FlexColumn, ScrollBox } from '..';
import {
  DEFAULT_EXPANDED_SIDEBAR_WIDTH,
  COLLAPSED_SIDEBAR_WIDTH,
  MenuFooterContainer,
  FilterTogglerTitle,
  CollapsedContentComponent,
} from './sidebar.styled';

interface Props {
  sx?: SxProps;
  width?: number;
  children: ReactNode | ReactNode[];
  collapseTitle?: string;
  collapsedContent?: ReactNode | ReactNode[];
  position?: 'left' | 'right';
  /**
   * if set to true, the "Hide" control is going to be visible at md breackpoint
   * default value is false
   */
  showCollapseOnMdOnly?: boolean;
}

const Sidebar: React.FC<Props> = ({
  sx,
  collapsedContent,
  children,
  width,
  collapseTitle = 'Hide',
  position = 'left',
  showCollapseOnMdOnly,
}) => {
  const { width: windowWidth } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleSidebarState = () => setIsOpen(!isOpen);

  /**
   * listening to window width change to detect the cases when sidebar was collapsed at md, and
   * should be expanded (open) when window size becomes > lg breackpoint in case of resizing
   */
  useEffect(() => {
    if (showCollapseOnMdOnly && windowWidth && windowWidth >= 1200) {
      setIsOpen(true);
    }
  }, [windowWidth, showCollapseOnMdOnly]);

  const panelWidth = useMemo(() => {
    const expandedSidebarWidth = width || DEFAULT_EXPANDED_SIDEBAR_WIDTH;
    return `${isOpen ? expandedSidebarWidth : COLLAPSED_SIDEBAR_WIDTH}px`;
  }, [isOpen, width]);

  return (
    <Box
      sx={{
        ...sx,
        width: panelWidth,
        minWidth: panelWidth,
        ...(position === 'left' ? { borderRight: '1px solid lightgray' } : { borderLeft: '1px solid lightgray' }),
      }}
    >
      <FlexColumn sx={{ height: '100%' }}>
        <ScrollBox height="100%" disabledScroll={!isOpen}>
          {isOpen ? <>{children}</> : null}
        </ScrollBox>
        <MenuFooterContainer onClick={toggleSidebarState} showCollapseOnMdOnly={!!showCollapseOnMdOnly}>
          {isOpen ? (
            <FilterTogglerTitle color="primary">
              {position === 'left' ? (
                <>
                  <ChevronLeftIcon /> {collapseTitle}
                </>
              ) : (
                <>
                  {collapseTitle}
                  <ChevronRightIcon />
                </>
              )}
            </FilterTogglerTitle>
          ) : (
            <CollapsedContentComponent collapsedContent={collapsedContent} position={position} />
          )}
        </MenuFooterContainer>
      </FlexColumn>
    </Box>
  );
};

export default Sidebar;
