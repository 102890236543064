import axios, { AxiosInstance } from 'axios';
import { baseApiUrl, AUTH_LS_KEY } from 'config';

const ai: AxiosInstance = axios.create({
  baseURL: baseApiUrl,
});

ai.interceptors.request.use(async (config) => {
  const authKey = window.localStorage.getItem(AUTH_LS_KEY);
  config.headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'multipart/form-data',
    'auth-key': authKey!,
  };
  return config;
});

ai.interceptors.response.use((response) => response.data);

export default ai;
