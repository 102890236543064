import React from 'react';
import { SxProps, Box, Typography, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FiltersCountProps {
  sx?: SxProps;
  filtersCount: number;
  onResetAll: () => void;
}

const FiltersCount = ({ sx, filtersCount, onResetAll }: FiltersCountProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '22px' }}>Filters</Typography>
        <Chip label={filtersCount} size="small" color="primary" sx={{ fontWeight: 500, marginLeft: '6px' }} />
      </Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '4px', pr: '4px', cursor: 'pointer' }}
        onClick={onResetAll}
      >
        <CloseIcon sx={{ fontSize: '16px' }} color="primary" />
        <Typography sx={{ fontWeight: 500, fontSize: '13px' }} color="primary">
          Reset all
        </Typography>
      </Box>
    </Box>
  );
};

export default FiltersCount;
