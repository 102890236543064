import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  fetchHubspotProjectSpecializations,
  createHubspotProjectSpecialization,
  updateHubspotProjectSpecialization,
  deleteHubspotProjectSpecialization,
} from './hubspot-project-specializations-service';

export const useFetchHubspotProjectSpecializations = (
  payload: GetPayload<typeof fetchHubspotProjectSpecializations>
) => {
  const result = useQuery(['hubspotProjectSpecializations', payload.hubspotProjectId], () =>
    fetchHubspotProjectSpecializations(payload)
  );
  return prepareQueryResult('hubspotProjectSpecializations', result, []);
};

export const useCreateHubspotProjectSpecialization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createHubspotProjectSpecialization, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSpecializations']);
    },
  });
  return prepareMutation('createHubspotProjectSpecialization', mutation);
};

export const useUpdateHubspotProjectSpecialization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateHubspotProjectSpecialization, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSpecializations']);
    },
  });
  return prepareMutation('updateHubspotProjectSpecialization', mutation);
};

export const useDeleteHubspotProjectSpecialization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteHubspotProjectSpecialization, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubspotProjectSpecializations']);
    },
  });
  return prepareMutation('deleteHubspotProjectSpecialization', mutation);
};
