import React, { memo } from 'react';
import { Box } from '@mui/material';

import { CopyButton } from 'components';

import FormInput, { Props as FormInputProps } from '../form-input';

/*
  TODO: Logic and api of the component are similar to LinkInput's
  Create the component for FormInput + icon button
  and then reuse it for LinkInput and CopyInput   
*/

const CopyInput = (props: FormInputProps) => {
  const { sx, ...formInputProps } = props;
  const { value } = formInputProps;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', ...sx }}>
      <FormInput {...formInputProps} />
      {value && <CopyButton sx={{ flexShrink: 0, mt: '12px' }} value={value} />}
    </Box>
  );
};

export default memo(CopyInput);
