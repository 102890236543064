import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CandidateSkill } from 'types';
import { getCandidateSkills, addSkill, deleteSkill, updateSkill } from './skill-service';

const CANDIDATE_SKILLS_FETCH_KEY = 'candidate-skills-fetch';

export const useFetchCandidateSkills = (candidateId: string) => {
  const { data, isLoading, isError } = useQuery<CandidateSkill[], Error>(CANDIDATE_SKILLS_FETCH_KEY, () =>
    getCandidateSkills(candidateId)
  );
  const candidateSkills = useMemo(() => (data || []).filter((skill) => skill.name !== undefined), [data]);
  return {
    candidateSkills,
    candidateSkillsAreLoading: isLoading,
    candidateSkillsFetchError: isError,
  };
};

export const useAddSkillMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(addSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(CANDIDATE_SKILLS_FETCH_KEY);
    },
  });
};

export const useDeleteSkillMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(CANDIDATE_SKILLS_FETCH_KEY);
    },
  });
};

export const useUpdateSkillMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSkill, {
    onSuccess: () => {
      queryClient.invalidateQueries(CANDIDATE_SKILLS_FETCH_KEY);
    },
  });
};
