import { HubspotProjectSkill } from 'types';

import ai from '../../axios';
import ENDPOINTS from '../../endpoints';

interface CreateHubspotProjectSkillPayload {
  hubspotProjectId: string;
  skill: Pick<HubspotProjectSkill, 'name' | 'years' | 'main'>;
}

export const createHubspotProjectSkill = ({
  hubspotProjectId,
  skill,
}: CreateHubspotProjectSkillPayload): Promise<HubspotProjectSkill> =>
  ai.post(`${ENDPOINTS.HUBSPOT_PROJECTS}/${hubspotProjectId}/skills`, skill);
