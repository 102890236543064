import React from 'react';
import { useUpdateCandidate } from 'api/domain';
import { useSnackbar } from 'notistack';
import { SxProps, Switch, Avatar } from '@mui/material';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';

interface Props {
  candidateId: string;
  isHot?: boolean;
  size: 'small' | 'medium';
  sx?: SxProps;
}

const HotSwitch: React.FC<Props> = ({ candidateId, isHot, size, sx }) => {
  const { updateCandidate } = useUpdateCandidate();
  const { enqueueSnackbar } = useSnackbar();

  const handleIsHotChange = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    updateCandidate(
      { candidateId, fieldsToUpdate: { isHot: checked } },
      {
        onSuccess: () => {
          enqueueSnackbar(`Contact is ${checked ? 'set' : 'unset'} as "hot"`, { variant: 'success' });
        },
        onError: (error) => {
          const { message } = error as Error;
          enqueueSnackbar(message || 'Failed to update candidate', { variant: 'error' });
        },
      }
    );
  };

  return (
    <Switch
      defaultChecked={isHot}
      icon={
        <Avatar sx={{ height: '16px', width: '16px', backgroundColor: '#FFF' }}>
          <LocalFireDepartmentOutlinedIcon
            fontSize="small"
            sx={(theme) => ({ fontSize: '14px', color: theme.palette.action.disabled })}
          />
        </Avatar>
      }
      checkedIcon={
        <Avatar sx={(theme) => ({ height: '16px', width: '16px', backgroundColor: theme.palette.grey['50'] })}>
          <LocalFireDepartmentOutlinedIcon sx={(theme) => ({ fontSize: '14px', color: theme.palette.error.main })} />
        </Avatar>
      }
      onChange={handleIsHotChange}
      color="error"
      size={size}
      sx={sx}
    />
  );
};
export default HotSwitch;
