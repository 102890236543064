import React from 'react';
import { Chip } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

interface Props {
  name: string;
}

const AssigneeCell = ({ name }: Props) => {
  if (!name) {
    return null;
  }

  return <Chip label={name} size="small" icon={<PersonOutlineIcon />} />;
};

export default AssigneeCell;
